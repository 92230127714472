'use strict';
/* jshint unused: vars */

angular.module('otn.directives.vidyo', ['otn.services.user', 'otn.services.api', 'otn.services.notifier', 'otn.services.videorealtime', 'otn.utils.filters', 'otn.utils.debounce', 'otn.components', 'otn.components-templates', 'ngResource', 'ngAnimate', 'ui.bootstrap', 'ngAudio']);
;'use strict';
var vidyoModule = angular.module('otn.directives.vidyo');
vidyoModule.controller('CallScreenSidePanelController', function ($rootScope, $scope, $log) {
  var vm = this;
  vm.controllerName = 'CallScreenSidePanelController';
  var init = function () {
    $log.debug('init.......................++++++++ ' + vm.controllerName);
    vm.isShown = false;
    vm.activePanel = null;
  };
  init();
  vm.isActivePanel = function (panelType) {
    return vm.activePanel === panelType;
  };
  vm.switchPanel = function (panelType) {
    $log.debug('invoked switchPanel, panelType:' + panelType);
    if (panelType === null) {
      //panel was closed
      vm.isShown = false;
    } else {
      if (!vm.isShown) {
        vm.isShown = true;
      }
    }
    vm.activePanel = panelType;
    $rootScope.$broadcast('RealignVideoWindowEvent');
  };

  $scope.$on('SidePanelSwitched', function (event, panelType) {
    $log.debug(vm.controllerName + ' SidePanelSwitched received ' + JSON.stringify(panelType));
    vm.switchPanel(panelType);
  });
});
;'use strict';
angular.module('otn.directives.vidyo').controller('InCallModalController',
  function ($rootScope, $scope, $log, $interval, $timeout, $window, data,
    Notifier, Events, EventsService, VideoService, ModalManager, VidyoMessages, User, AppConfig, CallService, FullScreen, VideoOverlayService) {
    $log.info('InCallModalController initializing, data :' + JSON.stringify(data));
    $scope.selectedParticipant = {};
    $scope.isSelfViewOn = true;

    $scope.call = data.call;
    $scope.call.context = data.context;
    $scope.isMediaStatisticsSupported = data.call.isMediaStatisticsSupported;

    // FIXME: Call has a caller, move the logic appropraitely  
    $scope.caller = data.caller;
    $scope.activePanel = null;
    var MEDIA_STATS_THRESHOLDS_MAP = [
      {'code': 'excellent', 'class': 'call-quality-i-excellent','label':'Excellent'},
      {'code': 'good', 'class': 'call-quality-i-good', 'label':'Good'},
      {'code': 'poor', 'class': 'call-quality-i-poor', 'label':'Poor'},
      {'code': 'terrible', 'class': 'call-quality-i-terrible', 'label':'Terrible'}
    ];
    var findCallQClassByCode = function(code) {
      $log.info('InCallModalController findCallQClassByCode, code :' + code);
        var res = MEDIA_STATS_THRESHOLDS_MAP[0];
        angular.forEach(MEDIA_STATS_THRESHOLDS_MAP, function(value, key) {
          if (value.code === code) {
            res = value;
          }
        });
      $log.info('InCallModalController findCallQClassByCode, res :' + JSON.stringify(res));
        return res;
    };
    var currThreshold = findCallQClassByCode(CallService.getCurrentCallQualityLevel());
    $scope.currentCallQualityIndicatorClass = currThreshold.class;
    $scope.currentCallQualityIndicatorLabel = currThreshold.label;

    $scope.switchSidePanel = function (panelType) {
      $log.debug('invoked InCallModalController switchSidePanel, panelType:' + panelType);
      if ($scope.activePanel === panelType) {
        $scope.activePanel = null;
      } else {
        $scope.activePanel = panelType;
      }
      $scope.$broadcast('SidePanelSwitched', $scope.activePanel);
    };

    // Sync isSharingContent state maintained outside of the angular $scope inside CallService library.
    $scope.$watch(
      function ($scope) {
        // This becomes the value we're "watching".
        return (CallService.isSharingContent());
      },
      function (newValue) {
        $scope.controls.isContentSharing = newValue;
      }
    );

    $scope.$watch(
      function ($scope) {
        // This becomes the value we're "watching".
        return (CallService.isViewingSharedContent());
      },
      function (newValue) {
        $scope.controls.isViewingSharedContent = newValue;

        if ($scope.controls.isViewingSharedContent &&
          CallService.isContentShareLimited() &&
          !VideoService.isChrome() &&
          !VideoService.isFirefox()) {
          Notifier.createMessage('warning', VidyoMessages.call.contentShareLimited.message, VidyoMessages.call.contentShareLimited.solution);
        }
      }
    );

    $scope.toggleContentSharing = function () {
      if ($scope.controls.isContentShareSupported) {
        CallService.toggleContentSharing().then(function (isSharing) {
            $log.debug('CallService.toggleContentSharing sharing: ', isSharing);
          })
          .catch(function (err) {
            var solution = '';
            if (err.code === 'E_SHARE_NOEXTENSIONCHROME') {
              solution = 'Please <a href="' + err.extensionUrl + '" target="_blank">Install</a> the Pexip Browser extension and try again.';
            }

            Notifier.createMessage('error', err.message, solution, JSON.stringify(err.code));
          });
      } else {
        $log.debug('toggleContentSharing Not supported isContentShareSupported: ', $scope.controls.isContentShareSupported);
      }
    };

    $scope.isActivePanel = function (panelType) {
      return $scope.activePanel === panelType;
    };

    var refreshTokenTimer = $interval(function () {
      $log.info('invoked refreshToken ' + new Date());
      User.actions.refreshToken('video');
    }, 300000); // 5 min


    var oneTimeRefreshTokenOnStart = $timeout(function () {
      $log.info('invoked oneTimeRefreshTokenOnStart ' + new Date());
      User.actions.refreshToken('video');
    }, 10000);

    // vidyo stops ringing the incoming call after 30 seconds
    // so after a bit longer inform the caller that there was no answer
    var hangupTimer = $timeout(function () {
      $log.info('ending call via hangupTimer');
      $scope.endCall('hangupTimer');
    }, 37000);

    if (data.context === 'MIRROR_TEST' || data.acceptedSide) {
      $log.info('this is the mirror call or acceptedSide is true, cancel the hangupTimer');
      $timeout.cancel(hangupTimer);
    }
    $scope.$on('CallEntered', function () {
      $log.info('CallEntered start');
      $log.debug('canceling hangupTimer');
      $timeout.cancel(hangupTimer);
      $log.info('CallEntered end');
    });

    $scope.$on('AllParticipantsLeft', function () {
      $log.debug('InCallModalController AllParticipantsLeft start');
      // if (!$scope.isGuestLink()) {
        // FIXME @Yakov we need to provide this case
        /*
        VidyoManager.api.events.leaveConference().then(
          function(leaveConferenceSuccess) {
            $log.debug('InCallModalController AllParticipantsLeft with leaveConferenceSuccess ');
          },
          function(leaveConferenceFailed) {
            $log.debug('InCallModalController AllParticipantsLeft with leaveConferenceFailed ');
          }
        );
        */
      // }
      $log.debug('InCallModalController AllParticipantsLeft end ');
    });

    $scope.$on('otn-call-ConferenceUpdated', function (angularEvent, call, conferenceUpdated) {
      $log.debug('InCallModalController received otn-call-ConferenceUpdated: ' + JSON.stringify(conferenceUpdated));
      $scope.controls.isRoomLocked = conferenceUpdated.locked;
    });

    var currentEvent = data.call.eventData;

    // TODO: This is polled constantly while you're in call. Consider setting the result as a prop on $scope
    $scope.isGuestLink = function () {
      return EventsService.isGuestLink(currentEvent);
    };

    // TODO: Looks like this is unused
    $scope.isMultipoint = function () {
      return EventsService.isMultipoint(currentEvent);
    };

    $scope.muteSpeaker = function () {
      $log.info('muteSpeaker start');
      if ($scope.controls.isMuteSpeakerSupported) {
        $scope.controls.isSpeakerMuted = !$scope.controls.isSpeakerMuted;
        CallService.toggleVolume();
      } else {
        $log.debug('Not supported isMuteSpeakerSupported: ', $scope.controls.isMuteSpeakerSupported);
      }
      $log.debug('isSpeakerMuted: ', $scope.controls.isSpeakerMuted);
    };

    $scope.muteMicrophone = function () {
      $log.info('muteMicrophone start');
      $scope.controls.isMicrophoneMuted = !$scope.controls.isMicrophoneMuted;
      CallService.toggleMic();
      $log.debug('isMicrophoneMuted: ', $scope.controls.isMicrophoneMuted);
    };

    $scope.toggleSelfView = function () {
      $log.info('toggleSelfView start');
      $scope.isSelfViewOn = CallService.toggleSelfView();
      $log.debug('toggleSelfView: ', CallService.getSelfViewMode());
    };

    $scope.toggleMediaStatsView = function () {
      VideoOverlayService.toggleMediaStatsView();
    };

    $scope.toggleVideoWarningMessage = function() {
      VideoOverlayService.toggleVideoWarningMessage();
    };

    // testVideoWarningMessage
    $scope.testVideoWarningMessage = function() {
      CallService.testCallQuality();
    };

    $scope.testStats = function() {
      $rootScope.$broadcast('TestMediaStats');
      $rootScope.$broadcast('otn-call-quality-changed', 'good');
    };

    $scope.toggleFullScreen = function () {
      $log.info('toggleFullScreen start');
      if (VideoService.pluginReady) {
        /*
         Note: we cannot call FullScreen on the #call-screen, because the plugin
         itself sits directory on the <body> element, not in the modal. This will work
         to make the modal full-screen but the video window will not be shown.
         */
        FullScreen.toggle($window.document.body);
      } else {
        $log.error('Video plugin does not report as started, cannot toggle full screen mode');
      }
      $log.info('toggleFullScreen end');
    };

    $scope.muteCamera = function () {
      if ($scope.controls.isCameraOn) {
        $scope.$evalAsync(function () {

          if (VideoService.isUsingFlash() || VideoService.isEdge()) {
            Notifier.createMessage('warning',
              VidyoMessages.call.videoDisablePrivacy.message,
              VidyoMessages.call.videoDisablePrivacy.solution);
          }
          $scope.controls.isCameraOn = false;
          $scope.controls.isSelfiewDisabled = true;
          $scope.controls.oldSelfViewState = CallService.getSelfViewMode();
          $scope.setSelfViewMode('None');
          CallService.toggleCamera();
        });
      } else {
        $scope.$evalAsync(function () {
          $scope.controls.isCameraOn = true;
          $scope.controls.isSelfiewDisabled = false;
          $scope.setSelfViewMode($scope.controls.oldSelfViewState);
          CallService.toggleCamera();
        });
      }
    };

    $scope.setSelfViewMode = function (selfViewMode) {
      if (selfViewMode === 'PIP') {
        CallService.changeSelfViewMode('PIP');
        $scope.isSelfViewOn = true;
      } else {
        CallService.changeSelfViewMode('None');
        $scope.isSelfViewOn = false;
      }
    };

    $scope.isCameraOn = function () {
      return self.isCameraOn;
    };

    // Resets vidyo controls to their initial state, every time a new call is entered
    // FIXME Commented the following @Syed please check with @Yakov whether this is used anymore
    //VidyoManager.initControls();

    // This scope variable controls visibility of the various levels of in call controls, as well as toggle states
    // Valid values: initial, speakers, microphone, more, settings
    // TODO: group microphone and speaker into objects
    $scope.controls = {
      active: 'initial',
      isContentSharing: false,
      isViewingSharedContent: false,
      isSpeakerMuted: false,
      speakerVolume: null,
      isMicrophoneMuted: false,
      isRoomLocked: false,
      microphoneVolume: null,
      isSelfiewDisabled: false,
      oldSelfViewState: null,
      isCameraOn: true,
      isMuteSpeakerSupported: CallService.isMuteSpeakerSupported(),
      isContentShareSupported: CallService.isContentShareSupported(),
      isContentShareLimited: CallService.isContentShareLimited(),
      microphoneLoaded: function () {
        $log.info('microphoneLoaded start');
        $log.info('microphoneLoaded end');
      },
      microphoneChanged: function (newVolume) {
        $log.info('microphoneChanged start');
        $log.info('microphoneChanged end');
      },
      muteMicrophone: function () {
        $log.info('muteMicrophone start');
        $log.info('muteMicrophone end');
      },
      speakerLoaded: function () {
        $log.info('speakerLoaded start');
        $log.info('speakerLoaded end');
      },
      speakerChanged: function (newVolume) {
        $log.info('speakerChanged start');
        $log.info('speakerChanged end');
      },
      muteSpeaker: function () {
        $log.info('muteSpeaker start');
        $log.info('muteSpeaker end');
      },
      video: {
        // FIXME: @Syed replace VidyoManager logic
        //fullScreen: VidyoManager.controls.video.fullScreen,
        //privacy: VidyoManager.controls.video.privacy,
        //selfView: VidyoManager.controls.video.selfView
      }
    };

    $scope.isMirrorTest = function () {
      return data.context === 'MIRROR_TEST';
    };

    $scope.lockRoomToggle = function () {
      if ($scope.isMirrorTest()) {
        $log.warn('Cannot lock room during Mirror Test');
        return;
      }

      if (!$scope.controls.isRoomLocked) {
        CallService.lockVvr($scope.call)
          .then(function (call) {
            $scope.controls.isRoomLocked = true;
          })
          .catch(function (rejection) {
            $log.error('Room lock failed.', rejection);
            Notifier.createMessage('error', VidyoMessages.call.cantLockRoom.message, VidyoMessages.call.cantLockRoom.solution, JSON.stringify(rejection));
          });
      } else {
        CallService.unlockVvr($scope.call)
          .then(function (call) {
            $scope.controls.isRoomLocked = false;
          })
          .catch(function (rejection) {
            $log.error('Room unlock failed.', rejection);
          });
      }
    };

    $scope.endCall = function (caller) {
      $log.info('endCall start');

      if (caller && caller === 'hangupTimer') {
        // notification of no answer
        Notifier.createMessage('warning', VidyoMessages.call.cantReach.message, VidyoMessages.call.cantReach.solution);
      }

      CallService.endCall($scope.call)
        .then(function (call) {
          ModalManager.close('inCall');
          ModalManager.close('joinCall');
        })
        .catch(function (error) {
          // FIXME Notify with message
        });

      $log.info('endCall end');
    };

    $window.onbeforeunload = function () {
      $log.info('onbeforeunload InCallModalControllerstart, id=' + User.id);
      $log.info('onbeforeunload InCallModalController end');

      //CS1-1072: Because Safari doesn't call "onunload", the end call logic is done here
      if (VideoService.isSafari()) {
        CallService.endCallOnUnload();
        currentEvent = null;
        return;
      }

      // intent: generate the "Confirm Navigation" pop up
      // if the user attempts to closes the tab or the browser
      // if you don't return something no pop-up will be generated. in chrome at least
      // doing " return; " also does not generate the pop up
      if (User.id !== null) {
        return '';
      }
    };

    $window.onbeforeunload = function () {
      $log.info('onbeforeunload InCallModalController start, ');
      CallService.endCallOnUnload();
      currentEvent = null;
      $log.info('onbeforeunload InCallModalController end');
      return true;
    };

    $scope.$on('otn-call-quality-changed', function (aEvent, code) {
      $log.info('InCallModalController: otn-call-quality-changed code=' + JSON.stringify(code));
      var currThreshold = findCallQClassByCode(code);
      $scope.currentCallQualityIndicatorClass = currThreshold.class;
      $scope.currentCallQualityIndicatorLabel = currThreshold.label;
    });
    $scope.$on('$destroy', function () {
      $log.info('$destroy InCallModalController start');
      $timeout.cancel(hangupTimer);
      $log.info('cancel hangupTimer');
      $interval.cancel(refreshTokenTimer);
      $log.info('cancel refreshTokenTimer');
      $window.onbeforeunload = null;
      currentEvent = null;
      $rootScope.$broadcast('InCallModalControllerDestroyed');
      $log.info('$destroy InCallModalController end');
    });
  });
;'use strict';
angular.module('otn.directives.vidyo').controller('IncomingCallModalController',
  function ($scope, $log, $timeout, AuditService, VideoService, ModalManager, VidyoMessages, data, ngAudio, CallService, Notifier) {
    $log.info('IncomingCallModalController initializing');
    $scope.system = {
      type: 'hcp'
    };

    $scope.call = data;
    $scope.caller = data.caller;
    $scope.connecting = false;

    if (VideoService.isAudioEnabled()) {
      //TODO: This is dirty, but relative paths don't seem to work here.
      $scope.ringing = ngAudio.play('bower_components/otn.directives.vidyo/dist/assets/ring.mp3');
      $scope.ringing.loop = 2;
    } else {
      $log.warn('Audio is disabled! Cannot play ringing sound.');
    }

    AuditService.saveCallRing(data.vvrId);

    $scope.acceptCall = function () {
      $log.info('acceptCall start');
      $scope.connecting = true;

      CallService.acceptCall($scope.call)
        .then(function (call) {
          $scope.call = call;
          $scope.connecting = false;

          ModalManager.close('incomingCall');
          if (VideoService.isAudioEnabled()) {
            $scope.ringing.stop();
          }

          ModalManager.open('inCall', {
            acceptedSide: true,
            caller: $scope.caller,
            call: $scope.call
          });
          $scope.isIncoming = false;
        })
        .catch(function (rejection) {
          $scope.connecting = false;
          ModalManager.close('incomingCall');
          Notifier.createMessage('error', VidyoMessages.call.cantStartConference.message, VidyoMessages.call.cantStartConference.solution, JSON.stringify(rejection.error));
          $scope.isIncoming = false;
        });
    };

    $scope.rejectCall = function () {
      $log.info('rejectCall start');
      if (VideoService.isAudioEnabled()) {
        $scope.ringing.stop();
      }

      CallService.rejectCall($scope.call)
        .then(function (call) {
          $scope.call = call;
          ModalManager.close('incomingCall');
          $scope.isIncoming = false;
        })
        .catch(function (rejection) {
          ModalManager.close('incomingCall');
          Notifier.createMessage('error', VidyoMessages.call.cantStartConference.message, VidyoMessages.call.cantStartConference.solution, JSON.stringify(rejection.error));
          $scope.isIncoming = false;
        });

      // TODO: Due to no messaging being recieved on decline/ring missed, probably the participant count needs to be monitored in order to provide decent UX (ie don't open the in call modal if nobody ever comes..)
      $log.info('rejectCall end');
    };

    $scope.$on('otn-realtime-call-answered-from-another-device', function (angularEvent) {
      $log.debug('plugin: otn-realtime-call-answered-from-another-device', angularEvent);
      //TODO we might want to show some warning that call was answered from another device.
      ModalManager.close('incomingCall');
      $scope.isIncoming = false;
      $log.debug('plugin: otn-realtime-call-answered-from-another-device complete: %O', angularEvent);
    });

    $scope.$on('$destroy', function () {
      $log.info('$destroy IncomingCallModalController end');
      if (VideoService.isAudioEnabled() && typeof $scope.ringing !== 'undefined') {
        $scope.ringing.stop();
      }
      $log.info('$destroy IncomingCallModalController end');
    });
  });
;'use strict';
var vidyoModule = angular.module('otn.directives.vidyo');
vidyoModule.controller('MediaStatsPanelController', function($scope, $log, $q, $timeout, $filter, $interval, CallService, VideoOverlayService) {
  var vm = this;
  vm.controllerName = 'MediaStatsPanelController';
  /*
   <<<<Public API
   */

  vm.close = function() {
    VideoOverlayService.toggleMediaStatsView();
  };
  /*
   Public API end >>>>
   */
  /*
   <<<<Private API
   */
  var init = function() {
    $log.debug(vm.controllerName + ': init');
    vm.THRESHOLDS = [
      {'name': 'Excellent', 'per': parseFloat(0), 'class': 'level-excellent'},
      {'name': 'Good', 'per': parseFloat(1), 'class': 'level-good'},
      {'name': 'Poor', 'per': parseFloat(3), 'class': 'level-bad'},
      {'name': 'Terrible', 'per': parseFloat(10), 'class': 'level-terrible'}
    ];
    vm.qualityTitle = {
      'out': {
        'audio': vm.THRESHOLDS[0],
        'video': vm.THRESHOLDS[0]
      },
      'in': {
        'audio': vm.THRESHOLDS[0],
        'video': vm.THRESHOLDS[0]
      },
    };
    $log.debug(vm.controllerName + ': init ' + JSON.stringify(vm.THRESHOLDS));
    var attrToTest = 'percentage-lost-recent';
    vm.pollingId = $interval(function() {
      vm.stats = CallService.getMediaStats();
      try {
        vm.qualityTitle.out.audio = calcQuality(vm.stats.outgoing.audio[attrToTest]);
        vm.qualityTitle.out.video = calcQuality(vm.stats.outgoing.video[attrToTest]);
        vm.qualityTitle.in.audio = calcQuality(vm.stats.incoming.audio[attrToTest]);
        vm.qualityTitle.in.video = calcQuality(vm.stats.incoming.video[attrToTest]);
      } catch (e) {
        $log.error(vm.controllerName + ': Failed to process poll update ' + e);
      }
    }, 1000);
  };

  var calcQuality = function(quality) {
    var res = vm.THRESHOLDS[0];
    var qualFl = parseFloat(quality.replace('%', ''));
    angular.forEach(vm.THRESHOLDS, function(value, key) {
      if (qualFl > value.per) {
        res = value;
      }
    });
    return res;
  };
  var destroy = function() {
    $log.debug(vm.controllerName + ': invoked destroy pollingId:' + vm.pollingId);
    if (angular.isDefined(vm.pollingId)) {
      $interval.cancel(vm.pollingId);
      $log.debug(vm.controllerName + ': cancelled pollingId:' + vm.pollingId);
      vm.pollingId = undefined;
      vm.stats = {};
    }
  };

  /*
   Private API end>>>>>>
   */
  /*
   Listeners
   */
  /*
   init
   */
  init();

  $scope.$on("$destroy", function() {
    $log.debug(vm.controllerName + ': invoked $destroy');
    destroy();
  });
  $scope.$on("TestMediaStats", function() {
    $log.debug(vm.controllerName + ': invoked TestMediaStats');
    vm.qualityTitle.out.audio = calcQuality(String(Math.floor(Math.random() * 11)));
    vm.qualityTitle.out.video = calcQuality(String(Math.floor(Math.random() * 11)));
    vm.qualityTitle.in.audio = calcQuality(String(Math.floor(Math.random() * 11)));
    vm.qualityTitle.in.video = calcQuality(String(Math.floor(Math.random() * 11)));
  });
  $scope.$on("InCallModalControllerDestroyed", function() {
    $log.debug(vm.controllerName + ': on InCallModalControllerDestroyed');
    destroy();
  });
});
;'use strict';
var vidyoModule = angular.module('otn.directives.vidyo');
vidyoModule.controller('ParticipantsPanelController', function ($scope, $log, $q, $timeout, $filter, CallService, ModalManager) {
  var vm = this;
  vm.controllerName = 'ParticipantsPanelController';

  // CS1-3060, CS1-2839
  const ignoreParticipantNamePrefixes = ['util_', 'rtmp://', 'rtmps://'];

  /*
   <<<<Public API
   */
  vm.selectItem = function (selectedItem) {
    if (!angular.equals({}, vm.selectedParticipant) && vm.selectedParticipant.name === selectedItem.name) {
      vm.removeSelection();
      return;
    }
    vm.selectedParticipant = selectedItem;
    //update participant list
    angular.forEach(vm.participants, function (participant, index) {
      if (selectedItem.name === participant.name) {
        participant.isSelected = true;
      } else {
        participant.isSelected = false;
      }
    });
    $scope.$emit('ParticipantSelected', selectedItem);
  };

  vm.isMultipoint = function () {
    return CallService.isMultipoint();
  };

  vm.removeSelection = function () {
    //update participant list
    angular.forEach(vm.participants, function (participant, index) {
      participant.isSelected = false;

    });
    $scope.$emit('ParticipantUnSelected', vm.selectedParticipant);
    vm.selectedParticipant = {};
  };

  const startsWith = (string, prefix) => (
    string !== undefined &&
    prefix !== undefined &&
    (string.indexOf(prefix) === 0)
  );

  const isParticipantToBeShown = (participant) => {
    for (let prefix of ignoreParticipantNamePrefixes) {
      if (startsWith(participant.name, prefix)) {
        $log.debug(`${vm.controllerName}: Ignoring participant ${participant.name}`);
        return false;
      }
    }
    return true;
  };

  /*
   Public API end >>>>
   */
  /*
   <<<<Private API
   */
  var init = function () {
    $log.debug('init ' + vm.controllerName);
    vm.participants = [];
    vm.selectedParticipant = {};

    CallService.getParticipants()
      .then(function (participants) {
        const filteredParticipants = participants.filter((participant) => isParticipantToBeShown(participant));
        vm.participants = filteredParticipants;
      });
  };

  function mergeParticipant(vvr, participant) {
    $log.debug(vm.controllerName + ' mergeParticipant: ' + JSON.stringify(participant));

    if (!isParticipantToBeShown(participant)) {
      return;
    }

    CallService.getParticipant(vvr, participant)
      .then(function (augmentedParticipant) {
        deleteParticipant(augmentedParticipant.id);
        vm.participants.push(augmentedParticipant);
      });
  }

  function deleteParticipant(participantId, isStopCall) {
    $log.debug(vm.controllerName + ' deleteParticipant: ' + JSON.stringify(participantId));
    for (var i = 0; i < vm.participants.length; i++) {
      if (vm.participants[i].id === participantId) {
        vm.participants.splice(i, 1);

        if (isStopCall) {
          CallService.stopCurrentCall().then(function (call) {
              if (call.stopOnReject) {
                ModalManager.close('inCall');
              }
            })
            .catch(function (error) {
              // FIXME Handle
            });
        }
        break;
      }
    }
  }

  /*
   Private API end>>>>>>
   */
  /*
   Listeners
   */
  $scope.$on('otn-call-ParticipantCreated', function (angularEvent, call, participant) {
    $log.debug(vm.controllerName + ' received otn-call-ParticipantCreated: ' + JSON.stringify(participant));
    mergeParticipant(call.vvrData, participant);
  });
  $scope.$on('otn-call-ParticipantUpdated', function (angularEvent, call, participant) {
    $log.debug(vm.controllerName + ' received otn-call-ParticipantUpdated: ' + JSON.stringify(participant));
    mergeParticipant(call.vvrData, participant);
  });
  $scope.$on('otn-call-ParticipantDeleted', function (angularEvent, call, participant) {
    $log.debug(vm.controllerName + ' received otn-call-ParticipantDeleted: ' + JSON.stringify(participant));
    deleteParticipant(participant.id, true);
  });
  /*
   init
   */
  init();
});
;'use strict';
angular.module('otn.directives.vidyo').controller('PreCallModalConsultantFormController', function (
  $scope, $log, Notifier, Users, EventsService) {
  const vm = this;

  const eventContactToSearchText = (eventContact) => {
    if (!eventContact) {
      return '';
    }
    const { name } = eventContact;
    return name;
  };

  vm.form = {
    consultantSearchText: eventContactToSearchText($scope.connectToForm.eventContact)
  };

  $scope.$watch('connectToForm.eventContact.name', function() {
    vm.form.consultantSearchText = eventContactToSearchText($scope.connectToForm.eventContact);
  });

  const eventTypeToConsultantPlaceholder = {
    [$scope.inputOptions.type[0]]: 'Search for consultant', // Clinical
    [$scope.inputOptions.type[1]]: 'Enter speaker name', // Learning
    [$scope.inputOptions.type[2]]: 'Enter chair name', // Meeting
  };

  vm.getConsultantLabel = () => {
    const typeCode = EventsService.typeStringToCode($scope.connectToForm.type);
    return EventsService.eventTypeToConsultantRole(typeCode);
  };

  vm.getConsultantPlaceholder = () => {
    return eventTypeToConsultantPlaceholder[$scope.connectToForm.type];
  };

  vm.formatConsultantName = (consultant) => {
    if (!consultant || !consultant.name) {
      return '';
    }
    return (consultant.name + (consultant.orgName ? ', ' + consultant.orgName : ''));
  };

  vm.onConsultantSearchChange = function () {
    $scope.connectToForm.eventContact = {};
  };

  vm.onConsultantSearchBlur = function () {
    if (!$scope.connectToForm.eventContact.name) {
      vm.form.consultantSearchText = '';
    }
  };

  // TODO: Move to string utils
  const contains = (searchIn, searchFor) => (searchIn.toLowerCase().indexOf(searchFor.toLowerCase()) >= 0);

  vm.fetchConsultants = function (searchText) {
    const fetchLimit = 30;
    return new Promise((resolve, reject) => {
      let role = $scope.connectToForm.type
        ? vm.getConsultantLabel().toLowerCase()
        : undefined;
      Users.searchContacts({
        query: searchText,
        role
      }, (response) => {
        $log.debug('fetchConsultants returned: ', response);
        let contacts = [...response];

        // MT never returns myself so we add it manually
        const myContact = $scope.buildMyContact();
        if (contains(myContact.name, searchText)) {
          contacts.unshift(myContact);
        }

        contacts = contacts.slice(0, fetchLimit);
        resolve(contacts);
      }, (rejection) => {
        $log.error('fetchConsultants rejected - rejection:' + rejection);
        // TODO: need generic error message
        Notifier.createMessage('error', 'An error has occurred, please try again.', '', JSON.stringify(rejection));
        reject(rejection);
      });
    });
  };

  vm.onConsultantSelect = (consultant) => {
    $scope.connectToForm.eventContact = $scope.consultantToEventContact(consultant);
  };

});;'use strict';
angular.module('otn.directives.vidyo').controller('PreCallModalController', function($log, $rootScope, $scope,
                                                                                     $location, $cookies, ModalManager, VideoService, $filter, $q, event, options, User, Events, Systems, Notifier,
                                                                                     VidyoMessages, debounce, ngAudio, Favourites, Profiles, FavouritesServiceV2, $timeout, Users, $anchorScroll,
                                                                                     CallService, VirtualVisits, PresenceService, EventsService, FavouritesServiceV3, Search) {

  $scope.className = 'PreCallModalController';
  $log.info(`${$scope.className} initializing`);

  const pages = {
    ADHOC: 'ADHOC',
    SCHEDULE: 'SCHEDULE',
    SCHEDULE_CONFIRM: 'SCHEDULE_CONFIRM'
  };
  $scope.pages = pages;

  var ringing;
  $anchorScroll.yOffset = 30;
  $scope.participantCapacity = null;

  $scope.userDetails = User.details;
  $scope.inputOptions = options;

  $scope.viewState = {};
  // $scope.viewState.isScheduling = false;
  const initConnectToForm = () => {
    const {
      followupRequestId,
      type,
      title,
      numPatients,
      patientPresent,
      startTime,
      endTime,
      hostPin,
      guestPin,
      eventContact,
      participants,
      patients
    } = event;
    const {
      systems,
      guests,
      offnet
    } = participants;
    const isOffnetParticipants = !!(offnet && offnet.length > 0);
    const isGuestPinRequired = !!(guestPin || isOffnetParticipants);

    const newHostPin = hostPin || EventsService.generateRandomPin();

    let newGuestPin = '';
    if (guestPin) {
      newGuestPin = guestPin;
    } else if (isGuestPinRequired) {
      newGuestPin = EventsService.generateRandomPin(newHostPin);
    }

    $scope.connectToForm = {
      type: type || EventsService.getTypes()[0],
      title,
      numPatients,
      patientPresent,
      eventContact: eventContact || {},
      participants: {
        systems: systems || [],
        guests: guests || [],
        offnet: offnet || []
      },
      hostPin: newHostPin,
      isGuestPinRequired,
      isGuestPinToggleDisabled: isOffnetParticipants,
      guestPin: newGuestPin,
      patients: patients || [],
      pcvcConflictOptOut: $scope.initPcvcConflictOptOut()
    };

    if (followupRequestId) {
      $scope.connectToForm.followupRequestId = followupRequestId;
    }

    if (startTime && endTime) {
      $scope.connectToForm.schedule = {
        startTime: startTime,
        endTime: endTime
      };
    }
  };

  // References to HTML form elements, for validation
  $scope.formElements = {
    connectTo: null,
    schedule: null
  };

  EventsService.init();
  FavouritesServiceV3.init();

  Favourites.list(function(favourites) {
    User.actions.resolveFavourites(favourites);
  });

  // $scope.isSelectedSystemFavourite = function () {
  //   return FavouritesServiceV3.isFavouriteSystem($scope.systemSearch.selectedSystem);
  // };

  $scope.isInternetExplorer = function() {
    return VideoService.isInternetExplorer();
  };

  $scope.isClinicalEvent = function() {
    return ($scope.connectToForm.type === $scope.inputOptions.type[0]);
  };

  // $scope.isSystemSelected = function () {
  //   return ($scope.systemSearch.selectedSystem && $scope.systemSearch.selectedSystem.id > 0);
  // };

  const getMySystemParticipant = () => {
    const filteredSystems = $scope.connectToForm.participants.systems.filter((system) => system.isMySystem);
    return (filteredSystems.length === 1) ? filteredSystems[0] : null;
  };

  const getAllParticipants = () => {
    const {systems, guests, offnet} = $scope.connectToForm.participants;
    if (!systems || !guests || !offnet) {
      return [];
    }
    return [...systems, ...guests, ...offnet];
  }

  $scope.isCallButtonDisabled = function() {
    if ($scope.isInternetExplorer() || $scope.viewState.isConnecting) {
      return true;
    }

    const allParticipants = getAllParticipants();
    if (allParticipants.length < 2) {
      return true;
    }

    if (!getMySystemParticipant()) {
      return true;
    }

    // Decision: Don't disable call button if a participant is offline
    // const offlineSystems = systems.filter((system) => (system.presence !== 'online'));
    return false;
  };

  $scope.initPcvcConflictOptOut = function() {
    // CS1-4042
    return false;
  };

  $scope.onChangePcvcConflictOptOut = function() {
    localStorage.setItem('pcvcConflictOptOut', $scope.connectToForm.pcvcConflictOptOut);
  };


  $scope.isScheduleButtonDisabled = () => {
    const allParticipants = getAllParticipants();
    if (allParticipants.length < 2) {
      return true;
    }

    return false;
  };

  $scope.isLegacySystemDisclaimerShown = () => {
    let hasLegacyParticipant = false;
    $scope.connectToForm.participants.systems.forEach((system) => {
      if (system.type === 'site') {
        hasLegacyParticipant = true;
      }
    });
    return hasLegacyParticipant &&
      $scope.viewState.currentPage === pages.ADHOC;
  };

  $scope.roomOrgName = function(roomName, orgName) {
    $log.info('roomOrgName start');
    $log.debug('roomOrgName - roomName: ' + roomName + ', orgName: ', orgName);

    var output = '';
    if (typeof roomName === 'undefined') {
      roomName = '';
    }

    if (typeof orgName === 'undefined') {
      orgName = '';
    }

    var shortRoomName = $filter('ellipsis')(roomName, 15);
    if (shortRoomName === roomName) {
      output = shortRoomName + ' ' + orgName;
    } else{ // has an ellipsis to separate room name and org name
      output = shortRoomName + orgName;
    }

    $log.info('roomOrgName end');
    return output.trim();
  };

  $scope.cancel = function() {
    $log.warn('PreCallModalController: cancel()');
    ModalManager.close('preCall');
  };

  // Switch between "Connect Now" and "Schedule Events"
  $scope.connectToChange = function(newPage) {
    // Sets "view mode" to the opposite of the current value, to toggle between Member and Guest
    $scope.viewState.currentPage = newPage;
    if (newPage === pages.ADHOC) {
      delete $scope.connectToForm.schedule;
    }
  };

  const updateGuestCapacity = function() {
    const {type} = $scope.connectToForm;
    const {clinicalMaxParticipants, nonClinicalMaxParticipants} = $scope.eventsConfig;
    if (type === $scope.inputOptions.type[0]) { // clinical event
      $scope.participantCapacity = clinicalMaxParticipants;
    } else{ // learning event or meeting
      $scope.participantCapacity = nonClinicalMaxParticipants;
    }
  };

  $scope.eventTypeChange = function() {
    const {type} = $scope.connectToForm;
    const {inputOptions, connectToForm} = $scope;
    if (type === inputOptions.type[0]) { // clinical event
      connectToForm.title = 'Clinical event';
    } else if (type === inputOptions.type[1]) { // learning event
      connectToForm.title = 'Learning event';
    } else if (type === inputOptions.type[2]) { // meeting event
      connectToForm.title = 'Meeting';
    }
    updateGuestCapacity();
  };

  $scope.fetchRemotePresence = function(system) {
    $log.debug('fetchRemotePresence() system: ', system);
    system.status = 'current-status-spinner';
    PresenceService.fetchRemotePresence(system)
      .then(function(response) {
        $log.debug('PresenceService.fetchRemotePresence resolved: ', response);
      })
      .catch(function(rejection) {
        $log.error('PresenceService.fetchRemotePresence rejected: ', rejection);
        let reference = '';
        if (typeof rejection.data !== 'undefined' && typeof rejection.data.reference !== 'undefined') {
          reference = 'error reference: ' + rejection.data.reference;
        }
        Notifier.createMessage('error', VidyoMessages.call.participantOffline.message, VidyoMessages.call.participantOffline.solution, rejection.status + '\n' + rejection.statusText + '\n' + reference);
      });
  };

  // TODO: CS1-2673:  What does this "ok" mean?
  $scope.ok = function() {
    $log.info('ok start');
    ModalManager.close('preCall');
    location.reload();
    $log.info('ok end');
  };

  // TODO: Take this out to a common util.
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/parseInt
  const filterInt = function(value) {
    if (/^(\-|\+)?([0-9]+|Infinity)$/.test(value)) {
      return Number(value);
    }
    return 0;
  };

  $scope.onPatientNumberChange = function() {
    $log.info('onPatientNumberChange start');
    var numberOfPatients;
    try {
      numberOfPatients = filterInt($scope.connectToForm.numPatients);
    } catch (e) {
      numberOfPatients = 0;
      $log.info(e.message);
      Notifier.createMessage('error', VidyoMessages.call.invalidPatientNumber.message, VidyoMessages.call.invalidPatientNumber.solution);
    }
    if (numberOfPatients < 1 || numberOfPatients > 999) {
      $scope.connectToForm.numPatients = 1;
      Notifier.createMessage('error', VidyoMessages.call.invalidPatientNumber.message, VidyoMessages.call.invalidPatientNumber.solution);
    }
    $log.info('onPatientNumberChange end');
  };

  $scope.inputNotification = function(event, action) {
    var element = angular.element(event.target);
    switch (action) {
      case 'focus':
        element.addClass('pcvc-guest-link-focus');
        element.removeClass('pcvc-guest-link-blur');
        break;
      case 'blur':
        element.addClass('pcvc-guest-link-blur');
        element.removeClass('pcvc-guest-link-focus');
        break;
    }
  };

  //PCVC-916
  $scope.onChangeGuestPin = () => {
    if (!$scope.connectToForm.isGuestPinRequired) {
      $scope.connectToForm.guestPin = '';
    } else if (!$scope.connectToForm.guestPin) {
      $scope.connectToForm.guestPin = EventsService.generateRandomPin($scope.connectToForm.hostPin);
    }
  };

  //TODO: CS1-2673: Move to String Util / Service
  const concatMessages = function(targetMessage, messageToConcatenate) {
    let output = targetMessage;
    if (targetMessage.length > 0) {
      output += '<br> - ';
    }
    output += messageToConcatenate;
    return output;
  };

  // Validate entire connect form
  const isConnectToFormValid = function() {
    const {participants, eventContact, hostPin, guestPin, isGuestPinRequired} = $scope.connectToForm;
    const allParticipants = [...participants.systems, ...participants.guests, ...participants.offnet];
    const numParticipants = allParticipants.length;
    let displayMessage = '';
    const isClinical = $scope.isClinicalEvent();

    if (numParticipants < 2) {
      Notifier.createMessage('error', VidyoMessages.otnInvite.notEnoughParticipants.message, null);
      return false;
    } else if (numParticipants > $scope.participantCapacity) {
      displayMessage = concatMessages(displayMessage, VidyoMessages.otnInvite.msg42.message);
    }

    const hostSystems = allParticipants.filter((system) => system.isHost);
    if (hostSystems.length < 1) {
      const message = isClinical ?
        VidyoMessages.otnInvite.noConsultantSystem.message :
        VidyoMessages.otnInvite.noHostSystem.message;
      displayMessage = concatMessages(displayMessage, message);
    }

    if (!eventContact.id) {
      const message = isClinical ?
        VidyoMessages.otnInvite.noConsultant.message :
        VidyoMessages.otnInvite.noPresenter.message;
      displayMessage = concatMessages(displayMessage, message);
    }

    const form = $scope.formElements.connectTo;
    if (form.guestPin) {
      //If pins are invalid (i.e. not correct length) their value is undefined
      form.guestPin.$setValidity('pinsMatch', !hostPin || !guestPin || hostPin !== guestPin);
    }
    if (form.$invalid) {
      $log.debug(VidyoMessages.otnInvite.msg01.message);
      displayMessage = concatMessages(displayMessage, VidyoMessages.otnInvite.msg01.message);
    }
    if (form.hostPin && form.hostPin.$invalid) {
      $log.debug(VidyoMessages.otnInvite.hostPinLength.message);
      displayMessage = concatMessages(displayMessage, VidyoMessages.otnInvite.hostPinLength.message);
    }
    if (isGuestPinRequired === true && form.guestPin.$invalid) {
      if (form.guestPin.$error.pinsMatch) {
        $log.debug(VidyoMessages.otnInvite.pinsMatch.message);
        displayMessage = concatMessages(displayMessage, VidyoMessages.otnInvite.pinsMatch.message);
      } else{
        $log.debug(VidyoMessages.otnInvite.guestPinLength.message);
        displayMessage = concatMessages(displayMessage, VidyoMessages.otnInvite.guestPinLength.message);
      }
    }

    if (displayMessage.length > 0) {
      $log.debug(displayMessage);
      Notifier.createMessage('error', displayMessage, null);
      return false;
    }
    return true;
  };

  const isScheduleFormValid = function() {
    $log.info('isScheduleFormValid()', $scope.connectToForm);
    const {startTime, endTime} = $scope.connectToForm.schedule;

    const now = new Date();
    const todayDate = new Date($filter('date')(now, 'MM/dd/yyyy'));
    const scheduledDate = new Date($filter('date')(startTime, 'MM/dd/yyyy'));
    let displayMessage = '';

    if (scheduledDate < todayDate) {
      displayMessage = concatMessages(displayMessage, VidyoMessages.otnInvite.msg23.message);
    } else if (startTime < now) {
      displayMessage = concatMessages(displayMessage, VidyoMessages.otnInvite.msg24.message);
    }
    if (endTime <= startTime) {
      displayMessage = concatMessages(displayMessage, VidyoMessages.otnInvite.msg25.message);
    }

    if (displayMessage.length > 0) {
      $log.debug(`isScheduleFormValid(): invalid. Message: ${displayMessage}`);
      Notifier.createMessage('error', displayMessage, null);
      return false;
    }
    return true;
  };

  const packageEventDetails = function() {
    return EventsService.packageEventDetails($scope.connectToForm);
  };

  /**
   * MT Validation of the current event. Checks things that cannot be checked on the FE (i.e. checks that at least one
   * of the participants is the current user's or their delegator's system).
   *
   * @returns {Promise<unknown>}
   */
  const validateEventMT = () => {
    const event = packageEventDetails();

    return new Promise((resolve, reject) => {
      Events.validate(
        event,
        (response) => resolve(response),
        (err) => reject(err.data)
      );
    });
  };

  const openSchedulePage = () => {
    $scope.viewState.currentPage = pages.SCHEDULE_CONFIRM;
  };

  const processMTValidationError = (errorData) => {
    const {error, details} = errorData;
    if (error === 'E_VALIDATION_ERROR' && details === 'NO_OWN_OR_DELEGATOR_SYSTEM') {
      Notifier.createMessage('error', VidyoMessages.otnInvite.noOwnOrDelegatorSystem.message, null);
    } else{
      Notifier.createMessage('error', 'Validation error.', null, JSON.stringify(errorData));
    }
  };

  /**
   * Place an adhoc "OTN Invite"/multipoint call.
   * - If we have guest participants, a confirmation modal is shown.
   * - Otherwise the call is started right away
   */
  $scope.placeMultipointCall = function() {
    $log.info('placeMultipointCall()');

    if (!isConnectToFormValid()) {
      $log.debug('placeMultipointCall(): Form is invalid.');
      return;
    }

    // Clear schedule data if this is a copy of a scheduled event
    $scope.connectToForm.schedule = {};

    // If no guest participants, start call without showing confirm box
    const {guests, offnet} = $scope.connectToForm.participants;
    const nonOtnParticipants = [...guests, ...offnet];
    if (!nonOtnParticipants || nonOtnParticipants.length === 0) {
      $scope.viewState.isConnecting = true;

      const call = {
        createEventRequest: packageEventDetails()
      };

      CallService.createCall(call)
        .then(function(call) {
          $scope.viewState.isConnecting = false;
        })
        .catch(function(rejection) {
          $scope.viewState.isConnecting = false;
        });
    } else{
      //open confirm call modal
      ModalManager.open('preCallConfirm', {
        event: packageEventDetails(),
        onConfirm: function() {
          $scope.viewState.isConnecting = true;
        },
        onSuccess: function() {
          $scope.viewState.isConnecting = false;
        },
        onFailure: function(err) {
          $scope.viewState.isConnecting = false;
        }
      });
    }
  };

  const initScheduleTimes = (eventTime) => {

    var startTime = null;
    var endTime = null;

    //Jira#CS1-3430 : preserve old event date and time when even copied
    if ($scope.viewState && $scope.viewState.isEventCopy) {
      startTime = eventTime.start;
      endTime = eventTime.end;
      $scope.connectToForm.schedule = {
        startTime,
        endTime
      };
      return;
    }

    // TODO: Make these configurable:
    const defaultStartNextMin = 15;
    const defaultIntervalMin = 15;

    const msInMin = 60 * 1000;
    const defaultIntervalMs = defaultIntervalMin * msInMin;

    startTime = new Date();
    startTime.setSeconds(0);
    startTime.setMilliseconds(0);

    // Set start mins by adding 1 minute to the current time, and finding the next 15 min from there
    const newMins = Math.ceil((startTime.getMinutes() + 1) / defaultStartNextMin) * defaultStartNextMin;
    startTime.setMinutes(newMins);

    endTime = new Date(startTime.getTime() + defaultIntervalMs);

    $scope.connectToForm.schedule = {
      startTime,
      endTime
    };

  };

  $scope.doSchedule = function() {

    //Jira#CS1-3430 : preserve old event date and time when even copied
    var eventTime = {
      start: ($scope.connectToForm.schedule ? $scope.connectToForm.schedule.startTime : null),
      end: ($scope.connectToForm.schedule ? $scope.connectToForm.schedule.endTime : null)
    }

    $scope.connectToForm.schedule = {};
    if (!isConnectToFormValid()) {
      $log.debug('connect form is invalid');
      return;
    }

    if($scope.isClinicalEvent() 
      && $scope.connectToForm.participants.guests
      && $scope.connectToForm.participants.guests.length > 0
      && $scope.connectToForm.patients
      && $scope.connectToForm.patients.length > 0
    ) {
      delete $scope.connectToForm.patients[0].id
    } 

    Events.validate(
      packageEventDetails(),
      (response) => {
        initScheduleTimes(eventTime);
        openSchedulePage(response)
      },
      (err) => processMTValidationError(err.data)
    );
  };

  $scope.scheduleConfirm = function() {
    $log.info(`${$scope.className} scheduleConfirm isScheduling = ${$scope.viewState.isScheduling}`);
    if (!isScheduleFormValid()) {
      $log.debug('schedule form is invalid');
      return;
    }

    // create event
    ModalManager.open('preCallConfirm', {
      event: packageEventDetails(),
      onConfirm: function() {
        $scope.viewState.isScheduling = true;
        $log.info(`${$scope.className} preCallConfirm onConfirm isScheduling = ${$scope.viewState.isScheduling}`);
      },
      onSuccess: function() {
        $scope.viewState.isScheduling = false;
        $log.info(`${$scope.className} preCallConfirm onSuccess isScheduling = ${$scope.viewState.isScheduling}`);
      },
      onFailure: function(err) {
        $scope.viewState.isScheduling = false;
        $log.info(`${$scope.className} preCallConfirm onFailure isScheduling = ${$scope.viewState.isScheduling}`);
      }
    });
  };

  $scope.scheduleBack = function() {
    $scope.viewState.currentPage = pages.SCHEDULE;
  };

  const initEventsConfig = function() {
    Events.config(function(eventsConfig) {
      $scope.eventsConfig = eventsConfig;
      updateGuestCapacity();
    }, function(err) {
      //TODO: CS1-2673: Handle this more gracefully
      $log.error('Error getting config! ' + JSON.stringify(err));
      Notifier.createMessage('error', 'An error has occurred, please try again.', null, JSON.stringify(err));
    });
  };

  const initViewProps = function() {
    const isEventCopy = options.calledFrom === 'event-copy';
    const isParticipantEditable = true; //(system && system.id > 0) ? false : true;
    $scope.viewState = {
      isEventCopy: isEventCopy,
      isParticipantEditable: isParticipantEditable,
      isEventTypeToggleShown: isParticipantEditable,
      currentPage: options.viewMode || pages.ADHOC,
      isConnecting: false,
      isScheduling: false
    };
  };

  /**
   * Go through all participants, fetch their details from Profiles and fetch their presence.
   */
  const initParticipantSystems = () => {
    $scope.connectToForm.participants.systems.forEach((systemParticipant) => {
      const systemId = systemParticipant.id;
      if (systemId) {
        systemParticipant.isLoading = true;
        Profiles.get({
          type: 'systems',
          id: systemId
        }, (profile) => {
          $log.debug('Received profile: ', profile);
          const {contactId, systemName, organization, studioType} = profile;

          systemParticipant.isLoading = false;
          systemParticipant.contactId = contactId;
          systemParticipant.name = systemName;
          systemParticipant.organization = organization;

          if (studioType === 'PC_VIDEO') {
            systemParticipant.type = 'hcp';
          } else if (studioType === 'HARDWARE_VIDEO') {
            systemParticipant.type = 'site';
          }

          $scope.fetchRemotePresence(systemParticipant);
        }, function(err) {
          //TODO: CS1-2673: Handle this more gracefully
          $log.error('Profiles.get for ID "' + systemParticipant.id + '" failed. Reason was: ' + JSON.stringify(err));
          Notifier.createMessage('error', 'An error has occurred, please try again.', '', JSON.stringify(err));
        });
      }
    });
  };

  // This is to be consistent with PresenceService.fetchRemotePresence. Otherwise it's pointless.
  const presenceToStatus = (presence) => (`current-status-${presence}`);

  $scope.systemToParticipant = (system) => {
    const {systemId, contactId, userId, isMySystem, systemType, systemName, roomName, siteName} = system;

    const participant = {
      id: systemId,
      contactId,
      userId,
      isMySystem
    };
    var isPCVCHostSite = systemName && systemName.indexOf('_HOST_') !== -1;
    if (systemType === 'PC_VIDEO' && !isPCVCHostSite) {
      participant.name = $filter('fullName')(system);
      participant.type = 'hcp';
      participant.organization = $filter('generateOrganizationNameWithPrefix')(system);
    }
    if (systemType === 'PC_VIDEO' && isPCVCHostSite) {
      participant.name = systemName;
      participant.type = 'hcp';
      participant.organization = siteName;
    }
    else if (systemType === 'HARDWARE_VIDEO') {
      participant.name = systemName;
      participant.type = 'site';
      const systemRoomName = roomName || '';
      const systemOrgName = $filter('generateOrganizationNameWithPrefix')(system);
      participant.organization = $scope.roomOrgName(systemRoomName, systemOrgName);
    }

    return participant;
  };

  /*
   This is because the MT does not return the system id for my system. Therefore for my system the only identifier is
   the isMySystem flag. If the MT returned a system id, this could be deleted and replaced by a simple comparison of
   two ids.
   */
  $scope.isSameSystemParticipant = (participant1, participant2) => {
    if (participant1.id && participant2.id && participant1.id === participant2.id) {
      return true;
    } else if (participant1.isMySystem === true && participant2.isMySystem === true) {
      return true;
    }
    return false;
  };

  $scope.buildMySystem = () => {
    const {contactid, organization, salutation, firstName, lastName} = User.details;

    return {
      contactId: contactid,
      systemType: 'PC_VIDEO',
      isMySystem: true,
      salutation,
      firstName,
      lastName,
      organization
    };
  };

  $scope.buildMySystemParticipant = () => {
    const mySystem = $scope.buildMySystem();
    const presence = PresenceService.getMyPresence();
    const participant = $scope.systemToParticipant(mySystem);
    const mySystemParticipant = {
      ...participant,
      presence,
      status: presenceToStatus(presence),
      isMySystem: true
    };
    return mySystemParticipant;
  };

  const buildSystemEventContact = ({contactId, userId, name}) => ({
    id: contactId,
    userId,
    name
  });

  /**
   * @returns {{firstName, lastName, contactId, name: string, salutation, userId}} - Same format as the MT Users.searchContacts
   */
  $scope.buildMyContact = () => {
    const {contactid, userid, salutation, firstName, lastName} = User.details;
    return {
      contactId: contactid,
      userId: userid,
      firstName,
      lastName,
      salutation,
      name: `${salutation || ''} ${firstName || ''} ${lastName || ''} (myself)`
    };
  };

  /*
   Converts MT format (Users.searchContacts) to format expected by event.eventContact
   */
  $scope.consultantToEventContact = ({contactId, userId, name}) => ({
    id: contactId,
    userId,
    name
  });

  /**
   * @returns {{name: string, id, userId}} - Same format as in event.eventContact
   */
  const buildMyEventContact = () => {
    const consultant = $scope.buildMyContact();
    return $scope.consultantToEventContact(consultant);
  };

  $scope.updateEventContactFromSystem = (system) => {
    if (!system.contactId) {
      $scope.connectToForm.eventContact = {};
      return;
    }
    $scope.connectToForm.eventContact = system.isMySystem ?
      buildMyEventContact() :
      buildSystemEventContact(system);
  };

  $scope.fetchRemotePresence = (system) => {
    PresenceService.fetchRemotePresence(system)
      .then(function(response) {
        $log.debug('PresenceService.fetchRemotePresence resolved: ', response);
        $scope.connectToForm.participants.systems.forEach((participantSystem) => {
          if ($scope.isSameSystemParticipant(participantSystem, system)) {
            //response.status: "current-status-online", response.presence: "online"
            participantSystem.status = response.status;
            participantSystem.presence = response.presence;
          }
        });
      })
      .catch(function(rejection) {
        $log.error('PresenceService.fetchRemotePresence rejected: ', rejection);
      });
  };

  // Only show the consultant form if the host is selected and is not PCVC
  $scope.isConsultantFormShown = () => {
    const allParticipants = getAllParticipants();
    const hostSystems = allParticipants.filter((system) => system.isHost);
    return (hostSystems.length === 1 && !hostSystems[0].contactId);
  };

  const getMyDelegators = () => {
    return new Promise((resolve, reject) => {
      Users.delegators(
        (data) => resolve(data),
        (err) => reject(err)
      );
    });
  };

  /**
   * Convert a delegator object (as returned by /delegator/pcvc) to a participant system object.
   * @param delegator
   * @returns {{contactId, organization: (string|string), name, id, type: (string), userId}}
   */
  const delegatorToParticipantSystem = (delegator) => {
    const {contactId, systemId, userId, name, organization} = delegator;
    return {
      id: systemId,
      contactId,
      name,
      userId,
      'type': userId ? 'hcp' : 'site',
      'organization': (organization ? `from ${organization}` : '')
    };
  };

  /**
   * Initializes participants list with my system.
   *
   * Case 1: I am not a delegate. Add me as participant.
   * Case 2: I am a delegate for one specialist. Add them as participant.
   * Case 3: I am a delegate for multiple specialists. Keep participants list empty.
   */
  const initMySystem = () => {
    if (options.calledFrom === 'event-copy') {
      // If copying, use the participants list as is, don't add my system.
      return;
    }

    const addMySystemToParticipants = () => {
      const mySystemParticipant = $scope.buildMySystemParticipant();
      mySystemParticipant.isHost = true;
      $scope.connectToForm.participants.systems.unshift(mySystemParticipant);

      const myEventContact = buildMyEventContact();
      $scope.connectToForm.eventContact = myEventContact;
    };

    const addDelegatorSystemToParticipants = (delegator) => {
      $log.debug('addDelegatorSystemToParticipants()', delegator);
      const delegatorSystem = delegatorToParticipantSystem(delegator);
      delegatorSystem.isHost = true;
      $scope.connectToForm.participants.systems.unshift(delegatorSystem);
      $scope.updateEventContactFromSystem(delegatorSystem);
      $scope.fetchRemotePresence(delegatorSystem);
    };

    const addDelegatorSystemToParticipantsFromStore = (delegators) => {
      $log.debug('addDelegatorSystemToParticipantsFromStore()', delegators);
      var host = $location.host();
      var subDomain = host;
  
      if (host.indexOf('.') > 0) {
        subDomain = host.substring(host.indexOf('.'));
      } 
  
      var cookieId = 'delegatorId-delegate-' + $scope.userDetails.userid;
      var cookieOptions = {
        domain: subDomain
      };
  
      var cookieValue = $cookies.get(cookieId, cookieOptions);
      if (cookieValue) {
          cookieValue = JSON.parse(cookieValue);
          var delegatorUserId = cookieValue.delegatorUserId;
          var delegatorSystemId = cookieValue.delegatorSystemId;
          for (var i = 0; i < delegators.length; i++) { 
            var delegator = delegators[i];
            if (delegatorUserId === delegator.userId &&
              delegatorSystemId === delegator.systemId) {
                addDelegatorSystemToParticipants(delegators[i]);
                break;
              }
          }
      } 
    }

    getMyDelegators().then((delegators) => {
      if (delegators.length === 0) {
        addMySystemToParticipants();
      } else if (delegators.length === 1) {
        addDelegatorSystemToParticipants(delegators[0]);
      } else if (delegators.length > 1) {
        addDelegatorSystemToParticipantsFromStore(delegators);
      }
    }).catch((err) => {
      $log.error('Could not find out if current user is a delegate. ', err);
    });
  };

  // This is added here as the presence changes does not reflect otherwise
  $scope.$on('otn-realtime-presence-change', function(angularEvent, videoEvent) {
    const presence = PresenceService.getMyPresence();
    const status = presenceToStatus(presence);

    const mySystem = getMySystemParticipant();
    if (mySystem) {
      mySystem.presence = presence;
      mySystem.status = status;
    }
  });

  const init = function() {
    initEventsConfig();
    initViewProps();
    initConnectToForm();
    initParticipantSystems();
    initMySystem();
  };

  init();

  $scope.$on('$destroy', function() {
    // will be undefined if placeCall was never clicked
    if (VideoService.isAudioEnabled() && typeof ringing !== 'undefined') {
      ringing.stop();
    }
  });

});
;'use strict';
angular.module('otn.directives.vidyo').controller('PreCallModalParticipantFormController', function (
  $scope, $log, $filter, Notifier, PresenceService, Systems, Users, VidyoMessages, ConfirmationModalService, PatientRegistry) {
  var vm = this;

  vm.participantTypes = [{
    label: 'Guest via email (OTNinvite)',
    value: 'guest'
  }, {
  //   label: 'Patient',
  //   value: 'patient'
  // }, {
    label: 'OTN Member or System',
    value: 'otn_system'
  }, {
    label: 'Non-OTN system (standards-based)',
    value: 'offnet_system'
  }];

  vm.form = {
    type: localStorage.getItem('participantType'),
    participant: {
      formErrors: {
        ohip: ''
      }
    },
    systemSearchText: ''
  };

  vm.onChangeParticipantType = function () {
    localStorage.setItem('participantType', vm.form.type);
    vm.form.participant = {};
    vm.form.systemSearchText = '';
    $scope.participantForm.$setPristine();
  };

  vm.onSystemSearchChange = function (newValue, oldValue) {
    vm.form.participant = {};
  };

  const systemToSearchResult = (system) => ({
    ...system,
    displayText: $filter('generateTypeaheadDisplayText')(system, true)
    // displayTextPretty: $filter('generateTypeaheadDisplayText')(system, false)
  });

  // TODO: CS1-2673: See if this "(my system)" can be consolidated with that in PreCallModalController.buildMyConsultant
  const buildMySystemSearchResult = () => {
    const system = $scope.buildMySystem();
    const result = systemToSearchResult(system);
    result.displayText += ' (my system)';
    // result.displayTextPretty += ' (my system)';
    return result;
  };

  // TODO: Move to string utils
  const contains = (searchIn, searchFor) => (searchIn.toLowerCase().indexOf(searchFor.toLowerCase()) >= 0);

  const isSystemInList = (systemParticipants, system) => {
    const participantToFind = $scope.systemToParticipant(system);
    let inList = false;
    systemParticipants.forEach((participant) => {
      if ($scope.isSameSystemParticipant(participant, participantToFind)) {
        inList = true;
      }
    });
    return inList;
  };

  vm.fetchSystems = function (searchText) {
    $log.info('fetchSystems start');
    const fetchLimit = 30;
    return new Promise((resolve, reject) => {
      Systems.search({
        q: searchText
      }, function (response) {
        let systems = response.slice(0, fetchLimit);
        systems = systems.map((system) => systemToSearchResult(system));

        // MT never returns my system so we add it manually
        const mySystem = buildMySystemSearchResult();
        if (contains(mySystem.displayText, searchText)) {
          systems.unshift(mySystem);
        }

        // Filter out systems that are already selected
        systems = systems.filter((system) => (
          !isSystemInList($scope.connectToForm.participants.systems, system)
        ));

        $scope.errorMessage = (systems.length === 0) ? 'No Results Found' : '';
        resolve(systems);
      }, function (rejection) {
        $log.debug('fetchSystems rejected - rejection:' + rejection);
        //TODO: need generic error message
        Notifier.createMessage('error', 'An error has occurred, please try again.', '', JSON.stringify(rejection));
        reject(rejection);
      });
    });
  };

  vm.onSystemSelect = function (system) {
    const participant = system.isMySystem ?
      $scope.buildMySystemParticipant() :
      $scope.systemToParticipant(system);
    vm.form.participant = participant;
    vm.addParticipant();
  };

  vm.fetchNonUserContacts = (searchText) => {
    const fetchLimit = 30;
    return new Promise((resolve, reject) => {
      Users.searchNonUserContacts({
        query: searchText
      }, (response) => {
        $log.debug('fetchNonUserContacts returned: ', response);
        let contacts = [...response];
        contacts = contacts.slice(0, fetchLimit);
        resolve(contacts);
      }, (rejection) => {
        $log.error('fetchNonUserContacts rejected - rejection:' + rejection);
        // TODO: need generic error message
        Notifier.createMessage('error', 'An error has occurred, please try again.', '', JSON.stringify(rejection));
        reject(rejection);
      });
    });
  };

  vm.formatContactName = (contact) => {
    if (!contact || !contact.name) {
      return '';
    }
    const { name, orgName, isConsultant } = contact;
    let label = name;
    if (orgName) {
      label += `, ${orgName}`;
    }
    if (isConsultant) {
      label += ' (consultant)';
    }
    return label;
  };

  vm.onContactNameChange = () => {
    delete vm.form.participant.contactId;
    delete vm.form.participant.isConsultant;
  };

  vm.onContactSelect = ({ contactId, name, isConsultant }) => {
    vm.form.participant = {
      ...vm.form.participant,
      name,
      contactId,
      isConsultant
    };
  };

  const validateAddParticipant = () => {
    const { participants } = $scope.connectToForm;
    const allParticipants = [...participants.systems, ...participants.guests, ...participants.offnet];
    if (allParticipants.length >= $scope.participantCapacity) {
      throw new Error(VidyoMessages.otnInvite.participantLimitReached.message);
    }
  };

  const addOtnParticipant = function () {
    const newSystemParticipant = vm.form.participant;
    if (angular.equals(newSystemParticipant, {})) {
      throw new Error(VidyoMessages.otnInvite.noSystem.message);
    }

    $scope.connectToForm.participants.systems.forEach((systemParticipant) => {
      if ($scope.isSameSystemParticipant(newSystemParticipant, systemParticipant)) {
        throw new Error(VidyoMessages.otnInvite.duplicateSystems.message);
      }
    });

    $scope.connectToForm.participants.systems.push(newSystemParticipant);
    vm.form.systemSearchText = '';
    $scope.fetchRemotePresence(newSystemParticipant);
  };

  const setGuestPinIfNeeded = () => {
    const { participants } = $scope.connectToForm;
    if (participants.offnet.length > 0) {
      $scope.connectToForm.isGuestPinRequired = true;
      $scope.connectToForm.isGuestPinToggleDisabled = true;
      $scope.onChangeGuestPin();
    }
  };

  const addNonOtnParticipant = function () {
    var errorMessages = '';
    var form = $scope.participantForm;
    if (form.$error.required) {
      errorMessages += VidyoMessages.otnInvite.msg01.message + '<br>';
    }
    if (form.guestName && form.guestName.$invalid) {
      errorMessages += VidyoMessages.otnInvite.guestNameTooShort.message + '<br>';
    }
    if (form.guestEmail && form.guestEmail.$invalid) {
      errorMessages += VidyoMessages.otnInvite.msg02.message + '<br>';
    }
    if (errorMessages) {
      throw new Error(errorMessages);
    }

    const newParticipantEmail = vm.form.participant.email;
    const { participants, patients } = $scope.connectToForm;
    const { guests, offnet } = participants;
    const nonOtnParticipants = [...guests, ...offnet];
    nonOtnParticipants.forEach(function (participant) {
      if (participant.email === newParticipantEmail) {
        form.guestEmail.duplicate = true;
        throw new Error(VidyoMessages.otnInvite.duplicateEmails.message);
      }
    });

    if (vm.form.type === 'patient') {
      const patient = vm.form.participant;
      ConfirmationModalService.showPatientConsentModal(function(){
        patient.name = `${patient.firstName} ${patient.lastName}`;
        participants.guests.push(patient);
        patients.push(patient);
      });
    } else if (vm.form.type === 'guest') {
      if($scope.isClinicalEvent() && vm.form.participant.isPHIConsent) {
        const guest = vm.form.participant;
        const patient = {
          isPHIConsent: vm.form.participant.isPHIConsent,
          lastName: vm.form.participant.name,
          email: vm.form.participant.email,
          gender: 'O'
        };
        participants.guests.push(guest);
        patients.push(patient);
      } else {
        participants.guests.push(vm.form.participant);
      }
    } else {
      participants.offnet.push(vm.form.participant);
    }
    setGuestPinIfNeeded();
  };

  vm.addParticipant = function () {
    try {
      validateAddParticipant();
      if (vm.form.type === 'otn_system') {
        addOtnParticipant();
      } else {
        addNonOtnParticipant();
      }
    } catch (e) {
      Notifier.createMessage('error', e.message, null);
      return;
    }

    vm.form.participant = {};
    $scope.participantForm.$setPristine();
  };

  vm.getNumParticipants = () => {
    const { systems, guests, offnet } = $scope.connectToForm.participants;
    return [...systems, ...guests, ...offnet].length;
  };

  vm.getMaxNumParticipants = () => ($scope.participantCapacity);

  const processPatientRegistryResponse = (response) => {
    if(response.resourceType === 'Patient') {
      vm.form.participant = {
        ...vm.form.participant,
        gender: response.gender,
        lastName: response.name.length > 0 ? response.name[0].family : '',
        firstName: response.name.length > 0 && response.name[0].given.length > 0 ? response.name[0].given[0] : ''
      }
    } else {

    }
  };

  vm.searchPatientbyOhip = () => {
    PatientRegistry.get({
      hcn: vm.form.participant.ohipNumber
    }, function(result){
      processPatientRegistryResponse(result);
    })
  };

});;'use strict';
angular.module('otn.directives.vidyo').controller('PreCallModalParticipantListController', function (
  $scope, EventsService, FavouritesServiceV3) {

  const vm = this;

  vm.removeSystemParticipant = (index) => {
    $scope.connectToForm.participants.systems.splice(index, 1);
  };

  const clearGuestPinIfNeeded = () => {
    const { participants } = $scope.connectToForm;
    if (participants.guests.length === 0 && participants.offnet.length === 0) {
      $scope.connectToForm.isGuestPinRequired = false;
      $scope.connectToForm.guestPin = '';
    }
    if (participants.offnet.length === 0) {
      $scope.connectToForm.isGuestPinToggleDisabled = false;
    }
  };

  vm.removeGuestParticipant = (index) => {
    $scope.connectToForm.participants.guests.splice(index, 1);
    clearGuestPinIfNeeded();
  };

  vm.removeOffnetParticipant = (index) => {
    $scope.connectToForm.participants.offnet.splice(index, 1);
    clearGuestPinIfNeeded();
  };

  vm.isDisabledOffnetSystemHost = (systemParticipant) => (
    systemParticipant.isConsultant === false || !systemParticipant.contactId
  );

  vm.toggleOtnSystemHost = (systemParam) => {
    const { systems, guests, offnet } = $scope.connectToForm.participants;
    systems.forEach((system) => {
      if ($scope.isSameSystemParticipant(systemParam, system) && !system.isHost) {
        system.isHost = true;
        $scope.updateEventContactFromSystem(system);
      } else {
        delete system.isHost;
      }
    });
    [...guests, ...offnet].forEach((system) => {
      delete system.isHost;
    });
  };

  vm.toggleNonOtnSystemHost = (email) => {
    const { systems, guests, offnet } = $scope.connectToForm.participants;
    [...guests, ...offnet].forEach((system) => {
      if (system.email === email && !system.isHost) {
        system.isHost = true;
        $scope.updateEventContactFromSystem(system);
      } else {
        delete system.isHost;
      }
    });
    systems.forEach((system) => {
      delete system.isHost;
    });
  };

  vm.isFavourite = (system) => (
    FavouritesServiceV3.isFavouriteSystem(system)
  );

  vm.toggleSystemFavourite = (system) => (
    FavouritesServiceV3.toggleSystemFavourite(system)
  );

  vm.getHostButtonTooltip = (system) => {
    const typeCode = EventsService.typeStringToCode($scope.connectToForm.type);
    const hostRole = EventsService.eventTypeToHostRole(typeCode);
    return system.isHost ? hostRole : `Mark as ${hostRole.toLowerCase()}`;
  };

  vm.getHostButtonTooltipOffnet = (system) => {
    if (vm.isDisabledOffnetSystemHost(system)) {
      return 'Consultants must be selected from list. Contact info@otn.ca to register your consultant.';
    }
    return vm.getHostButtonTooltip(system);
  };

  vm.refreshStatus = (system) => {
    $scope.fetchRemotePresence(system);
  };

});;'use strict';
angular.module('otn.directives.vidyo').controller('PreCallModalScheduleFormController', function ($scope, $log) {

  var vm = this;
  var todayNow = new Date();

  vm.dateFormat = 'yyyy-MM-dd';
  vm.isCalendarOpen = false;

  vm.datepickerOptions = {
    formatYear: 'yy',
    startingDay: 0,
    showWeeks: false
  };

  vm.timepickerOptions = {
    hStep: 1,
    mStep: 15,
    isMeridian: false
  };

  vm.form = {
    date: $scope.connectToForm.schedule.startTime || todayNow
  };

  // TODO: This is not used. Use it or delete it.
  // end time picker
  vm.minDate = todayNow;

  // TODO: This is not used. Use it or delete it.
  // Disable weekend selection
  vm.disabled = function (date, mode) {
    return (mode === 'day' && (date.getDay() === 0 || date.getDay() === 6));
  };

  $scope.openpicker = function ($event) {
    // needed to render calendar buttons properly
    $event.preventDefault();
    $event.stopPropagation();
    vm.isCalendarOpen = true;
  };

  /**
   * Creates a javascript Date object combining the given date, hours and minutes.
   *
   * @param date
   * @param h
   * @param m
   * @returns {Date}
   */
  const buildDateAndTime = (date, h, m) => {
    const newDate = new Date(date.getTime());
    newDate.setHours(h);
    newDate.setMinutes(m);
    newDate.setSeconds(0);
    return newDate;
  };

  vm.onDateChanged = () => {
    if (!vm.form.date) {
      return;
    }

    const scheduleForm = $scope.connectToForm.schedule;
    var startTime = scheduleForm.startTime;
    scheduleForm.startTime = buildDateAndTime(vm.form.date, startTime.getHours(), startTime.getMinutes());

    var endTime = scheduleForm.endTime;
    scheduleForm.endTime = buildDateAndTime(vm.form.date, endTime.getHours(), endTime.getMinutes());

    $log.debug('onDateChanged(): Time updated: ', {
      start: scheduleForm.startTime,
      end: scheduleForm.endTime
    });
  };
});;'use strict';
var vidyoModule = angular.module('otn.directives.vidyo');
vidyoModule.controller('SettingsPanelController', function ($scope, $log, $q, $timeout, $filter, CallService, VideoService, VideoConfigService, Users) {
  var vm = this;
  vm.controllerName = 'SettingsPanelController';
  vm.pcvcSettingsUrl = VideoConfigService.pcvcSettingsUrl;
  /*
   <<<<Public API
   */
  vm.setCurrentSpeaker = function (speaker) {
    vm.currentSpeaker = speaker;
    CallService.setSpeaker(speaker);
  };

  vm.setCurrentCamera = function (camera, showWarning) {
    vm.currentCamera = camera;
    CallService.setCamera(camera, showWarning);
  };

  vm.changeCamera = function () {
    CallService.changeCamera();
  };

  vm.setCurrentMic = function (mic, showWarning) {
    vm.currentMic = mic;
    CallService.setMic(mic, showWarning);
  };

  vm.changeMic = function () {
    CallService.changeMic();
  };

  vm.toggled = function (open) {
    $log.log('Dropdown is now: ', open);
  };

  vm.toggleDropdown = function ($event) {
    $event.preventDefault();
    $event.stopPropagation();
    vm.status.isopen = !vm.status.isopen;
  };
  /*
   Public API end >>>>
   */
  /*
   <<<<Private API
   */
  var init = function () {
    $log.debug('init ' + vm.controllerName);
    vm.speakers = [];
    vm.currentSpeaker = {};
    vm.cameras = [];
    vm.currentCamera = {};
    vm.mics = [];
    vm.currentMic = {};
    vm.status = {
      isopen: false
    };
    vm.isSpeakerSwitchInCallSupported = CallService.isSpeakerSwitchInCallSupported();
    vm.isDeviceSwitchInCallSupported = CallService.isDeviceSwitchInCallSupported();

    Users.bandwidthOptions(function (bandwidthOptions) {
      var bandwidthSettingInt = CallService.getBandwidthSetting();
      var bandwidthOption = $filter('getByProperty')('bandwidth', bandwidthSettingInt, bandwidthOptions);
      vm.bandwidthSetting = bandwidthOption ? bandwidthOption.name : '';
    });

    vm.isBrowserEdge = VideoService.isEdge();
    vm.isBrowserFirefox = VideoService.isFirefox();

    if (vm.isSpeakerSwitchInCallSupported) {
      populateSpeakers();
    }
    if (vm.isDeviceSwitchInCallSupported) {
      populateCameras();
      populateMics();
    }
  };

  function selectDevice(devices, id) {
    for (var i = 0; devices != null && i < devices.length; i++) {
      if (devices[i].id === id) {
        return devices[i];
      }
    }
    return null;
  }

  function populateSpeakers() {
    CallService.getSpeakers().then(function (speakers) {
      vm.speakers = speakers;
      CallService.getCurrentSpeakerId().then(function (currentSpeakerId) {
        vm.setCurrentSpeaker(selectDevice(speakers, currentSpeakerId));
      });
    });
  }

  function populateCameras() {
    CallService.getCameras().then(function (cameras) {
      vm.cameras = cameras;
      CallService.getCurrentCameraId().then(function (currentCameraId) {
        vm.setCurrentCamera(selectDevice(cameras, currentCameraId), false);
      });
    });
  }

  function populateMics() {
    CallService.getMics().then(function (mics) {
      vm.mics = mics;
      CallService.getCurrentMicId().then(function (currentMicId) {
        vm.setCurrentMic(selectDevice(mics, currentMicId), false);
      });
    });
  }
  /*
   Private API end>>>>>>
   */
  /*
   Any Listeners will go here
   */
  /*
   init
   */
  init();
});
;'use strict';
var vidyoModule = angular.module('otn.directives.vidyo');
vidyoModule.controller('VideoQualityWarnController', function($scope, $log, $q, $timeout, $filter, $interval, CallService, VideoOverlayService, VideoConfigService) {
  var vm = this;
  vm.controllerName = 'VideoQualityWarnController';
  vm.pcvcSettingsUrl = VideoConfigService.pcvcSettingsUrl;
  /*
   <<<<Public API
   */

  vm.close = function() {
    VideoOverlayService.closeVideoWarningMessage();
  };
  /*
   Public API end >>>>
   */
  /*
   <<<<Private API
   */
  var init = function() {
    $log.debug(vm.controllerName + ': init');
  };

  var destroy = function() {
    $log.debug(vm.controllerName + ': invoked destroy ');
  };

  /*
   Private API end>>>>>>
   */
  /*
   Listeners
   */
  /*
   init
   */
  init();

  $scope.$on('$destroy', function() {
    $log.debug(vm.controllerName + ': invoked $destroy');
    destroy();
  });
});
;/*jshint latedef: nofunc */
(function() {
  'use strict';

  angular
    .module('otn.directives.vidyo')
    .controller('GuestParticipantController', GuestParticipantController);

  GuestParticipantController.$inject = ['$rootScope', '$log', '$scope', '$modalInstance', 'guestSettings'];

  function GuestParticipantController($rootScope, $log, $scope, $modalInstance, guestSettings) {
    var sName = 'GuestParticipantController';
    $log.debug('%s: init, guestSettings: %s', sName, guestSettings);

    $scope.guestSettings = guestSettings;
    $scope.modalSettings = {
      isSaveDisabled: true,
      isConsentDisabled: $scope.guestSettings.isPHIConsent,
      emailTriggered: false
    };

    $scope.save = function() {
      $log.debug('%s: save', sName);
      $modalInstance.close({
        guestSettings: $scope.guestSettings,
        modalSettings: $scope.modalSettings  
      });
    };

    $scope.cancel = function() {
      $log.debug('%s: cancel', sName);
      $modalInstance.dismiss('cancel');
    };

    $scope.emailChanged = function() {
      $scope.modalSettings.isSaveDisabled = false;
      $scope.modalSettings.isConsentDisabled = false;
      $scope.modalSettings.emailTriggered = true;
    };

    $scope.nameChanged = function() {
      $scope.modalSettings.isSaveDisabled = false;
    };

    $rootScope.$on('ConsentConfirmed', function() {
      console.info('ConsentConfirmed');
      $scope.modalSettings.isSaveDisabled = false;
      $scope.modalSettings.emailTriggered = true;
    });
  }
})();;/*jshint latedef: nofunc */
(function() {
  'use strict';

  angular
    .module('otn.directives.vidyo')
    .controller('UserPcvcPrefSettingsController', UserPcvcPrefSettingsController);

  UserPcvcPrefSettingsController.$inject = ['$log', '$scope', '$modalInstance', 'pcvcSettings'];

  function UserPcvcPrefSettingsController($log, $scope, $modalInstance, pcvcSettings) {
    var sName = 'UserPcvcPrefSettingsController';
    $log.debug('%s: init, pcvcSettings: %s', sName, pcvcSettings);

    $scope.pcvcSettings = pcvcSettings;

    $scope.isAdminContactRequired = function () {
      return $scope.pcvcSettings.contactName || $scope.pcvcSettings.contactPhone || $scope.pcvcSettings.contactEmail;
    };

    $scope.save = function() {
      $log.debug('%s: save', sName);
      $modalInstance.close($scope.pcvcSettings);
    };

    $scope.cancel = function() {
      $log.debug('%s: cancel', sName);
      $modalInstance.dismiss('cancel');
    };
  }
})();;'use strict';
/* jshint unused: vars */

var app = angular.module('otn.directives.vidyo');
app.directive('adhocAction', function ($window, $location, $log, $filter, ActionUtils, User) {
  return {
    restrict: 'A',
    template: '<a id="adhocAction"  title="Videoconference"></a>',
    replace: true,
    scope: {
      adhocAction: '=adhocAction'
    },
    link: function ($scope, $element, $attrs) {
      var params;
      if ($scope.adhocAction.context === 'favouriteDetails') {
        $scope.classes = 'favourite-action-video';
        $scope.show = User.details.services.pcvc || User.details.services.pcvc3;

        if ($scope.adhocAction.favCategory === 'CONTACT') {
          params = {
            userDestId: $scope.adhocAction.vidyoEmail,
            userDestType: 'EMAIL',
            tsmStudioId: $scope.adhocAction.systemId,
            systemLabel: $scope.adhocAction.firstName + '_' + $scope.adhocAction.lastName,
            nickname: $scope.adhocAction.firstName + '_' + $scope.adhocAction.lastName,
            orgName: $scope.adhocAction.organization,
            siteName: $scope.adhocAction.site,
            city: $scope.adhocAction.city,
            lhin: $scope.adhocAction.lhin,
            contactId: $scope.adhocAction.systemId,
            favourite: ActionUtils.isFavourite($scope.adhocAction.systemId),
            redirectURI: $location.absUrl()
          };
        } else {
          params = {
            userDestId: $scope.adhocAction.dialingAlias,
            userDestType: 'DIALING_STRING',
            tsmStudioId: $scope.adhocAction.systemId,
            systemLabel: $scope.adhocAction.name,
            nickname: $scope.adhocAction.name,
            orgName: $scope.adhocAction.organization,
            siteName: $scope.adhocAction.site,
            city: $scope.adhocAction.city,
            lhin: $scope.adhocAction.lhin,
            favourite: ActionUtils.isFavourite($scope.adhocAction.systemId),
            redirectURI: $location.absUrl()
          };
        }
      } else if ($scope.adhocAction.context === 'details') {
        $scope.classes = 'quick-link-style quick-videoconference';
        $log.debug(ActionUtils.isMe($scope.adhocAction.profileId));
        $log.debug($scope.adhocAction.pcvcProvisioned);
        $log.debug($scope.adhocAction.vidyoEmail);
        $log.debug($scope.adhocAction.systemId);
        $scope.show = !ActionUtils.isMe($scope.adhocAction.profileId) && $scope.adhocAction.pcvcProvisioned && $scope.adhocAction.vidyoEmail && $scope.adhocAction.systemId;
        $log.debug($scope.show);
        params = {
          userDestId: $scope.adhocAction.vidyoEmail,
          userDestType: 'EMAIL',
          tsmStudioId: $scope.adhocAction.systemId,
          systemLabel: $scope.adhocAction.firstName + '_' + $scope.adhocAction.lastName,
          nickname: $scope.adhocAction.firstName + '_' + $scope.adhocAction.lastName,
          orgName: $scope.adhocAction.organization,
          siteName: $scope.adhocAction.site,
          city: $scope.adhocAction.city,
          lhin: $scope.adhocAction.lhin,
          favourite: ActionUtils.isFavourite($scope.adhocAction.systemId),
          redirectURI: $location.absUrl()
        };
        // hcpResults.html
      } else if ($scope.adhocAction.context === 'result') {
        $scope.classes = 'video-btn';
        $scope.show = !ActionUtils.isMe($scope.adhocAction.profileId) && $scope.adhocAction.pcvcProvisioned && $scope.adhocAction.vidyoEmail && $scope.adhocAction.systemId;
        $log.debug($scope.show);
        params = {
          userDestId: $scope.adhocAction.vidyoEmail,
          userDestType: 'EMAIL',
          tsmStudioId: $scope.adhocAction.systemId,
          systemLabel: $scope.adhocAction.firstName + '_' + $scope.adhocAction.lastName,
          nickname: $scope.adhocAction.firstName + '_' + $scope.adhocAction.lastName,
          orgName: $scope.adhocAction.organization,
          siteName: $scope.adhocAction.site,
          city: $scope.adhocAction.city,
          lhin: $scope.adhocAction.lhin,
          favourite: ActionUtils.isFavourite($scope.adhocAction.systemId),
          redirectURI: $location.absUrl()
        };
        // siteProfileDetails.html
      } else if ($scope.adhocAction.context === 'siteDetails') {
        $scope.classes = 'system-videoconference';
        $scope.show = User.details.services.pcvc || User.details.services.pcvc3;
        params = {
          userDestId: $scope.adhocAction.dialingAlias,
          userDestType: 'DIALING_STRING',
          tsmStudioId: $scope.adhocAction.systemId,
          systemLabel: $scope.adhocAction.name,
          nickname: $scope.adhocAction.name,
          orgName: $scope.adhocAction.organization,
          siteName: $scope.adhocAction.site,
          city: $scope.adhocAction.city,
          lhin: $scope.adhocAction.lhin,
          favourite: ActionUtils.isFavourite($scope.adhocAction.systemId),
          redirectURI: $location.absUrl()
        };
        // siteResults.html
      } else if ($scope.adhocAction.context === 'siteResult') {
        $scope.classes = 'videoconference-btn';
        $scope.show = User.details.services.pcvc || User.details.services.pcvc3;
        params = {
          userDestId: $scope.adhocAction.dialingAlias,
          userDestType: 'DIALING_STRING',
          tsmStudioId: $scope.adhocAction.systemId,
          systemLabel: $scope.adhocAction.name,
          nickname: $scope.adhocAction.name,
          orgName: $scope.adhocAction.organization,
          siteName: $scope.adhocAction.site,
          city: $scope.adhocAction.city,
          lhin: $scope.adhocAction.lhin,
          favourite: ActionUtils.isFavourite($scope.adhocAction.systemId),
          redirectURI: $location.absUrl()
        };
      } else {
        $scope.classes = '';
        throw new Error('adhocAction invalid context ' + $scope.adhocAction.context);
      }

      var paramsUrl = '';
      for (var key in params) {
        if (params.hasOwnProperty(key)) {
          if (paramsUrl !== '') {
            paramsUrl += '&';
          }
          paramsUrl += key + '=' + params[key];
        }
      }
      paramsUrl = $filter('urlEncode')(paramsUrl);

      // only fill in element if $scope.show is true
      if ($scope.show) {
        $scope.href = $scope.adhocAction.urlBase + '?' + paramsUrl;
        $log.debug('Ad-Hoc Call Action - href is: ' + $scope.href);
        $element.bind('click', function (e) {
          $window.location.href = $scope.href;
        });
        $attrs.$set('class', $scope.classes);
      }
    }
  };
});
;'use strict';
angular.module('otn.directives.vidyo').directive('clock', ['dateFilter', '$timeout', '$log', function (dateFilter, $timeout, $log) {
  return {
    restrict: 'E',
    scope: {
      format: '@'
    },
    link: function (scope, element, attrs) {
      var startTime = Date.now();
      var updateTime = function () {
        var elapsedTime = Date.now() - startTime;
        element.html(dateFilter(elapsedTime, scope.format, 'UTC'));
        $timeout(updateTime, 1000, false);
      };
      updateTime();
    }
  };
}]);
;'use strict';
angular.module('otn.directives.vidyo').directive('consentConfirmation', ['OtnConfirmationModalService', '$rootScope', function (OtnConfirmationModalService, $rootScope) {
  return {
    restrict: 'E',
    templateUrl: 'html/consentConfirm.html',
    scope: {
      model: '=',
      isDisabled: '='
    },
    link: function (scope, element, attrs) {
      var modalOptions = {
        closeButtonText: 'Cancel',
        actionButtonText: 'Confirm',
        actionHeaderText: 'Consent',
        questionText: 'By checking this box, you confirm that the patient has been informed, in accordance with guidance provided by Ontario Health, of the risks associated with communicating personal health information by email and has provided their express and knowledgeable consent to receive the OTNinvite email sent to them. You further confirm that you have documented this consent discussion in the patient’s medical record.',
        explanationText: ''
      };

      scope.onConfirmClicked = function() {
        if(scope.model) {
          OtnConfirmationModalService.showModal({}, modalOptions).then(function (result) {
            $rootScope.$broadcast('ConsentConfirmed');
          }, function() {
            scope.model = false;
          });
        }
      }      
    }
  };
}]);
;'use strict';
angular.module('otn.directives.vidyo').directive('datepickerPopup', function () {
  return {
    restrict: 'EAC',
    require: 'ngModel',
    link: function (scope, element, attr, controller) {
      //remove the default formatter from the input directive to prevent conflict
      controller.$formatters.shift();
    }
  };
});
;'use strict';
angular.module('otn.directives.vidyo').directive('otnFecc', function ($log, CallService) {
  return {
    restrict: 'E',
    templateUrl: 'html/feccPanel.html',
    scope: {
      participant: '='
    },
    controllerAs: 'feccVM',
    controller: function ($scope, $log) {
      var vm = this;
      vm.controllerName = 'FECCPanelController';
      vm.runFecc = function (action) {
        $log.debug('action: ' + action);
        CallService.fecc($scope.participant, action).then(
          function (controlCameraSuccess) {
            $log.debug('controlCameraSuccess: ' + controlCameraSuccess);
          },
          function (controlCameraFailed) {
            $log.debug('controlCameraFailed: ' + controlCameraFailed);
          }
        );
      };
    }
  };
});
;'use strict';

/*
systemName - Controls the type of button that will be shown. Expected options like 'default', 'event-list', 'event-copy', 'custom', etc.
label - Custom button label. Used when systemName is 'custom'
viewMode - One of 'ADHOC' or 'SCHEDULE' to control the default pre call modal mode
category - The event category/type such as "CLINIC" / "LEARNING" / "MEETING". To be used when copying events.
eventTitle - The user-entered event title. To be used when copying events.
startTime - To be used when copying events.
endTime - To be used when copying events.
numPatients - The total number of patients. To be used when copying events.
isPatientPresent - To be used when copying events.
pin - Guest pin. To be used when copying events.
hostPin - Host pin. To be used when copying events.
participants - To be used when copying events. Note: This is passed in by reference, not by value, to get the full object.
onOpen - Callback function to call when pre call modal is opened
 */
angular.module('otn.directives.vidyo').directive('preCall', function ($log, $rootScope, ModalManager, $location, User, Notifier, CallService, VideoService, UserPcvcPrefSettingsService) {
  return {
    restrict: 'A',
    templateUrl: 'html/pcvcModal.html',
    scope: {
      systemName: '@',
      followupRequestId: '@',
      systemDescription: '@',
      systemType: '@',
      organizationName: '@',
      systemId: '@',
      icon: '@',
      contactId: '@',
      label: '@',
      patientName: '@',
      patientEmail: '@',
      category: '@',
      eventTitle: '@',
      startTime: '@',
      endTime: '@',
      numPatients: '@',
      isPatientPresent: '@',
      pin: '@',
      hostPin: '@',
      participants: '=',
      eventContact: '=',
      isDisabled: '@',
      origin: '@',
      viewMode: '@',
      onOpen: '&',
      patients: '='
    },
    link: function ($scope, element, attrs) {
      $log.info('preCall directive initializing');
      if (angular.isUndefined($scope.icon)) {
        $log.debug('Using default icon "video-btn"');
        $scope.icon = 'video-btn';
      }

      $scope.displayCallIcon = false;
      if (angular.isDefined($scope.contactId) && angular.isDefined(User.details.contactid)) {
        if (parseInt($scope.contactId, 10) === User.details.contactid) {
          // don't display video conference button for your self
          $scope.displayCallIcon = false;
        } else {
          $scope.displayCallIcon = true;
        }
      } else if (angular.isUndefined($scope.systemId)) {
        $log.debug('Defaulting systemId to 0');
        $scope.systemId = 0;
      } else {
        $scope.displayCallIcon = true;
      }

      $scope.prefix = '';
      if ($scope.systemType === 'site') {
        $scope.prefix = 'at ';
      } else if ($scope.systemType === 'hcp') {
        $scope.prefix = 'from ';
      }

      // used to switch which icon vs button is displayed
      // event list uses a large button, everywhere else small icon
      $scope.buttonLocation = 'default';
      if ($scope.systemName === 'event-list') {
        $scope.buttonLocation = 'event-list';
      }
      if ($scope.systemName === 'event-copy') {
        $scope.buttonLocation = 'event-copy';
      }
      if (angular.isDefined($scope.favouriteMenu)) {
        $scope.displayCallIcon = true;
      }
      if ($scope.systemName === 'fav-list-icon') {
        $scope.buttonLocation = 'fav-list-icon';
        $scope.systemName = $scope.systemfavname;
        $scope.systemName = '';
      }
      if ($scope.systemName === 'fav-list') {
        $scope.icon = 'nav-connect-icon';
        $scope.buttonLocation = 'fav-list';
        //if(angular.isDefined($scope.systemfavname))
        // {
        //     $scope.systemName = $scope.systemfavname;
        //    $scope.buttonLocation = 'fav-list-icon';
        // }
      }
      if ($scope.systemName === 'mirror-list') {
        //  $scope.icon = 'nav-connect-icon';
        $scope.buttonLocation = 'mirror-list';
      }
      if ($scope.systemName === 'custom') {
        $scope.buttonLocation = 'custom';
      }

      /**
       * @param str String representation of a date. I.e. "2019-11-25T15:27:01.000+0000"
       * @returns {*} A Date object that represents the given string.
       */
      var stringToDate = function (str) {
        if (str === undefined || str === null) {
          return str;
        }
        // Note: Safari cannot parse the time zone "+0000" so we change it to "Z"
        return new Date(str.replace('+0000', 'Z'));
      };

      var stringToBoolean = function (str) {
        if (str === undefined || str === null) {
          return str;
        }
        return (str === 'true') ? true : false;
      };

      $scope.openCall = function ($event) {
        CallService.startMirrorTestCall($event);
      };

      $scope.validateEmail = function (email) {
        var reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
        if (reg.test(email)) {
          return true;
        } else {
          return false;
        }
      };

      $scope.lastErrorMessageId = '';

      $scope.open = function ($event) {
        $log.info('preCall open start');
        VideoService.isAudioEnabled();
        if ($scope.onOpen && typeof $scope.onOpen() === 'function') {
          try {
            $log.info('preCall: Calling onOpen function.');
            $scope.onOpen()();
          } catch (err) {
            $log.warn('preCall: The parameter passed into onOpen is not a function.');
          }
        }
        $event.stopPropagation();
        if (!User.details.primaryAgeGroup) {
          //DIR-1474: Changed redirectUri from hardcoded 'eventList' to the current path
          var redirectUri = $location.path();
          $log.info('preCall: Redirecting to pcvcSettingsPreferences redirectUri=' + redirectUri);
          $location.path('/pcvcSettingsPreferences').search({
            'redirectUri': redirectUri
          });
          $log.info('preCall open-1 end');
          return true;
        }
        if ($scope.origin === 'hubhome' && !$scope.validateEmail($scope.patientEmail)) {
          $log.info('preCall open-3 end');
          $scope.lastErrorMessageId = new Date().getTime();
          Notifier.createMessage('error', 'Please enter a valid email.');

          var unregister = $scope.$watch('patientEmail', function (newValue, oldValue) {
            if (newValue !== oldValue) {
              Notifier.removeMessage($scope.lastErrorMessageId);
              $scope.lastErrorMessageId = '';
              unregister();
            }
          });
        } else {
          ModalManager.open('preCall', {
            systemName: $scope.systemName,
            followupRequestId: $scope.followupRequestId,
            systemDescription: $scope.systemDescription,
            systemType: $scope.systemType,
            systemId: $scope.systemId,
            patientName: $scope.patientName,
            patientEmail: $scope.patientEmail,
            category: $scope.category,
            eventTitle: $scope.eventTitle,
            startTime: stringToDate($scope.startTime),
            endTime: stringToDate($scope.endTime),
            numPatients: $scope.numPatients,
            patients: $scope.patients,
            isPatientPresent: stringToBoolean($scope.isPatientPresent),
            pin: $scope.pin,
            hostPin: $scope.hostPin,
            participants: $scope.participants,
            eventContact: $scope.eventContact,
            organizationName: ($scope.organizationName) ? $scope.prefix + $scope.organizationName : '',
            contactId: $scope.contactId,
            viewMode: ($scope.viewMode === 'ADHOC' || $scope.viewMode === 'SCHEDULE') ? $scope.viewMode : null
          });
          $log.info('preCall open-2 end');
        }
      };
    }
  };
});
;'use strict';
/*
Add as an attribute to a scrollable panel, pass in a list of objects to watch.
If the list increases in length, the panel will be scrolled to the bottom.
*/
angular.module('otn.directives.vidyo').directive('scrollToBottom', function ($log) {
  return {
    scope: {
      scrollToBottom: '='
    },
    link: function (scope, element) {
      scope.$watchCollection('scrollToBottom', function (newValue, oldValue) {
        //Only scroll to bottom when new list is longer than old list
        if (newValue && oldValue && newValue.length > oldValue.length) {
          angular.element(element)[0].scrollTop = angular.element(element)[0].scrollHeight;
        }
      });
    }
  };
});
;'use strict';
angular.module('otn.directives.vidyo').directive('videoWindow', function($document, $window, $log, $timeout, FullScreen, VideoService) {
  return {
    restrict: 'A',
    replace: false,
    scope: {
      containerClass: '@'
    },
    template: '',
    link: function($scope, element, attrs) {
      $log.info('videoWindow directive initializing');
      if (typeof $scope.containerClass === 'undefined' || !$scope.containerClass) {
        $log.warn('"container-class" should be passed as an attribute to this directive. Defaulting to VideoService.config.domContainerClass (' + VideoService.config.domContainerClass + ')');
        $scope.containerClass = VideoService.config.domContainerClass;
      }

      var getActiveVideoElement = function() {
        var pluginContainers = document.getElementsByClassName($scope.containerClass);
        $log.info('THE CONTAINERS: ' + pluginContainers);
        if (pluginContainers.length === 1) {
          return angular.element(pluginContainers[0]);
        } else if (pluginContainers.length > 0) { // more than 1 found, find plugin
          for (var i = 0; i < pluginContainers.length; i++) {
            if (pluginContainers[i].children.length > 0) {
              return angular.element(pluginContainers[i]);
            }
          }
        }
        return null;
      };

      var getVideoOverlayContainer = function() {
        return angular.element(document.querySelector('#video-overlay-container'));
      };

      var getElementPosition = function(element) {
        var elementBoundingBox = element[0].getBoundingClientRect();
        var position = {};
        if (elementBoundingBox) {
          position.top = Math.round(elementBoundingBox.top);
          position.left = Math.round(elementBoundingBox.left);
          position.width = Math.round(elementBoundingBox.width);
          position.height = Math.round(elementBoundingBox.height);
        }
        return position;
      };

      var realignVideoOverlayView = function() {
        $log.info('videoWindow: realignVideoOverlayView start');
        var overlay = getVideoOverlayContainer();
        var videoContainerPosition = getElementPosition(element);
        overlay.css({
          'position': 'fixed',
          'z-index': 2500,
          'top': videoContainerPosition.top + 'px',
          'left': videoContainerPosition.left + 'px',
          'width': videoContainerPosition.width + 'px',
          'height': videoContainerPosition.height + 'px',
          'pointer-events': 'none'
          // 'border': 'solid red',
          // 'background-color': 'blue',
          // 'opacity': '0.5'
        });
      };

      // Check if video window is out of place, realign if necessary
      var realignVideoWindow = function() {
        realignVideoOverlayView();
        var videoElement = getActiveVideoElement();
        $log.info('realignVideoWindow... ' + new Date());
        // This is called recursively, until the video window is the same size and position as the container
        var checkAndPerformRealign = function() {
          realignVideoOverlayView();
          var videoContainerPosition = getElementPosition(element);
          var videoPosition = getElementPosition(videoElement);
          var now = new Date();
          var msg = 'checkAndPerformRealign... videoContainerPosition=' + JSON.stringify(videoContainerPosition) + ', videoPosition=' + JSON.stringify(videoPosition) + ' ' + now;
          $log.info(msg);
          if (videoPosition.top !== videoContainerPosition.top ||
            videoPosition.left !== videoContainerPosition.left ||
            videoPosition.width !== videoContainerPosition.width ||
            videoPosition.height !== videoContainerPosition.height) {
            $log.debug('Resizing video to match video container. videoContainerPosition=' + JSON.stringify(videoContainerPosition) + ', videoPosition=' + JSON.stringify(videoPosition));

            // FIXME: #selfview Hardcoded, @Yakov currently not implemented
            // FIXME: Re-Align caused to add the div again, Remove before appending
            /*
             videoElement.append('<div id="selfview"'+
             ' style="width:300px; height:200px; float:right; position: fixed; right: 10%; margin-top: -205px; border: red solid;">'+
             '     <video id="selfvideo" autoplay="autoplay" muted="true">'+
             '     </video>'+
             ' </div>');
             */

            videoElement.css({
              'position': 'fixed',
              'z-index': 2000,
              'top': videoContainerPosition.top + 'px',
              'left': videoContainerPosition.left + 'px',
              'width': videoContainerPosition.width + 'px',
              'height': videoContainerPosition.height + 'px',
              'border': '0px'
            });

            // Switching to full-screen may take time (esp. on Mac where it's animated). Need to re-check after some time.
            $log.debug('Scheduling another resize check in 2 seconds...');
            $timeout(checkAndPerformRealign, 2000, false);
          }
        };
        $timeout(checkAndPerformRealign, 100, false);
      };

      angular.element($window).on('resize', realignVideoWindow);

      if (VideoService.plugin) {
        realignVideoWindow();
      } else{
        $log.error('VideoService does not have an active plugin handle, cannot show video window');
      }

      $scope.$on('RealignVideoWindowEvent', function() {
        realignVideoWindow();
        // Bugfix: White screen, non-intrusive, non-visible resize
        var videoElement = getActiveVideoElement();
        var videoPosition = getElementPosition(videoElement);

        videoElement.css({
          /* jshint ignore:start */
          'width': (eval(videoPosition.width) - 1) + 'px'
          /* jshint ignore:end */
        });

        $timeout(function() {
          videoElement.css({
            'width': videoPosition.width + 'px'
          });
        }, 100, false);
      });
      $scope.$on('$destroy', function() {
        $log.info('$destroy videoWindow start');
        $log.debug('The "video window" directive recieved the "$destroy" event on its scope');

        // Remove event listener for window resizes
        angular.element($window).off('resize', realignVideoWindow);

        // Exit full screen mode
        FullScreen.exit();
        var cssReset = {
          'position': 'fixed',
          'top': VideoService.config.offScreenPosition.top,
          'left': VideoService.config.offScreenPosition.left,
          'width': VideoService.config.offScreenPosition.width,
          'height': VideoService.config.offScreenPosition.height
        };
        getActiveVideoElement().css(cssReset);
        var overlay = getVideoOverlayContainer();
        overlay.css(cssReset);
        $log.info('$destroy videoWindow end');
      });
    }
  };
});
;'use strict';
angular.module('otn.directives.vidyo').directive('vidyoPlugin',
  function($rootScope, $http, $filter, $log, $modal, $window, $timeout, $cookies,
           VideoService, ModalManager, User, Notifier, VidyoMessages, Events, Systems, AppConfig,
           videoHelper, CallService, otnHubEvents, PresenceService) {
    return {
      restrict: 'A',
      replace: false,
      scope: {
        'mimeType': '@',
        'logLevels': '@'
      },
      template: '',
      link: function($scope, element, attrs) {
        $log.info('vidyoPlugin directive initializing');
        $scope.inCall = false;
        var canDoNotifications = false;
        var wasCallActiveWithMinimumParticipantCount = false;
        var pexUtilParticipants = {};
        var pexSipAudioParticipants = {};

        var initIncomingCallModel = function() {
          $log.info('initIncomingCallModel');
          $scope.incomingCallModel = {
            eventId: null,
            caller: {
              type: '',
              mainName: '',
              subName: '',
              firstName: '',
              lastName: '',
              salutation: '',
              organization: '',
              systemName: '',
              siteName: '',
              roomName: ''
            }
          };
        };

        initIncomingCallModel();

        var logLevels;
        if (typeof $scope.logLevels === 'undefined' || !$scope.logLevels) {
          $log.warn('"log-levels" should be passed as an attribute to this directive. Defaulting to VideoService.config.logLevels.default ("' + VideoService.config.logLevels.default + '")');
          logLevels = VideoService.config.logLevels.default;
        }

        // Let's check if the browser supports notifications
        if (('Notification' in $window)) {
          canDoNotifications = true;
        }

        if (canDoNotifications) {
          // request permissions right away as opposed to when the window might be minimized...
          Notification.requestPermission(function(permission) {
            if (permission === 'granted') {
              canDoNotifications = true;
            } else{
              canDoNotifications = false;
            }
          });
        }

        if (!VideoService.pluginInitialized) {
          $log.debug('plugin initializing');
          VideoService.initializePlugin();
        }

        var pluginInitialized = function() {
          if (VideoService.config.containerIds.length === 0) {
            element.css({
              'position': 'absolute',
              'top': VideoService.config.offScreenPosition.top,
              'left': VideoService.config.offScreenPosition.left,
              'width': VideoService.config.offScreenPosition.width,
              'height': VideoService.config.offScreenPosition.height
            });
          }
          $timeout(function() {
            VideoService.loadPlugin();
          }, 300); // Timing issues using the external library in Angular code
        };

        var pluginReady = function() {
          $log.debug('Plugin reports as started');

          $scope.$on('otn-realtime-presence-change', function(angularEvent, videoEvent) {
            // No Action required here as this is handled by TopNavigation
          });

          //  "otn-realtime-receivingCall" Listener - Received when your endpoint is "ringing", includes the "invitingUser" (their username) and an "onCallFlag" boolean (true=initiated by another endpoint, false=initiated by VidyoPortal)
          $scope.$on('otn-realtime-receivingCall', function(angularEvent, videoEventKey, videoEvent) {
            $log.debug('otn-realtime-receivingCall', angularEvent, videoEventKey, JSON.stringify(videoEvent));
            $scope.incomingCall(videoEvent);
          });

          $scope.$on('otn-realtime-on-callCancelled', function(angularEvent, videoEvent) {
            $log.debug('call cancelled from caller end', angularEvent, videoEvent);
            ModalManager.close('incomingCall');
          });

          //  "otn-realtime-missedCall" Listener - Received after your endpoint has stopped "ringing"
          $scope.$on('otn-realtime-missedCall', function(angularEvent, videoEvent) {
            $log.debug('otn-realtime-missedCall', angularEvent, videoEvent);
            var call = $scope.incomingCallModel;
            $scope.DidNotAnswerIncomingCall(call);

            CallService.missCall(call).then(function(call) {
                ModalManager.close('incomingCall');
              })
              .catch(function(error) {
                // FIXME Handle
              });

            $log.debug('otn-realtime-missedCall: %O', videoEvent);
          });

          $scope.$on('otn-realtime-call-answered-from-another-device', function(angularEvent) {
            $log.debug('plugin: otn-realtime-call-answered-from-another-device', angularEvent);
            //TODO we might want to show some warning that call was answered from another device.
            ModalManager.close('incomingCall');
            $log.debug('plugin: otn-realtime-call-answered-from-another-device complete: %O', angularEvent);
          });

          //  "otn-realtime-call-completed" Listener - Received after the participants left the conference
          $scope.$on('otn-realtime-call-completed', function() {
            CallService.completeCall({}).then(function(call) {
                $scope.inCall = false;
                ModalManager.close('inCall');
              })
              .catch(function(error) {
                // FIXME Handle
              });

            $rootScope.$broadcast('CallEnded');
            $log.debug('otn-realtime-call-completed');
          });

          $scope.$on('otn-call-ParticipantCreated', function(angularEvent, call, participant) {
            $log.debug('plugin: otn-call-ParticipantCreated', angularEvent, call, participant);
            $log.debug('plugin: otn-call-ParticipantCreated', JSON.stringify(participant));
            var isUtilParticipant = participant.name && participant.name.indexOf('util_') === 0;
            var sipAudioTrigger = 'user=phone';
            var isPexAudioParticipant = (participant.name && participant.name.indexOf(sipAudioTrigger) > 0) || (participant.uri && participant.uri.indexOf(sipAudioTrigger) > 0);
            $log.debug('plugin: otn-call-ParticipantCreated: isUtilParticipant:' + isUtilParticipant + ',isPexAudioParticipant:' + isPexAudioParticipant + ' name:' + participant.name + ', uri:' + participant.uri);
            if (isUtilParticipant) {
              pexUtilParticipants[participant.id] = participant;
            }
            if (isPexAudioParticipant) {
              pexSipAudioParticipants[participant.id] = participant;
            }
          });

          $scope.$on('otn-call-ParticipantDeleted', function(angularEvent, call, participant) {
            $log.debug('plugin: otn-call-ParticipantDeleted', angularEvent, call, participant);
            var pexUtilParticipant = pexUtilParticipants[participant.id];
            var pexSipAudioParticipant = pexSipAudioParticipants[participant.id];
            $log.debug('plugin: otn-call-ParticipantDeleted pexUtilParticipant:', pexUtilParticipant);
            $log.debug('plugin: otn-call-ParticipantDeleted pexSipAudioParticipant:', pexSipAudioParticipant);
            CallService.refreshCallData(call).then(function(call) {
              if (CallService.isP2P(call) && !CallService.isP2PJoinOrCall(call) && !CallService.isGuestCall(call) && !pexUtilParticipant && !pexSipAudioParticipant) {
                CallService.endCallWithoutRefresh(call).then(function(call) {
                    $log.debug('plugin: otn-call-ParticipantDeleted CallEnded');
                    $scope.inCall = false;
                    pexUtilParticipants = {};
                    pexSipAudioParticipants = {};
                    ModalManager.close('inCall');
                  })
                  .catch(function(error) {
                    // FIXME Handle
                  });
              }
            });
          });

          $scope.incomingCall = function(videoEvent) {
            $log.debug('$scope.incomingCall, function called with parameter: ', videoEvent);

            if (!videoEvent || !videoEvent.vvr) {
              return;
            }

            initIncomingCallModel();
            wasCallActiveWithMinimumParticipantCount = false;

            var call = {
              autoInitiate: videoEvent.autoInitiate || false,
              stopOnReject: videoEvent.stopOnReject || false,
              eventId: videoEvent.eventId, // Currently it's not passed back        
              vvrId: videoEvent.vvr,
              deviceId: videoEvent.deviceId, // Currently it's not passed back
              callerGuid: videoEvent.remoteSession,
              vvrCallerId: videoEvent.vvrCallerId,
              vvrCalleeId: videoEvent.vvrCalleeId
            };

            if (call.callerGuid) { // PCVC User
              // Returns array for that reason can't use the default $resource
              $http({
                method: 'GET',
                url: AppConfig.services.users.base + '/?guid=' + call.callerGuid,
                headers: {
                  'Authorization': 'Bearer ' + User.token.accessToken
                }
              }).success(function(response) {
                $log.debug('Users get by Guid success: ', response);
                call.caller = response && response[0] ? response[0] : {};
                call.caller.type = 'pcvcOnly';
                call.caller.mainName = (call.caller.salutation ? call.caller.salutation + ' ' : '') + call.caller.firstName + ' ' + call.caller.lastName;
                call.caller.fullName = call.caller.mainName;
                call.caller.subName = call.caller.organization;

                $scope.incomingCallModel = call;
                $log.debug('incomingCallModel ', $scope.incomingCallModel);

                $scope.displayNotification('Incoming call from ' + $scope.incomingCallModel.caller.mainName);
                ModalManager.open('incomingCall', $scope.incomingCallModel);
              }).error(function(rejection) {
                $log.debug('Users get by Guid rejected: ', rejection);
              });
            } else{
              CallService.refreshCallData(call)
                .then(function(call) {
                  call.caller = {};
                  if (call.vvrCallerId) {
                    angular.forEach(call.vvrData.participants, function(participant, key) {
                      if (call.vvrCallerId === participant.id) {
                        call.caller.systemId = participant.participantName;
                        call.caller.systemName = participant.participantName;
                      }
                    });
                  } else{
                    call.caller.systemId = undefined;
                    call.caller.systemName = call.vvrData.incomingCallGeneratedTitle;
                  }

                  $scope.incomingCallMixed(call);
                })
                .catch(function(error) {
                  // FIXME Handle
                });
            }
          };

          $scope.incomingCallMixed = function(call) {
            $log.info('incomingCallMixed: ', call);

            initIncomingCallModel();

            var systemName = call.caller.systemName;
            var systemId = call.caller.systemId;

            // P2P
            if (systemId) {
              systemId = systemId.replace('@otn.ca', '');
              $log.debug('fetch details for systemId: ', systemId);

              // This is an H.323 to PCVC call (type = mixed)
              Systems.get({
                id: systemId
              }, function(response) {
                $log.debug('Systems.get response: ', response);

                call.caller.type = 'mixed';
                call.caller.mainName = response.description;
                call.caller.subName = 'from ' + ((response.organization) ? response.organization : null);

                $log.debug('Incoming Legacy to PCVC call. Caller: %O', response);
                if (response.site) {
                  if (response.site.organization) {
                    if (response.site.organization.name) {
                      // Org name is up via site due to the way they join in the DB
                      call.caller.subName = response.site.organization.name;
                    } else{
                      // Org name is up via site due to the way they join in the DB
                      call.caller.subName = response.site.organization;
                    }
                  } else{
                    call.caller.subName = response.site.description;
                  }
                }

                if (response.roomName) {
                  call.caller.subName = ' from ' + response.roomName + ' at ' + call.caller.subName;
                }

                if (typeof response.site.organization !== 'undefined' && response.site.organization !== null) {
                  if (typeof response.site.organization.name === 'undefined' || response.site.organization.name === null) {
                    call.caller.subName = response.site.description;
                  }
                }

                $scope.incomingCallModel = call;
                $log.debug('incomingCallModel ', $scope.incomingCallModel);

                $scope.displayNotification('Incoming call from ' + $scope.incomingCallModel.caller.mainName);
                ModalManager.open('incomingCall', $scope.incomingCallModel);
              }, function(response) {
                $log.debug('Incoming Legacy to PCVC call. Failed to retrieve caller details: ', response);
              });

            }
            // MP
            else{
              call.caller.type = 'mixed';
              call.caller.mainName = call.vvrData.incomingCallGeneratedTitle;
              call.caller.subName = ''; // No Organization leave it blank  

              $scope.incomingCallModel = call;
              $log.debug('incomingCallModel ', $scope.incomingCallModel);

              $scope.displayNotification('Incoming call from ' + $scope.incomingCallModel.caller.mainName);
              ModalManager.open('incomingCall', $scope.incomingCallModel);
            }
          };

          $scope.DidNotAnswerIncomingCall = function(args) {
            $log.info('DidNotAnswerIncomingCall start');
            $log.debug('DidNotAnswerIncomingCall args: ', args);
            // notification of missed call
            var date = new Date();
            var fullName = args.caller.fullName || '';
            var organizationName = args.caller.organization || '';
            var systemName = args.caller.systemName || '';
            var roomName = args.caller.roomName || '';
            var siteName = args.caller.siteName || '';

            var message = '';
            if (args.caller.type !== 'mixed') {
              if (!fullName && !organizationName) {
                message = VidyoMessages.call.unknownMissedCall.message;
              } else{
                message = VidyoMessages.call.msg05MissedCall.message.replace('<PCVC_USER_NAME>', fullName);
                message = message.replace('<ORGANIZATION_NAME>', organizationName);
              }
              message = message.replace('<DATE>', date.toDateString());
              message = message.replace('<TIME>', date.toTimeString());
            } else{

              if (!systemName && !roomName && !siteName) {
                message = VidyoMessages.call.unknownMissedCall.message;
              } else{
                message = VidyoMessages.call.msg06MissedCall.message.replace('<SYSTEM_NAME>', systemName);

                var room = $filter('ellipsis')(roomName, 15);
                var site = siteName + ' at ' + organizationName;

                if (room.trim().length > 0) {
                  room = room + ' at ';
                } else{
                  room = '';
                }
                message = message.replace('<ROOM_NAME>', room);
                message = message.replace('<SITE_NAME>', site);
              }
              message = message.replace('<DATE>', date.toDateString());
              message = message.replace('<TIME>', date.toTimeString());
            }
            Notifier.createMessage('warning', message, VidyoMessages.call.msg05MissedCall.solution);
            initIncomingCallModel();
            $log.info(message);
            $log.info('DidNotAnswerIncomingCall end');
          };
          $scope.displayNotification = function(message) {
            if (canDoNotifications) {
              var TheNotification = new Notification('Incoming call', {
                body: message
              });
              $timeout(function() {
                TheNotification.close();
              }, 15000);
            }
          };
        };

        $scope.$on('$destroy', function() {
          $log.info('$destroy vidyoPlugin start');
          //TODO: If we ever need to call some "destroy" logic on the OtnVideoComponent, it should be done here
          $log.info('$destroy vidyoPlugin end');
        });

        $scope.$on('videoReLogin', function() {
          $log.info('videoReLogin start');
          PresenceService.videoReLogin();
          $log.info('videoReLogin end');
        });

        $rootScope.$on('pluginInitialized', function() {
          $log.info('pluginInitialized vidyoPlugin start');
          pluginInitialized();
          $log.info('pluginInitialized vidyoPlugin end');
        });

        $rootScope.$on('pluginReady', function() {
          $log.info('pluginReady vidyoPlugin start');
          pluginReady();
          $log.info('pluginReady vidyoPlugin end');
        });

      }
    };
  });
;'use strict';
angular.module('otn.directives.vidyo').filter('generateOrganizationNameWithPrefix', function ($filter) {
  return function (item) {
    var prefix = '';
    var orgName = '';

    if (item.systemType === 'PC_VIDEO') {
      if (typeof item.organization !== 'undefined') {
        prefix = 'from ';
        orgName = item.organization;
      }

    } else if (item.systemType === 'HARDWARE_VIDEO') {
      if (typeof item.siteName !== 'undefined') {
        prefix = 'at ';
        orgName = item.siteName;
      }
    }

    var output = $filter('ellipsis')(prefix + orgName, 40);
    return output;
  };
});
;'use strict';
angular.module('otn.directives.vidyo').filter('generateTypeaheadDisplayText', function($filter) {
  return function(item, isFull) {
    var output = '';
    var renderHardwareSystem = function(item, isFull) {
      if (isFull) {
        var res = item.systemName;
        if (typeof item.roomName && item.roomName) {
          res += ', ' + $filter('ellipsis')(item.roomName, 15);
        }
        if (typeof item.siteName && item.siteName) {
          res += ', ' + item.siteName;
        }
      } else{
        res = item.systemName;
      }
      return res;
    };

    var isPCVCHostSite = item.systemName && item.systemName.indexOf('_HOST_') !== -1;
    if (item.systemType === 'PC_VIDEO' && !isPCVCHostSite) {
      output = $filter('fullName')(item);
      if (typeof item.organization !== 'undefined' && item.organization) {
        if (!isFull) {
          var salut = '';
          if (item.salutation !== undefined) {
            salut = item.salutation.substring(0, 3);
          }
          output = salut + ' ' + item.firstName + ' ' + item.lastName;
        } else{
          output += ', ' + item.organization;
        }
      }
    }
    if (item.systemType === 'PC_VIDEO' && isPCVCHostSite) {
      output = renderHardwareSystem(item, isFull);
    }
    else if (item.systemType === 'HARDWARE_VIDEO') {
      output = renderHardwareSystem(item, isFull);
    }

    output = $filter('ellipsis')(output, 140);
    return output;
  };
});
;'use strict';
angular.module('otn.directives.vidyo').filter('truncate', function ($filter) {
  return function (string, maxChars) {
    var output = $filter('limitTo')(string, maxChars);
    if (string.length > maxChars) {
      output += '...';
    }
    return output;
  };
});
;'use strict';
var app = angular.module('otn.directives.vidyo');
app.constant('VidyoMessages', {
  authentication: {
    failedFirebaseToken: {
      message: 'Cannot retrieve the videoconferencing status.',
      solution: 'Please refresh the page and try again.'
    }
  },
  call: {
    mandatoryField: {
      message: 'All mandatory fields must be completed'
    },
    cantStartConference: {
      message: 'System is not able to start the videoconference.',
      solution: 'Please try again'
    },
    cantReach: {
      message: 'The user or system you are trying to call is currently unavailable.',
      solution: 'Please try again later.'
    },
    cantReachHost: {
      message: 'The consultant/host system is currently unavailable.',
      solution: 'Please try again later.'
    },
    cantJoin: {
      message: 'Your call could not be connected. Ensure the event you are attempting to connect to is currently in progress. Otherwise contact OTN Technical support for assistance at <CUSTOMER_SUPPORT_HELP_LINE>.',
      solution: ''
    },
    eventCancelled: {
      message: 'The event you are trying to join has been cancelled.',
      solution: ''
    },
    msg03CantReach: {
      message: 'System is not able to connect to the videoconference.',
      solution: 'Please try again'
    },
    msg05MissedCall: {
      message: 'You received a videoconference call from <PCVC_USER_NAME> from <ORGANIZATION_NAME> on <DATE> at <TIME>.',
      solution: 'Ok'
    },
    msg06MissedCall: {
      message: 'You received a videoconference call from <SYSTEM_NAME> from <ROOM_NAME><SITE_NAME> on <DATE> at <TIME>.',
      solution: 'Ok'
    },
    msg07MissedCall: {
      message: 'You received a videoconference call from a Codian Bridge on <DATE> at <TIME>.',
      solution: 'Ok'
    },
    unknownMissedCall: {
      message: 'You received a videoconference call from an unknown caller on <DATE> at <TIME>.',
      solution: 'Ok'
    },
    participantOffline: {
      message: 'The participant system is not online.',
      solution: 'Please try again'
    },
    localParticipantOffline: {
      message: 'It appears you have been signed out and are currently offline.',
      solution: 'Please refresh the page and try again.'
    },
    invalidPatientNumber: {
      message: 'Invalid patient number.',
      solution: 'Please enter a number between 1 and 999.'
    },
    videoServicesNotAvailable: {
      message: 'You have been logged out of video services.',
      solution: ''
    },
    cantLockRoom: {
      message: 'System is not able to lock the videoconference.',
      solution: ''
    },
    contentShareLimited: {
      message: 'Your browser supports viewing shared content as a still image or presentation.  To view full motion content, use Chrome or Firefox.',
      solution: ''
    },
    alreadyConnected: {
      message: 'You are already connected in a video call. To place a new call, disconnect from your active call first.',
      solution: ''
    },
    notOnline: {
      message: 'Your video system needs to be online to place a video call. You can try the following: 1) wait a moment and try again, 2) click here to ',
      solution: ''
    },
    videoDisablePrivacy: {
      message: 'The last frame of your camera view is still being displayed to other participants due to limitations with your current browser. If you haven’t done so, please turn the camera back on, physically cover your camera, then turn it off. We recommend using Chrome or Firefox to display a black screen by default when turning off your camera.',
      solution: ''
    },
    cantCallP2PJoinOrCall: {
      message: 'Unable to join or call the participant system, Event has ended at <TIME>.',
      solution: 'Please refresh your page and try again.'
    },
    cantJoinEnded: {
      message: 'Unable to join the Event, Event has ended at <TIME>.',
      solution: ''
    },
    startSuccess: {
      message: 'The event has been started.',
      solution: ''
    },
    cantJoinNotParticipant: {
      message: 'The event is in progress. Cannot join because your system is not a participant.',
      solution: ''
    }
  },
  otnInvite: {
    msg01: {
      message: 'All mandatory fields must be completed'
    },
    msg02: {
      message: 'Enter a valid email address.'
    },
    hostPinLength: {
      message: 'Host PIN must be 6 digits'
    },
    guestPinLength: {
      message: 'Guest PIN must be 6 digits'
    },
    pinsMatch: {
      message: 'Guest and host PIN cannot be the same'
    },
    msg04: {
      message: 'You already have an event scheduled at this time.',
      solution: 'Pleae try again.'
    },
    msg05: {
      message: 'Unable to connect to videoconference.',
      solution: 'Please try again.'
    },
    msg06: {
      message: 'Invite has been sent.'
    },
    msg07: {
      message: 'Unable to send email invite.',
      solution: 'Please try again.'
    },
    msg08: {
      message: 'The videoconference room is no longer active.'
    },
    msg09: {
      message: 'Select a valid delegator under On Behalf Of.'
    },
    msg19: {
      message: 'Unable to save the changes.'
    },
    msg23: {
      message: 'The date must be the current date or a future date with the format yyyy-MM-dd.'
    },
    msg24: {
      message: 'The start time must be in the future.'
    },
    msg25: {
      message: 'The end time must be later than the start time.'
    },
    msg41: {
      message: 'Unable to cancel the event.',
      solution: 'Please try again.'
    },
    msg42: {
      message: 'The event exceeds the maximum number of guest participants.'
    },
    participantLimitReached: {
      message: 'You cannot add any more participating systems to this event.'
    },
    msg43: {
      message: 'The date must be the current date or a future date with the format yyyy-MM-dd.'
    },
    duplicateEmails: {
      message: 'Email already used. Please enter a different email.'
    },
    guestNameTooShort: {
      message: 'Invalid entry. You must enter at least 2 characters for a Guest Name.'
    },
    cantGetAdminContact: {
      message: 'Error fetching administrative contact data.',
      solution: 'Please try again.'
    },
    noSystem: {
      message: 'Select person or room system.'
    },
    duplicateSystems: {
      message: 'This person or room system has already been added.'
    },
    notEnoughParticipants: {
      message: 'Please add at least two participating systems'
    },
    noConsultantSystem: {
      message: 'Please mark one participant as consultant system'
    },
    noHostSystem: {
      message: 'Please mark one participant as host system'
    },
    noConsultant: {
      message: 'Please select a consultant'
    },
    noPresenter: {
      message: 'Please select a presenter'
    },
    noOwnOrDelegatorSystem: {
      message: 'You must be associated with at least one system in the event in order to schedule. To manage your scheduling permissions, contact your Primary Contact.'
    }
  },
  plugin: {
    installation: {
      required: {
        message: 'OTNhub requires a compatible VidyoWeb plugin to enable videoconference services.',
        solution: 'Please: 1) <a href="@@PLUGIN_DOWNLOAD_URL@@">Download</a> the latest version of the VidyoWeb plugin and run the file to install. 2) <a onclick="window.location.reload()">Refresh</a> your page after the installation is complete. More information is available <a href="@@PRIVACY_CASL@@" target="_blank">here</a>.'
      },
      firefoxRequired: {
        message: 'OTNhub requires a compatible VidyoWeb plugin to enable videoconference services.',
        solution: 'Please: 1) <a href="@@PLUGIN_DOWNLOAD_URL@@">Download</a> the latest version of the VidyoWeb plugin and run the file to install. 2) Restart your browser after the installation is complete. More information is available <a href="@@PRIVACY_CASL@@" target="_blank">here</a>.'
      },
      chromeNoPluginNoExtension: {
        message: 'OTNhub requires the latest Chrome extension and a compatible Chrome VidyoWeb plugin to enable videoconference services.',
        solution: 'Please: 1) <a href="@@CHROME_EXT_INSTALL_URL@@" target="_blank">Install</a> the VidyoWebConnector Chrome extension. 2) <a href="@@CHROME_PLUGIN_DOWNLOAD_URL@@">Download</a> the Chrome VidyoWeb plugin and run the file to install. 3) <a onclick="window.location.reload()">Refresh</a> your page after both installations are complete. More information is available <a href="@@PRIVACY_CASL@@" target="_blank">here</a>.'
      },
      chromeNoPlugin: {
        message: 'OTNhub requires a compatible Chrome VidyoWeb plugin to enable videoconference services.',
        solution: 'Please: 1) <a href="@@CHROME_PLUGIN_DOWNLOAD_URL@@">Download</a> the latest version of the Chrome VidyoWeb plugin and run the file to install. 2) <a onclick="window.location.reload()">Refresh</a> your page after the installation is complete. More information is available <a href="@@PRIVACY_CASL@@" target="_blank">here</a>.'
      },
      chromeNoExtension: {
        message: 'OTNhub requires a Chrome extension to enable videoconference services.',
        solution: 'Please: 1) <a href="@@CHROME_EXT_INSTALL_URL@@" target="_blank">Install</a> the VidyoWebConnector Chrome extension. 2) <a onclick="window.location.reload()">Refresh</a> your page after installation is complete. More information about Chrome Support is available <a href="@@PRIVACY_CASL@@" target="_blank">here</a>.'
      },
      unsupportedPlatform: {
        message: 'Only Windows, and Mac OSX are supported'
      },
      unsupportedBrowser: {
        message: 'OTNhub Videoconference requires a compatible VidyoWeb plugin to enable video functionalities.',
        solution: 'Your browser is not supported by VidyoWeb. <b>Please use Internet Explorer or Safari 11 or prior*.</b> More information is available <a href="@@PRIVACY_CASL@@">here</a>.',
        solutionMac: 'Your browser is not supported by VidyoWeb. <b>Please use Firefox 52.9 ESR.</b> More information is available <a href="@@PCVC_MAC_PDF@@">here</a>.'
      },
      success: {
        message: 'Installation of the plug-in was successful'
      }
    },
    initialization: {
      failed: {
        message: 'Cannot use video functionality.',
        solution: 'Please reload the page and try again.'
      },
      pexrtcNotLoadingRetryLoop: {
        message: 'Cannot use video functionality.',
        solution: 'Please wait while we try to resolve the issue.'
      },
      pexrtcNotLoading: {
        message: 'Cannot restore video functionality. We’ve logged this issue.',
        solution: 'Please contact <CUSTOMER_SUPPORT_HELP_LINE> to resolve.'
      },
      pexrtcNotLoadingRetrySuccess: {
        message: 'Video functionality has been restored.',
        solution: ''
      },
      noCamerasFound: {
        message: 'Cannot use video functionality. No cameras found.',
        solution: 'Please attach a camera to your computer.'
      },
      noCamerasOrMicsFound: {
        message: 'Cannot use video functionality. No cameras/microphones found.',
        solution: 'Please attach a camera and microphone to your computer and grant permissions to use them.'
      },
      noFlashFound: {
        message: 'Your browser requires Adobe Flash 11 or newer to use videoconferencing.',
        solution: 'Please download and install <a href="@@ADOBE_FLASH_INSTALL_URL@@" target="_blank">Adobe Flash</a> before continuing, or use Chrome or Firefox.'
      },
      noMicsFound: {
        message: 'Cannot use video functionality. No Microphones found.',
        solution: 'Please attach a microphone to your computer.'
      },
      noCameraPermissions: {
        message: 'VidyoWeb needs access to your camera in order to join a conference.',
        solution: 'Please click the camera icon in your url bar and allow the browser to use your camera.'
      },
      cameraNotStarted: {
        message: 'VidyoWeb could not start your camera.',
        solution: 'Please reload the page and try again.'
      }
    }
  },
  browserSupport: {
    msIENoSupport: {
      message: 'Internet Explorer is no longer supported for videoconferencing. To continue making or receiving video calls, please switch to the <a href="@@CHROME_INSTALL_URL@@" target="_blank">Chrome</a> browser.'
    },
    msEdgeAndIELimitedSupport: {
      message: 'Your browser doesn’t offer full support for some features of videoconferencing during an event: 1) you will not be able to share your screen, 2) you will not be able to change your devices (e.g. microphone, camera), and 3) your last frame will be displayed on screen to other participants if you turn off your camera. We recommend using <a href="@@CHROME_INSTALL_URL@@" target="_blank">Chrome</a> or <a href="@@FIREFOX_INSTALL_URL@@" target="_blank">Firefox</a> to enable full videoconference capabilities.'
    },
    iosNoVideoSupport: {
      message: 'Your current device does not support videoconferencing using a web browser. Please <a href="@@IOS_INSTALL_URL@@" target="_blank">download the OTNconnect app</a> for iOS to make and receive videoconferencing calls.'
    },
    androidNoVideoSupport: {
      message: 'Your current device does not support videoconferencing. Please access the OTNhub on a personal computer or iOS device.'
    }
  }

});
;'use strict';
angular.module('otn.directives.vidyo')
  .factory('CallService',
    function($q, $log, $timeout, $filter, $rootScope, $injector,
             AppConfig, AuditService, User, Users, VideoService, PresenceService, Events, VirtualVisits,
             otnVideoComp, otnHubEvents, Notifier, ModalManager, RetryService, $interval, VideoOverlayService,
             VidyoMessages, PcvcServiceSettings, ngAudio) {

      var currentCall = {};
      var getMediaStatisticsSetIntervalHandler;
      var currentCallMediaStats = {};
      var MEDIA_WARN_THRESHOLD = parseFloat(3);
      var MEDIA_STATS_THRESHOLDS = [
        {'code': 'excellent', 'per': parseFloat(0)},
        {'code': 'good', 'per': parseFloat(1)},
        {'code': 'poor', 'per': parseFloat(3)},
        {'code': 'terrible', 'per': parseFloat(10)}
      ];
      var currentCallQualityLevel = MEDIA_STATS_THRESHOLDS[0].code;
      var ringing;

      var findCallQuality = function(quality) {
        var qualityStr = String(quality);
        var res = MEDIA_STATS_THRESHOLDS[0];
        var qualFl = parseFloat(qualityStr.replace('%', ''));
        angular.forEach(MEDIA_STATS_THRESHOLDS, function(value, key) {
          if (qualFl > value.per) {
            res = value;
          }
        });
        return res;
      };

      var findCallQualityIndex = function(code) {
        var i;
        for (i = 0; i < MEDIA_STATS_THRESHOLDS.length; i++) {
          if (MEDIA_STATS_THRESHOLDS[i].code === code) {
            break;
          }
        }
        return i;
      };

      function findMinCallQuality(arrayOfStreamQ) {
        //max index - worst quality
        var maxIndex = 0;
        var resCode = MEDIA_STATS_THRESHOLDS[0].code;
        var i;
        for (i = 0; i < arrayOfStreamQ.length; i++) {
          var code = arrayOfStreamQ[i];
          var sIndex = findCallQualityIndex(code);
          if (sIndex > maxIndex) {
            maxIndex = sIndex;
            resCode = code;
          }
        }
        return resCode;
      }

      // findCallQualityGreaterThan
      function findCallQualityGreaterThan(arrayOfStreamQ, threshhold) {
        var res = [];
        var i;
        for (i = 0; i < arrayOfStreamQ.length; i++) {
          var qualityStr = String(arrayOfStreamQ[i]);
          var qualFl = parseFloat(qualityStr.replace('%', ''));
          if (qualFl > threshhold) {
           res.push(qualFl);
          }
        }
        return res;
      }

      /**
       * Returns true if the given call is a "guest call", defined as having one or more guest participants.
       *
       * @param call
       * @returns {boolean|*} true if the given call is a guest call, false otherwise
       */
      function isGuestCall(call) {
        var EventsService = $injector.get('EventsService'); // Circular dependency
        return EventsService.isGuestLink(call.eventData);
      }

      function isP2P(call) {
        return call.vvrData && call.vvrData.participants.length === 2;
      }

      function isP2PJoin(event) {
        return event &&
          (event.isBridgeNeeded ||
          event.isOffNet ||
          (typeof event.audioAccessNumber !== 'undefined' && event.audioAccessNumber));
      }

      function isP2PJoinOrCall(call) {
        return isP2P(call) && isP2PJoin(call.eventData);
      }

      function isVvrInProgress(call) {
        return (call.vvrData && call.vvrData.state === 'InProgress');
      }

      /**
       * Extracts event participants from a call object, in two cases:
       * 1) Event has not been created yet. Participants are in createEventRequest.
       * 2) Event has been created. Participants are in eventData.
       *
       * @param call
       * @returns {*[]|*}
       */
      function getEventParticipants(call) {
        if (call.eventData && call.eventData.participants) {
          return call.eventData.participants;
        } else if (call.createEventRequest && call.createEventRequest.participants) {
          return call.createEventRequest.participants;
        }
        return [];
      }

      function isMySystemInCall(call) {
        const eventParticipants = getEventParticipants(call);
        const mySystemArray = eventParticipants.filter((participant) => participant.isMySystem);
        return mySystemArray.length > 0;
      }

      /**
       * Checks caller presence, so long as my user is in the call. Reason: if delegator is starting a call,
       * he/she may not be in it and their presence doesn't matter.
       *
       * @param call
       * @returns {Promise<unknown>}
       */
      function fetchCallerPresence(call) {
        $log.debug('CallService: fetchCallerPresence', call);
        return new Promise((resolve, reject) => {
          call.isMySystemInCall = isMySystemInCall(call);

          if (call.isMySystemInCall) {
            const callerPresence = PresenceService.getMyPresence();
            if (callerPresence === 'online') {
              if (!call.caller) {
                call.caller = {};
              }
              call.caller.presence = callerPresence;
              resolve(call);
            } else if (callerPresence === 'busy') {
              reject({ error: 'E_CALL_CALLER_BUSY' });
            } else {
              reject({ error: 'E_CALL_CALLER_OFFLINE' });
            }
          } else {
            // My user is not in the call, my presence doesn't matter
            resolve(call);
          }
        });
      }

      /**
       * Gets participants from call.createEventRequest and checks presence of PCVC/Legacy callees if:
       * - P2P call or
       * - Callee is the host
       *
       * @param call
       * @returns {Promise<unknown>}
       */
      function fetchCalleePresence(call) {
        $log.debug('CallService: fetchCalleePresence, function called with parameter: ', call);
        return new Promise((resolve, reject) => {
          const eventParticipants = getEventParticipants(call);
          const callees = eventParticipants.filter((participant) => !participant.isMySystem);
          const otnCallees = callees.filter((callee) => (callee.type === 'PCVC' || callee.type === 'LEGACY'));

          let calleeToCheckPresenceFor;
          let isCheckingHostPresence;
          if (callees.length === 1 && otnCallees.length === 1) {
            // P2P call with OTN system. Check presence of that system.
            calleeToCheckPresenceFor = otnCallees[0];
            isCheckingHostPresence = false;
          } else if (callees.length > 1) {
            // MP call. Check presence of host system.
            calleeToCheckPresenceFor = otnCallees.filter((callee) => callee.isHost)[0];
            isCheckingHostPresence = true;
          }

          if (calleeToCheckPresenceFor) {
            PresenceService.fetchRemotePresence(calleeToCheckPresenceFor)
              .then((response) => {
                if (response.presence === 'online') {
                  call.callee = response;
                  resolve(call);
                } else if (response.presence === 'busy') {
                  reject({
                    error: (isCheckingHostPresence) ? 'E_CALL_HOST_BUSY' : 'E_CALL_CALLEE_BUSY'
                  });
                } else {
                  reject({
                    error: (isCheckingHostPresence) ? 'E_CALL_HOST_OFFLINE' : 'E_CALL_CALLEE_OFFLINE'
                  });
                }
              })
              .catch((rejection) => reject(rejection));
          } else {
            resolve(call);
          }
        });
      }

      /**
       * Creates an Event for the given call if one doesn't exist, and adds its eventId to the call.
       *
       * @param call
       * @returns {*}
       */
      function createEvent(call) {
        $log.debug('CallService: createEvent, function called with parameter: ', call);
        var deferred = $q.defer();

        if (call.eventId === undefined) {
          Events.create(call.createEventRequest, function(response) {
            $log.info('** Event.create successful - ID: ', response.eventId);
            call.eventId = response.eventId;
            call.createEventResponse = response;
            deferred.resolve(call);
          }, function(rejection) {
            $log.debug('Event.create rejected: ', rejection);
            deferred.reject(call);
          });
        } else{
          deferred.resolve(call);
        }

        return deferred.promise;
      }

      function getEvent(call) {
        $log.debug('CallService: getEvent, function called with parameter: ', call);
        var deferred = $q.defer();

        if (call.eventId !== undefined) {
          Events.get({
            id: call.eventId
          }, function(eventData) {
            $log.debug('received eventData ' + JSON.stringify(eventData));
            call.eventData = eventData;
            deferred.resolve(call);
          }, function(error) {
            $log.debug('received error ' + error);
            deferred.reject(error);
          });
        } else{
          deferred.resolve(call);
        }

        return deferred.promise;
      }

      function validateEvent(call) {
        var deferred = $q.defer();
        if (call.eventData && call.eventData.status === 'CANCELLED') {
          deferred.reject({error: 'E_CALL_EVENT_CANCELLED'});
        } else {
          deferred.resolve(call);
        }
        return deferred.promise;
      }

      function getVvr(call) {
        $log.debug('CallService: getVvr, function called with parameter: ', call);
        var deferred = $q.defer();

        VirtualVisits.get({
          id: call.vvrId
        }, function(response) {
          $log.debug('VirtualVisits.get resolved: ', response);
          call.vvrData = response;

          if (response.tsmId !== undefined) {
            call.eventId = response.tsmId;
          }
          setCallerFlag(call);

          deferred.resolve(call);
        }, function(rejection) {
          $log.debug('VirtualVisits.get rejected: ', rejection);
          deferred.reject(rejection);
        });

        return deferred.promise;
      }

      function lockVvr(call) {
        $log.debug('CallService: lockVvr, function called with parameter: ', call);
        var deferred = $q.defer();

        VirtualVisits.lock({
          id: call.vvrId
        }, function(response) {
          $log.debug('VirtualVisits.lock resolved: ', response);

          deferred.resolve(call);
        }, function(rejection) {
          $log.debug('VirtualVisits.lock rejected: ', rejection);
          deferred.reject(rejection);
        });

        return deferred.promise;
      }

      function unlockVvr(call) {
        $log.debug('CallService: unlockVvr, function called with parameter: ', call);
        var deferred = $q.defer();

        VirtualVisits.unlock({
          id: call.vvrId
        }, function(response) {
          $log.debug('VirtualVisits.unlock resolved: ', response);

          deferred.resolve(call);
        }, function(rejection) {
          $log.debug('VirtualVisits.unlock rejected: ', rejection);
          deferred.reject(rejection);
        });

        return deferred.promise;
      }

      function findVvr(call) {
        $log.debug('CallService: findVvr, function called with parameter: ', call);
        var deferred = $q.defer();

        VirtualVisits.findVvr({
          eventId: call.eventId
        }, (response) => {
          $log.debug('VirtualVisits.find guids resolved: ', response);
          const vvrData = response[0];
          call.vvrId = vvrData.id;
          call.vvrData = vvrData;
          setCallerFlag(call);

          deferred.resolve(call);
        }, (rejection) => {
          $log.debug('VirtualVisits.find guids rejected: ', rejection);
          deferred.reject(rejection);
        });

        return deferred.promise;
      }

      function getBandwidthSettings(call) {
        var deferred = $q.defer();

        PcvcServiceSettings.list({}, function (pcvcSettings) {
          var bandwidth = $filter('getByProperty')('name', 'BANDWIDTH', pcvcSettings);
          call.bandwidth = (bandwidth && bandwidth.value && !isNaN(bandwidth.value)) ? parseInt(bandwidth.value) : null;
          deferred.resolve(call);
        });

        return deferred.promise;
      }

      /**
       * Returns true if the Middle Tier should notify participants. Otherwise, the
       * Front End will do it.
       *
       * Front End will notify if: P2P, callee is PCVC system, and caller current user (not delegate).
       * In all other cases, the MT can notify.
       *
       * @param call
       * @returns {boolean}
       */
      const shouldMTNotifyParticipants = (call) => {
        return !!(!call.isMySystemInCall || isMultipoint(call) || getPcvcCallees(call).length !== 1);
      };

      function startVvrVideoModality(call) {
        $log.debug('CallService: startVvrVideoModality, function called with parameter: ', call);
        var deferred = $q.defer();

        call.vvrData.state = 'InProgress';
        call.vvrData.notifyParticipants = shouldMTNotifyParticipants(call);

        VirtualVisits.startModality({
            id: call.vvrData.id
          },
          JSON.stringify(call.vvrData),
          function(response) {
            $log.info('** VirtualVisits.startModality successful: ', response);
            call.vvrData = {
              ...call.vvrData,
              ...response
            };
            setCallerFlag(call);

            deferred.resolve(call);
          },
          function(rejection) {
            $log.debug('VirtualVisits.startModality rejected: ', rejection);
            deferred.reject(rejection);
          });

        return deferred.promise;
      }

      /**
       * retrieves encoded userInfo required by iOS
       */
      function getUserInfo(call) {
        $log.debug('CallService: getUserInfo ', User.details.guid);
        var deferred = $q.defer();
        // If vvrData.notifyParticipants is true, the MT will notify participants
        if (call.vvrData.notifyParticipants === true) {
          deferred.resolve(call);
          return deferred.promise;
        }
        Users.getUsersInfo({
          guid: User.details.guid,
          contactids: null,
          isNameAndOrg: true,
          isEncode:true
        }, (response) => {
          $log.debug('getUserInfo returned: ', response);
          call.caller.userInfoEncoded = response[0];
          deferred.resolve(call);
        }, (rejection) => {
          $log.error('getUserInfo rejected - rejection:' + rejection);
          Notifier.createMessage('error', 'An error has occurred, please try again.', '', JSON.stringify(rejection));
          deferred.reject(rejection);
        });
        return deferred.promise;
      }
      /**
       * @param vvrData - equivalent of call.vvrData or event.vvr.vvrData
       * @param tsmSystemId - the studio we want to redial to
       * @returns {*}
       */
      function redialParticipant(vvrData, tsmSystemId) {
        const deferred = $q.defer();
        const vvrDataFiltered = {
          ...vvrData,
          participants: vvrData.participants.filter((participant) => participant.tsmSystemId === tsmSystemId)
        };

        VirtualVisits.redial({
          id: vvrDataFiltered.id
        },
        vvrDataFiltered,
        (response) => {
          $log.info('VirtualVisits.redial successful: ', response);
          deferred.resolve(response);
        },
        (rejection) => {
          $log.debug('VirtualVisits.redial rejected: ', rejection);
          deferred.reject(rejection);
        });

        return deferred.promise;
      }

      function startMediaStatsPoll(call) {
        $log.debug('CallService: startMediaStatsPoll ' + new Date());
        var mediaState = {
          isWarningShownAlready: false
        };
        if (call.isMySystemInCall === false) {
          return Promise.resolve(call);
        }

        var deferred = $q.defer();
        var isMediaStatisticsSupported = otnVideoComp.isMediaStatisticsSupported();
        call.isMediaStatisticsSupported = isMediaStatisticsSupported;
        if (!isMediaStatisticsSupported) {
          deferred.resolve(call);
          $log.info('CallService: startMediaStatsPoll is not supported for this browser' + new Date());
          return deferred.promise;
        }
        getMediaStatisticsSetIntervalHandler = $interval(function() {
          var s = otnVideoComp.getMediaStatistics();
          if (!angular.isDefined(s.outgoing) || !angular.isDefined(s.outgoing.audio)) {
            //race condition: check in case call was stopped but remain polls tries to retrieve stats
            return;
          }
          currentCallMediaStats = s;
          //audio
          var sao = s.outgoing.audio;
          var sai = s.incoming.audio;
          //video
          var svo = s.outgoing.video;
          var svi = s.incoming.video;
          var data = svo['configured-bitrate'] + '\t' + svo['packets-sent'] + '\t' + svi['packets-received'] + '\t' + svo['packets-lost'] + '\t' + svi['packets-lost'] + '\t' + svo['percentage-lost'] + '\t' + svi['percentage-lost'] + '\t' + svo['bitrate'] + '\t' + svi['bitrate'] + '\t' + svo['codec'] + '\t' + svi['codec'] + '\t' + svo['resolution'] + '\t' + svi['resolution'];
          data = data + '\t\t\t';
          data = data + sao['packets-sent'] + '\t' + sai['packets-received'] + '\t' + sao['packets-lost'] + '\t' + sai['packets-lost'] + '\t' + sao['bitrate'] + '\t' + sai['bitrate'] + '\t' + sao['codec'] + '\t' + sai['codec'];
          // $log.debug('CallService: MediaStatsPoll' + new Date() + ' <<>> ' + JSON.stringify(s));
          try {
            checkCallQualityChange(s, mediaState);
          }
          catch(error) {
            $log.error('CallService: checkCallQualityChange, failed ' + new Date(), error);
          }
        }, 1000);
        deferred.resolve(call);
        return deferred.promise;
      }

      function testCallQuality() {
        var data = {
          outgoing: {
            audio: {'percentage-lost-recent': 0},
            video: {'percentage-lost-recent': '1.5%'}
          },
          incoming: {
            audio: {'percentage-lost-recent': '2%'},
            video: {'percentage-lost-recent': '3.5%'}
          }
        };

        checkCallQualityChange(data);
      }

      function checkCallQualityChange(data, mediaState) {
        // $log.debug('CallService: checkCallQualityChange ' + new Date() + ' data: ' + JSON.stringify(data));
        var attrToTest = 'percentage-lost-recent';
        var streamsQ = [3];
        streamsQ[0] = findCallQuality(data.outgoing.audio[attrToTest]).code;
        streamsQ[1] = findCallQuality(data.outgoing.video[attrToTest]).code;
        streamsQ[2] = findCallQuality(data.incoming.audio[attrToTest]).code;
        streamsQ[3] = findCallQuality(data.incoming.video[attrToTest]).code;
        var minQ = findMinCallQuality(streamsQ);
        if (minQ !== currentCallQualityLevel) {
          $rootScope.$broadcast('otn-call-quality-changed', minQ);
          currentCallQualityLevel = minQ;
        }
        var streamsRecentPackLost = [3];
        streamsRecentPackLost[0] = data.outgoing.audio[attrToTest];
        streamsRecentPackLost[1] = data.outgoing.video[attrToTest];
        streamsRecentPackLost[2] = data.incoming.audio[attrToTest];
        streamsRecentPackLost[3] = data.incoming.video[attrToTest];
        var belowThreshold = findCallQualityGreaterThan(streamsRecentPackLost, MEDIA_WARN_THRESHOLD);
        if (belowThreshold.length > 0 && !mediaState.isWarningShownAlready) {
          mediaState.isWarningShownAlready = true;
          VideoOverlayService.showVideoWarningMessage();
          AuditService.sendCallQualitySnapshot(currentCall.eventId, data);
        }
      }

      function stopMediaStatsPoll(call) {
        $log.debug('CallService: stopMediaStatsPoll ' + new Date());
        var deferred = $q.defer();
        if (angular.isDefined(getMediaStatisticsSetIntervalHandler)) {
          $interval.cancel(getMediaStatisticsSetIntervalHandler);
          getMediaStatisticsSetIntervalHandler = undefined;
          currentCallMediaStats = {};
        }
        deferred.resolve(call);
        return deferred.promise;
      }

      const stopVvrVideoByVvrData = (vvrData) => {
        const stopParams = {
          state: 'Active',
          participants: []
        };

        for (var i = 0; i < vvrData.participants.length; i++) {
          var participant = {
            id: vvrData.participants[i].id
          };
          stopParams.participants.push(participant);
        }

        $log.info('VirtualVisits.stopModality called ', vvrData);
        return new Promise((resolve, reject) => {
          VirtualVisits.stopModality({
            id: vvrData.id
          },
          JSON.stringify(stopParams),
          (response) => {
            $log.info('VirtualVisits.stopModality successful: ', response);
            resolve(response);
          },
          (rejection) => {
            $log.debug('VirtualVisits.stopModality rejected: ', rejection);
            reject(rejection);
          });
        });
      };

      function stopVvrVideoModality(call) {
        $log.debug('CallService: stopVvrVideoModality, function called with parameter: ', call);
        var deferred = $q.defer();
        if (isMirrorTest(call) || isP2PJoinOrCall(call)) {
          $log.info('** VirtualVisits.stopModality isMirrorTest or isP2PJoinOrCall: ', call);
          deferred.resolve(call);
          return deferred.promise;
        }
        if (isMultipoint(call)) {
          $log.info('** VirtualVisits.stopModality isMultipoint: ', call);
          deferred.resolve(call);
        } else if (call.vvrData) {
          stopVvrVideoByVvrData(call.vvrData)
            .then((response) => {
              call.vvrData = response;
              setCallerFlag(call);
              deferred.resolve(call)
            })
            .catch((err) => deferred.reject(err));
        }
        return deferred.promise;
      }

      function patchUnMuteCamera() {
        $timeout(function () {
          $log.debug('CallService: patchUnMuteCamera ' + new Date());
          try {
            otnVideoComp.muteCamera(false);
          }
          catch (error) {
            $log.error('CallService: patchUnMuteCamera failed', error);
          }
          $log.debug('CallService: patchUnMuteCamera complete' + new Date());
        }, 500);
      }
      function startVideoCompCall(call) {
        $log.debug('CallService: startVideoCompCall, function called with parameter: ', call);
        var deferred = $q.defer();

        var apiUrl = AppConfig.services.virtualvisit.base;
        var videoContainerId = VideoService.config.videoContainerId;
        // Change the caller logic
        var participantCaller = getCaller(call);
        var sessionId = participantCaller.videoSession;
        $log.debug('CallService: startVideoCompCall, sessionId: ', sessionId);
        var userDisplayName = participantCaller.participantName;

        otnVideoComp.setApiRelativePath(false);
        otnVideoComp.startCall(sessionId, videoContainerId, userDisplayName, apiUrl, function(response) {
            $log.debug('VirtualVisits.startVideoCompCall sucess: ', response);
            patchUnMuteCamera();
            AuditService.saveCallJoin(call.eventId);
            deferred.resolve(call);
          }, function(rejection) {
            $log.debug('VirtualVisits.startVideoCompCall rejected: ', rejection);
            deferred.reject(rejection);
          }, function(response) {
            $log.info('** VirtualVisits.startVideoCompCall disconnected: ', response);
            // TODO: Maybe display a notification message using the response="Conference terminated by an administrator"
            endCall(call)
              .then(function(call) {
                $log.info('** VirtualVisits.startVideoCompCall endCall: ', call);
                ModalManager.close('inCall');
              })
              .catch(function(error) {
                // FIXME Notify with message
                $log.error('** VirtualVisits.startVideoCompCall endCall: ', error);
              });
          }, call.devices,
          function(participant) {
            $log.debug('CallService.startVideoCompCall otn-call-ParticipantCreated: ' + JSON.stringify(participant));
            $rootScope.$broadcast('otn-call-ParticipantCreated', call, participant);
          },
          function(participant) {
            $log.debug('CallService.startVideoCompCall otn-call-ParticipantUpdated: ' + JSON.stringify(participant));
            $rootScope.$broadcast('otn-call-ParticipantUpdated', call, participant);
          },
          function(participant) {
            $log.debug('CallService.startVideoCompCall otn-call-ParticipantDeleted: ' + JSON.stringify(participant));
            $rootScope.$broadcast('otn-call-ParticipantDeleted', call, participant);
          },
          function(conferenceUpdated) {
            $log.debug('CallService.startVideoCompCall otn-call-ConferenceUpdated: ' + JSON.stringify(conferenceUpdated));
            $rootScope.$broadcast('otn-call-ConferenceUpdated', call, conferenceUpdated);
          },
          null,
          call.bandwidth
        );

        return deferred.promise;
      }

      function startVideoCompCallWithRetry(call) {
        $log.debug('CallService: startVideoCompCallWithRetry, function called with parameter: ', call);
        if (call.isMySystemInCall === false) {
          return Promise.resolve(call);
        }

        const action = () => (startVideoCompCall(call));
        const condition = (rejection) => (rejection.code === 'E_EXPIRED_SESSION');
        const refresh = () => (refreshSession(call));

        return RetryService.retryWhen(action, condition, refresh);
      }

      function leaveVideoCompCall(call) {
        $log.debug('CallService: leaveVideoCompCall, function called with parameter: ', call);
        var deferred = $q.defer();

        otnVideoComp.leaveConference();
        $log.debug('CallService: leaveVideoCompCall sucess.');
        if (call.eventId) {
          AuditService.saveCallLeave(call.eventId);
        }
        deferred.resolve(call);

        return deferred.promise;
      }

      /**
       * Helper function to notify a PCVC callee of an incoming call.
       *
       * @param call
       * @param participantCallee
       * @returns {*}
       */
      function notifyPcvcCallee(call, participantCallee) {
        var deferred = $q.defer();
        var remoteSession = participantCallee.userGuid;
        var callData = {
          vvr: call.vvrData.id,
          callerInfo: call.caller.userInfoEncoded
        };
        otnHubEvents.call(remoteSession, callData)
          .then(function(response) {
            deferred.resolve(response);
          })
          .catch(function(rejection) {
            $log.debug('OtnHubEvents.call rejected: ', rejection);
            var errorCd = '';

            // OtnHubEvents.call rejected:  not_answered
            if (rejection === 'not_answered' || rejection === 'cancelled') {
              errorCd = 'E_CALL_NOT_ANSWERED';
            } else if (rejection === 'rejected') {
              errorCd = 'E_CALL_REJECTED';
            } else if (rejection === 'not_online') {
              errorCd = 'E_CALL_CALLEE_NOT_ONLINE';
            } else{
              errorCd = 'E_UNKOWN';
              $log.error('OtnHubEvents.call E_UNKOWN: ', rejection);
            }
            deferred.reject({
              'error': errorCd
            });
          });
        return deferred.promise;
      }

      /**
       * Triggers an "incoming call" notification for the callee (if the MT won't do it).
       *
       * @param call
       * @returns {*} Promise
       */
      function startNotifyCallee(call) {
        $log.debug('CallService: startNotifyCallee: ', call);
        return new Promise((resolve, reject) => {
          // If vvrData.notifyParticipants is true, the MT will notify participants
          if (call.vvrData.notifyParticipants === false) {
            const callee = getPcvcCallees(call)[0];
            notifyPcvcCallee(call, callee)
              .then(() => {
                $log.debug('CallService: startNotifyCallee: success');
                resolve(call);
              })
              .catch((rejection) => {
                $log.debug('CallService: startNotifyCallee: failure', rejection);
                reject(rejection);
              });
          } else {
            resolve(call);
          }
        });
      }

      function endNotifyRemote(call) {
        $log.debug('CallService: endNotifyRemote, function called with parameter: ', call);

        var deferred = $q.defer();
        if (isMirrorTest(call)) {
          deferred.resolve(call);
          return deferred.promise;
        }
        if (call.eventData && call.eventData.isWebcasting) {
          $log.debug('CallService: Webcast event, managed by MT autoinitiate');
          deferred.resolve(call);
          return deferred.promise;
        }
        var remoteGuid;
        var isMP = isMultipoint(call);

        if (!isMP) {
          var participantCallee = getCallee(call);
          //CS1-1285: participantCallee was undefined
          remoteGuid = participantCallee ? participantCallee.userGuid : null; // undefined for Roombased systems
        }

        otnHubEvents.endCall({
          vvrId: call.vvrData.id,
          isMP: isMP,
          remoteGuid: remoteGuid
        });

        $log.info('** otnHubEvents.endCall successful.');
        deferred.resolve(call);

        return deferred.promise;
      }

      function startOutgoingRinging() {
        if (VideoService.isAudioEnabled() && !ringing) {
          //TODO: This is dirty, but relative paths don't seem to work here.
          ringing = ngAudio.play('bower_components/otn.directives.vidyo/dist/assets/outgoing.mp3');
          ringing.loop = 2;
        } else {
          $log.warn('Audio is disabled! Cannot play ringing sound.');
        }
      }

      function stopRinging() {
        if (VideoService.isAudioEnabled() && ringing) {
          ringing.stop();
          ringing = null;
        }
      }

      function validateCall(call) {
        var deferred = $q.defer();

        if (!call) {
          deferred.reject({
            'error': 'E_CALL_UNDEFINED'
          });
        } else {
          deferred.resolve(call);
        }

        return deferred.promise;
      }

      /**
       * Call this to indicate that a call is about to start. It starts the outbound ringing and sets your presence
       * to busy.
       *
       * @param call
       */
      function indicateStartingCall(call) {
        PresenceService.setMyPresence('busy');
        startOutgoingRinging();
        return Promise.resolve(call);
      }

      /**
       * Indicates that call is starting as well as showing the "Connecting..." spinner modal.
       *
       * @param call
       * @returns {*}
       */
      function indicateJoiningCall(call) {
        if (call.isMySystemInCall === false) {
          return Promise.resolve(call);
        }
        ModalManager.open('joinCall');
        return indicateStartingCall(call);
      }

      /**
       * Create an event for a new call, start and join the call. This is called for AdHoc only.
       *
       * @param call
       * @returns {*} Promise
       */
      function createCall(call) {
        $log.debug('CallService: createCall(): ', call);
        var deferred = $q.defer();

        validateCall(call)
          .then(fetchCallerPresence)
          .then(fetchCalleePresence)
          .then(indicateStartingCall)
          .then(createEvent) // From this point on, call.eventId will be set
          .then(getEvent)
          .then(validateEvent)
          .then(getBandwidthSettings)
          .then(findVvr) // From this point on, call.vvrId and call.vvrData will be set
          .then(startVvrVideoModality)
          .then(getUserInfo)
          .then(startNotifyCallee)
          .then(startVideoCompCallWithRetry)
          .then(startMediaStatsPoll)
          .then(function (call) {
            $log.debug('CallService: createCall(): Call started');
            handleCreateCallSuccess(call);
            deferred.resolve(call);
          }).catch(function (rejection) {
            handleCreateCallFailure(call, rejection).then(function (call) {
              $log.debug('CallService: createCall(): Call failed. Event destroyed. ', rejection);
              deferred.reject(rejection);
            }).catch(function (endCallRejection) {
              $log.error('CallService: createCall(): Failed to destroy a rejected call event. ', endCallRejection);
              deferred.reject(rejection);
            });
          });

        return deferred.promise;
      }

      function startMirrorTestCall($event) {
        if (PresenceService.getMyPresence() !== 'online') {
          Notifier.createMessage('errorWithVideoReLogin', 'Your video system needs to be online to perform the mirror test. You can try the following: 1) wait a moment and try again, 2) click here to ');
          $log.info('end openCall ERROR. Your video system is not ready to run the mirror test.');
          return false;
        }
        $event.stopPropagation();

        // OTN Video Component functionality
        var apiUrl = AppConfig.services.virtualvisit.base;
        var videoContainerId = VideoService.config.videoContainerId;
        var onDisconnect = function(reson) {
          $log.debug('Call Service:Mirror Test onDisconnect');
          PresenceService.setMyPresence('online');
          ModalManager.close('inCall');
        };
        var displayName = User.details.username;
        otnVideoComp.setConfig({
          disableLogs: false,
          muteCamera: false
        });
        otnVideoComp.setApiRelativePath(false);
        otnVideoComp.startMirrorTest({
          videoContainerId: videoContainerId,
          apiUrl: apiUrl,
          userDisplayName: displayName,
          onDisconnect: onDisconnect
        }, function() {
          var call = {};
          startMediaStatsPoll(call);
          currentCall.context = 'MIRROR_TEST';
          ModalManager.close('preCall');
          ModalManager.open('inCall', {
            context: 'MIRROR_TEST',
            call: call
          });
        }, function(error) {
          $log.debug('Call Service:Mirror Test Failed.', err);
          Notifier.createMessage('error', 'Error', 'The mirror test is currently unavailable.', null);
        });
      }

      function findVideoSession(call) {
        $log.debug('CallService: findVideoSession, function called with parameter: ', call);
        return new Promise((resolve, reject) => {
          const participantCaller = getCaller(call);
          const sessionId = participantCaller.videoSession;

          if (sessionId === null || sessionId === undefined) {
            refreshSession(call)
              .then((call) => resolve(call))
              .catch((error) => reject(error));
          } else {
            resolve(call);
          }
        });
      }

      /**
       * Common initialization logic for startCall and joinCall
       *
       * @param call
       * @returns {Promise<unknown>}
       */
      function initStartOrJoinCall(call) {
        return validateCall(call)
          .then(getEvent)
          .then(validateEvent)
          .then(findVvr)
          .then(getBandwidthSettings)
          .then(fetchCallerPresence);
      }

      /**
       * Start a call that already has an existing event but is not started.
       *
       * If current user is a delegator who is not a participant in this event they will not be joined.
       *
       * @param call
       * @returns promise
       */
      function startCall(call) {
        $log.debug('CallService: start: ', call);
        return new Promise((resolve, reject) => {
          initStartOrJoinCall(call)
            .then(fetchCalleePresence)
            .then(indicateJoiningCall)
            .then(startVvrVideoModality)
            .then(getUserInfo)
            .then(startNotifyCallee)
            .then(startVideoCompCallWithRetry)
            .then(startMediaStatsPoll)
            .then((call) => {
              $log.info('CallService: start success');
              handleStartOrJoinSuccess(call, 'CALL');
              resolve(call);
            })
            .catch((rejection) => {
              $log.debug('CallService: start failed: ', rejection);
              handleStartOrJoinFailure(call, rejection);
              reject(rejection);
            });
        });
      }

      /**
       * Join a call that already has an existing event and is started
       * - If MP call, attempt to join call
       * - If P2P call, attempt to start (if not started) then join and notify remote participant
       * - If Guest call, attempt to start (if not started) then join
       *
       * TODO: CS1-2673: Make sure this logic continues to address the following cases:
       * -> CS1-2005 Users reporting when attempting to join a p2p call on OTNHub they are receiving the error "Error
       * system is not able to start the video Conference."
       * -> CS1-1441 No indication call is connecting from calendar
       * -> CS1-641: Implement initiate p2p call from event list
       * -> CS1-505: Implement decline workflow when user receives a call from bridge
       *
       * @param call
       * @returns promise
       */
      function joinCall(call) {
        $log.debug('CallService: join: ', call);
        return new Promise((resolve, reject) => {
          initStartOrJoinCall(call)
            .then(indicateJoiningCall)
            .then(findVideoSession)
            .then(startVideoCompCallWithRetry)
            .then(startMediaStatsPoll)
            .then((call) => {
              $log.info('CallService: join success');
              handleStartOrJoinSuccess(call, 'CALL_JOIN');
              resolve(call);
            })
            .catch((rejection) => {
              $log.debug('CallService: join failed: ', rejection);
              handleStartOrJoinFailure(call, rejection);
              reject(rejection);
            });
        });
      }

      function acceptCall(call) {
        $log.debug('CallService: acceptCall, function called with parameter: ', call);

        var deferred = $q.defer();

        if (!call) {
          return deferred.reject(call);
        }
        otnHubEvents.answerCall(call.vvrId);

        getVvr(call)
          .then(getBandwidthSettings)
          .then(startVideoCompCallWithRetry)
          .then(startMediaStatsPoll)
          .then(function(call) {

            $log.info('Call is accepted now!');
            currentCall = call;
            deferred.resolve(call);
          })
          .catch(function(error) {
            otnVideoComp.leaveConference();
            deferred.reject({
              'error': error
            });
          });

        return deferred.promise;
      }

      function rejectCall(call) {
        $log.debug('CallService: rejectCall, function called with parameter: ', call);
        var deferred = $q.defer();
        currentCall = call;

        otnHubEvents.rejectCall(call.vvrId);
        if (call.stopOnReject) {
          getVvr(call)
            .then(stopVvrVideoModality)
            .then(function(call) {
              deferred.resolve(call);
            })
            .catch(function(error) {
              deferred.reject(error);
            });
        } else{
          deferred.resolve(call);
        }

        return deferred.promise;
      }

      function missCall(call) {
        $log.debug('CallService: missCall, function called with parameter: ', call);
        var deferred = $q.defer();
        currentCall = call;

        if (call.stopOnReject) {
          getVvr(call)
            .then(stopVvrVideoModality)
            .then(function(call) {
              deferred.resolve(call);
            })
            .catch(function(error) {
              deferred.reject(error);
            });
        } else{
          deferred.resolve(call);
        }

        return deferred.promise;
      }

      function stopCurrentCall() {
        $log.debug('CallService: stopCurrentCall, function called with currentCall: ', currentCall);
        var deferred = $q.defer();
        var call = currentCall;

        if (call.stopOnReject) {
          refreshCallData(call)
            .then(stopVvrVideoModality)
            .then(function(call) {
              deferred.resolve(call);
            })
            .catch(function(error) {
              deferred.reject(error);
            });
        } else{
          deferred.resolve(call);
        }

        return deferred.promise;
      }

      function endCall(call) {
        $log.debug('CallService: endCall, function called with parameter: ', call);
        var deferred = $q.defer();

        refreshCallData(call)
          .then(stopMediaStatsPoll)
          .then(leaveVideoCompCall)
          // .then(stopVvrVideoModality) // ---> kickout all participants, onDisconnect is called
          .then(endNotifyRemote) // ---> otn-realtime-call-completed
          .then(function(call) {
            $log.info('Call is now ended!');
            $rootScope.$broadcast('CallEnded', call);
            currentCall = call;
            deferred.resolve(call);
          })
          .catch(function(error) {
            deferred.reject({
              'error': error
            });
          });

        return deferred.promise;
      }

      function refreshCallData(call) {
        $log.debug('CallService: refreshCallData, function called with parameter: ', call);
        var deferred = $q.defer();

        if (isMirrorTest(call)) {
          deferred.resolve(call);
          return deferred.promise;
        }

        getVvr(call)
          .then(getEvent)
          .then(function(call) {
            $log.info('refreshCallData is now refreshed!');
            currentCall = call;
            deferred.resolve(call);
          })
          .catch(function(error) {
            deferred.reject({
              'error': error
            });
          });

        return deferred.promise;
      }

      function endCallWithoutRefresh(call) {
        $log.debug('CallService: endCallWithoutRefresh, function called with parameter: ', call);
        var deferred = $q.defer();

        stopMediaStatsPoll(call)
          .then(leaveVideoCompCall)
          .then(stopVvrVideoModality) // ---> kickout all participants, onDisconnect is called
          .then(endNotifyRemote) // ---> otn-realtime-call-completed
          .then(function(call) {
            $log.info('Call is now ended via endCallWithoutRefresh!');
            $rootScope.$broadcast('CallEnded');
            currentCall = call;
            deferred.resolve(call);
          })
          .catch(function(error) {
            deferred.reject({
              'error': error
            });
          });

        return deferred.promise;
      }

      function endCallOnUnload() {
        // On Page refresh unload is called this method has angular limitations
        // such as browser alert doesn't work, developer console does not stop
        // in debug mode, can't call angular asynchornous calls etc.
        // Implemented a synchronous solution without using third party library.
        $log.debug('CallService: endCallOnUnload, currentCall: ', JSON.stringify(currentCall));

        if (currentCall.context === 'MIRROR_TEST') {
          $log.debug('CallService: endCallOnUnload, call is a Mirror test. exiting...');
          return;
        }
        var xmlhttp = new XMLHttpRequest();
        var url = AppConfig.services.virtualvisit.base + '/' + currentCall.vvrData.id;

        xmlhttp.onreadystatechange = function() {
          if (this.readyState === 4 && this.status === 200) {
            var vvr = angular.fromJson(this.response);
            $log.debug('Latest VVR object: ', vvr);

            if (vvr && vvr.participants.length > 2) {
              $log.debug('CallService: endCallOnUnload, call is MP does not perform stop modality. exiting...');
              otnVideoComp.leaveConference();
              return;
            }
            var xmlhttpStop = new XMLHttpRequest();
            var urlStop = AppConfig.services.virtualvisit.base + '/' + vvr.id;
            xmlhttpStop.open('PUT', urlStop, false); // false is for making the call synchronous
            xmlhttpStop.setRequestHeader('Content-type', 'application/json');
            xmlhttpStop.setRequestHeader('Authorization', 'Bearer ' + User.token.accessToken);
            xmlhttpStop.setRequestHeader('X-Authorization-Type', 'hub');
            xmlhttpStop.send(JSON.stringify({
              state: 'Active',
              participants: []
            }));
          }
        };

        xmlhttp.open('GET', url, false); // false is for making the call synchronous
        xmlhttp.setRequestHeader('Content-type', 'application/json');
        xmlhttp.setRequestHeader('Authorization', 'Bearer ' + User.token.accessToken);
        xmlhttp.setRequestHeader('X-Authorization-Type', 'hub');
        xmlhttp.send();
      }

      function handleCreateCallSuccess(call) {
        $log.info('Call has started successfuly');
        Events.auditLogging({id: call.eventId, actionType:'CALL'});
        currentCall = call;
        stopRinging();

        ModalManager.close('preCall');
        ModalManager.open('inCall', {
          eventId: call.eventId,
          isMakingCall: true,
          call: call
        });

        //FIXME: Remove this is to not close the popup as the other end has not joined/answered
        $timeout(function () {
          $rootScope.$broadcast('CallEntered');
        }, 100);
      }

      function handleStartOrJoinSuccess(call,  action) {
        $log.debug('CallService: handleStartOrJoinSuccess: ', call,  action);
        //audit and logging
        Events.auditLogging({id: call.eventId, actionType:action});

        if (call.isMySystemInCall === false) {
          Notifier.createMessage('confirmation', VidyoMessages.call.startSuccess.message, '');
          return;
        }

        currentCall = call;
        stopRinging();

        ModalManager.close('joinCall');
        ModalManager.open('inCall', {
          eventId: call.eventId,
          isMakingCall: true,
          call: call
        });

        //FIXME: Remove this is to not close the popup as the other end has not joined/answered
        $timeout(function () {
          $rootScope.$broadcast('CallEntered');
        }, 100);
      }

      function handleCreateCallFailure(call, rejection) {
        $log.debug('CallService: handleStartCallFailure(): ', {call: call, rejection: rejection});
        var deferred = $q.defer();

        if (rejection.error === 'E_CALL_UNDEFINED') {
          Notifier.createMessage('error', VidyoMessages.call.cantStartConference.message, VidyoMessages.call.cantStartConference.solution, JSON.stringify(rejection));
        } else if (rejection.error === 'E_CALL_CALLER_BUSY') {
          // CS1-1264 - User Tries to Make Second Call While Already in an Active Call
          Notifier.createMessage('error', VidyoMessages.call.alreadyConnected.message, null, JSON.stringify(rejection.error));
        } else if (rejection.error === 'E_CALL_CALLER_OFFLINE' || rejection.error === 'E_USER_NOT_ABLE_JOIN_THE_CALL') {
          Notifier.createMessage('error', VidyoMessages.call.localParticipantOffline.message, VidyoMessages.call.localParticipantOffline.solution, JSON.stringify(rejection));
        } else if (rejection.error === 'E_CALL_CALLEE_BUSY' || rejection.error === 'E_CALL_CALLEE_OFFLINE' || rejection.error === 'E_CALL_CALLEE_NOT_ONLINE') {
          Notifier.createMessage('error', VidyoMessages.call.cantReach.message, VidyoMessages.call.cantReach.solution, JSON.stringify(rejection));
        } else if (rejection.error === 'E_CALL_HOST_BUSY' || rejection.error === 'E_CALL_HOST_OFFLINE') {
          Notifier.createMessage('error', VidyoMessages.call.cantReachHost.message, VidyoMessages.call.cantReachHost.solution);
        } else if (rejection.error === 'E_CALL_NOT_ANSWERED' || rejection.error === 'E_CALL_REJECTED') {
          Notifier.createMessage('warning', VidyoMessages.call.cantReach.message + ' ' + VidyoMessages.call.cantReach.solution);
        } else if (rejection.error === 'E_CALL_EVENT_CANCELLED') {
          Notifier.createMessage('error', VidyoMessages.call.eventCancelled.message, VidyoMessages.call.eventCancelled.solution);
        } else if (rejection.error === 'E_VALIDATION_ERROR' && rejection.details === 'NO_OWN_OR_DELEGATOR_SYSTEM') {
          Notifier.createMessage('error', VidyoMessages.otnInvite.noOwnOrDelegatorSystem.message, null);
        } else {
          Notifier.createMessage('error', VidyoMessages.call.cantStartConference.message, VidyoMessages.call.cantStartConference.solution, JSON.stringify(rejection.error));
        }

        stopRinging();
        PresenceService.setMyPresence('online');
        // TODO: CS1-2673: Should we close the modal on error?
        // ModalManager.close('preCall');

        if (call.eventId) {
          // Event already created, perform cleanup
          getVvr(call)
            .then(stopMediaStatsPoll)
            .then(leaveVideoCompCall)
            .then(stopVvrVideoModality) // ---> kickout all participants, onDisconnect is called
            .then(function(call) {
              currentCall = call;
              deferred.resolve(call);
            })
            .catch(function(error) {
              deferred.reject({
                'error': error
              });
            });
        } else {
          deferred.resolve(call);
        }

        //TODO: Move this to an appropriate place, and implement:
        // FIXME: Work with @Yakov to implement the cleanup of event in case of failure
        /*
        $timeout(function() {
          //have to run it with timeout to allow the user to complete join room process
          $log.error('send stop event event ' + call.eventId);
          Events.stop({ id: call.eventId,force:true });
        }, 1000);
        */

        return deferred.promise;
      }

      function handleStartOrJoinFailure(call, rejection) {
        stopRinging();
        PresenceService.setMyPresence('online');
        ModalManager.close('joinCall');

        if (rejection.error === 'E_CALL_JOIN_NOT_IN_PROGRESS') {
          // Tried to join a MP call that was not in progress
          var message = VidyoMessages.call.cantJoin.message;
          message = message.replace('<CUSTOMER_SUPPORT_HELP_LINE>', AppConfig.links.help.customerSupportHelpLine);
          Notifier.createMessage('error', message, VidyoMessages.call.cantJoin.solution, JSON.stringify(rejection.error));
        } else if (rejection.error === 'E_USER_NOT_ABLE_JOIN_THE_CALL') {
          Notifier.createMessage('error', VidyoMessages.call.localParticipantOffline.message, VidyoMessages.call.localParticipantOffline.solution, JSON.stringify(rejection.error));
        } else if (rejection.error === 'E_CALL_CALLER_BUSY') {
          // CS1-1264 - User Tries to Make Second Call While Already in an Active Call
          Notifier.createMessage('error', VidyoMessages.call.alreadyConnected.message, null, JSON.stringify(rejection.error));
        } else if (rejection.error === 'E_CALL_CALLER_OFFLINE' || rejection.error === 'E_USER_NOT_ABLE_JOIN_THE_CALL') {
          Notifier.createMessage('error', VidyoMessages.call.localParticipantOffline.message, VidyoMessages.call.localParticipantOffline.solution, JSON.stringify(rejection));
        } else if (rejection.error === 'E_CALL_CALLEE_BUSY' || rejection.error === 'E_CALL_CALLEE_OFFLINE' || rejection.error === 'E_CALL_CALLEE_NOT_ONLINE') {
          Notifier.createMessage('error', VidyoMessages.call.cantReach.message, VidyoMessages.call.cantReach.solution);
        } else if (rejection.error === 'E_CALL_HOST_BUSY' || rejection.error === 'E_CALL_HOST_OFFLINE') {
          Notifier.createMessage('error', VidyoMessages.call.cantReachHost.message, VidyoMessages.call.cantReachHost.solution);
        } else if (rejection.error === 'E_CALL_NOT_ANSWERED' || rejection.error === 'E_CALL_REJECTED') {
          Notifier.createMessage('warning', '<b> ' + VidyoMessages.call.cantReach.message + ' </b> - ' + VidyoMessages.call.cantReach.solution, '');
          stopVvrVideoModality(call);
        } else if (rejection.error === 'E_CALL_EVENT_CANCELLED') {
          Notifier.createMessage('error', VidyoMessages.call.eventCancelled.message, VidyoMessages.call.eventCancelled.solution);
        } else {
          var message = VidyoMessages.call.cantJoin.message;
          message = message.replace('<CUSTOMER_SUPPORT_HELP_LINE>', AppConfig.links.help.customerSupportHelpLine);
          Notifier.createMessage('error', message, VidyoMessages.call.cantJoin.solution, JSON.stringify(rejection));
        }
      }

      function completeCall(call) {
        $log.debug('CallService: completeCall, function called with parameter: ', call);
        var deferred = $q.defer();

        stopMediaStatsPoll(call)
          .then(leaveVideoCompCall) // Do we need this
          .then(function(call) {
            $log.info('Call is now ended!');
            $rootScope.$broadcast('CallEnded');
            currentCall = call;
            deferred.resolve(call);
          })
          .catch(function(error) {
            deferred.reject({
              'error': error
            });
          });

        return deferred.promise;
      }

      function refreshSession(call) {
        $log.debug('CallService: refreshSession, function called with parameter: ', call);
        var deferred = $q.defer();
        var participantCaller = getCaller(call);

        VirtualVisits.refreshSession({
          id: call.vvrId,
          participantId: participantCaller.id
        }, participantCaller, function(response) {
          $log.debug('VirtualVisits.refreshSession resolved: ', response);
          participantCaller.videoSession = response.videoSession;
          deferred.resolve(call);
        }, function(rejection) {
          $log.debug('VirtualVisits.refreshSession rejected: ', rejection);
          deferred.reject(rejection);
        });

        return deferred.promise;
      }

      function getParticipants() {
        $log.debug('CallService: getParticipants, function called.');
        var deferred = $q.defer();
        var participants = [];
        var promises = [];

        if (currentCall.vvrData === undefined) {
          $log.info('** CallService.getParticipants currentCall.vvrData is undefined maybe due to mirror test: ', currentCall);
          deferred.resolve(participants);
          return deferred.promise;
        }

        otnVideoComp.getParticipants(function(participants) {
          $log.debug('CallService: otnVideoComp.getParticipants: ' + JSON.stringify(participants));

          angular.forEach(participants, function(participant, key) {
            promises.push(
              getParticipant(currentCall.vvrData, participant)
            );
          });

          $q.all(promises).then(function() {
            deferred.resolve(participants);
          });
        });

        return deferred.promise;
      }

      function isEmpty (str) {
        if(!str) return true;
        if ((str + '').trim() === '') return true;
        return false;
      }

      function getDisplayName(participant) {
        let displayName = '';
        let isSipAudio = false;
        if (!isEmpty(participant.name)) {
          displayName = participant.name;
        } 
        
        if (participant.isSipAudio) {
          isSipAudio = true;
        }
        else if (!isEmpty(participant.uri)) {
          const regexp = /(.*)sip:(.*)@/gm;
          const matches = [...participant.uri.matchAll(regexp)];
          if (matches.length !== 0) {
            isSipAudio = true;
          }
        }

        if (isSipAudio) {
          if (isEmpty(displayName)) {
            displayName = 'Unkown Phone';
          }
          else {
            displayName = displayName + ' (Phone)';
          }
        }        
        
        return displayName;
      }

      function getParticipant(vvr, participant) {
        $log.debug('CallService: getParticipant, function called with parameter: ', vvr, participant);
        var deferred = $q.defer();

        if (vvr === undefined) {
          $log.info('** CallService.getParticipant vvr is undefined maybe due to mirror test: ', vvr);
          participant.displayName = getDisplayName(participant);
          deferred.resolve(participant);
          return deferred.promise;
        }

        getVideoProviderParticipant(vvr, participant)
          .then(function(videoProviderParticipant) {
            if (videoProviderParticipant && videoProviderParticipant.isRoomBasedSystem) {
              participant.isSupportFECC = true;
              participant.videoProviderParticipant = videoProviderParticipant;
            } else{
              participant.isSupportFECC = false;
            }
            participant.displayName = getDisplayName(participant);

            deferred.resolve(participant);
          });

        return deferred.promise;
      }

      function getVideoProviderParticipant(vvr, participant) {
        $log.debug('CallService: getVideoProviderParticipant, function called with parameter: ', vvr, participant);
        var deferred = $q.defer();

        // Returns array for that reason can't use the default $resource
        VirtualVisits.getVideoProviderParticipant({
          id: vvr.id,
          participantId: participant.id
        }, (response) => {
          $log.debug('VirtualVisits.getVideoProviderParticipant resolved: ', response);
          deferred.resolve(response[0]);
        }, (rejection) => {
          $log.debug('VirtualVisits.getVideoProviderParticipant rejected: ', rejection);
          deferred.reject(rejection);
        });

        return deferred.promise;
      }

      function getEventVvr(event) {
        var deferred = $q.defer();
        if (event.vvr && event.vvr.vvrId) {
          deferred.resolve(event.vvr.vvrId);
        } else {
          findVvr({
            eventId: event.requestId
          }).then(function (data) {
            event.vvr = data;
            deferred.resolve(data.vvrId);
          });
        }
        return deferred.promise;
      }

      /**
       * Fetches event participants, and broadcasts an event on $rootScope with the result. Broadcasts an array of objects
       * such as: {id: "993d8d07-0e73-4351-873e-f2d7e4445220", alias: "Alex FF", name: "Alex FF", role: "guest",
       * connectTime: "2020-02-10T18:03:51.704739", ...}
       *
       * @param eventId The event.requestId for which to get participants.
       */
      function fetchRTEventParticipantsStatus(event) {
        if (!event) {
          return;
        }
        var buildParticipantSummary = function (requestId, participants) {
          var summary = {
            requestId: requestId,
            hosts: 0,
            guests: 0,
            isWaiting: false
          };
          angular.forEach(participants, function (participant) {
            if (participant.role === 'guest') {
              summary.guests++;
            } else {
              summary.hosts++;
            }
            //So long as 1 is waiting, isWaiting is true
            if (participant.serviceType === 'waiting_room') {
              summary.isWaiting = true;
            }
          });
          return summary;
        };

        getEventVvr(event).then(function (vvrId) {
          VirtualVisits.getVideoProviderParticipants({
            id: vvrId
          }, function (videoProviderParticipants) {
            let participants = [];
            angular.forEach(videoProviderParticipants, function (participant) {
              participant.displayName = getDisplayName(participant);
              participants.push(participant);
            });
            $rootScope.$broadcast('event_update_participants', {
              requestId: event.requestId,
              videoProviderParticipants: participants
            });
            $rootScope.$broadcast('event_list_update_participants', [
              buildParticipantSummary(event.requestId, participants)
            ]);
          });
        });
      }

      function fecc(participant, action) {
        $log.debug('CallService: fecc, function called with parameter: ', participant, action);
        var deferred = $q.defer();

        VirtualVisits.fecc({
          id: participant.videoProviderParticipant.id,
          action: action
        }, function(response) {
          $log.debug('VirtualVisits.fecc resolved: ', response);
          deferred.resolve(response);
        }, function(rejection) {
          $log.debug('VirtualVisits.fecc rejected: ', rejection);
          deferred.reject(rejection);
        });

        return deferred.promise;
      }

      function isSharingContent() {
        return otnVideoComp.isSharingContent();
      }

      function isViewingSharedContent() {
        return otnVideoComp.isViewingSharedContent();
      }

      function isMuteSpeakerSupported() {
        return otnVideoComp.isMuteSpeakerSupported();
      }

      function isSpeakerSwitchInCallSupported() {
        return otnVideoComp.isSpeakerSwitchInCallSupported();
      }

      function isDeviceSwitchInCallSupported() {
        return otnVideoComp.isDeviceSwitchInCallSupported();
      }

      function isContentShareSupported() {
        return otnVideoComp.isContentShareSupported();
      }

      function isContentShareLimited() {
        return otnVideoComp.getViewContentShareMode() != undefined &&
          otnVideoComp.getViewContentShareMode() === 'IMAGE';
      }

      function toggleContentSharing() {
        $log.debug('CallService: toggleContentSharing, function called');
        var deferred = $q.defer();

        if (!isSharingContent()) {
          otnVideoComp.startContentShare(function() {
            $log.debug('otnVideoComp.startContentShare started');
            deferred.resolve(true);
          }, function(err) {
            $log.debug('otnVideoComp.startContentShare started: ', err);
            deferred.reject(err);
          });
        } else{
          otnVideoComp.stopContentShare(function() {
            $log.debug('otnVideoComp.startContentShare stopped');
            deferred.resolve(false);
          }, function(err) {
            $log.debug('otnVideoComp.startContentShare stopped: ', err);
            deferred.reject(err);
          });
        }

        return deferred.promise;
      }

      function getSpeakers() {
        $log.debug('CallService: getSpeakers, function called');
        var deferred = $q.defer();

        otnVideoComp.getSpeakers(function(speakers) {
          $log.debug('otnVideoComp.getSpeakers: ', speakers);
          deferred.resolve(speakers);
        });

        return deferred.promise;
      }

      function getCurrentSpeakerId() {
        $log.debug('CallService: getCurrentSpeakerId, function called');
        var deferred = $q.defer();

        otnVideoComp.getCurrentSpeaker(function(speakerId) {
          $log.debug('otnVideoComp.getCurrentSpeaker: ', speakerId);
          deferred.resolve(speakerId);
        });

        return deferred.promise;
      }

      function getCameras() {
        $log.debug('CallService: getCameras, function called');
        var deferred = $q.defer();

        otnVideoComp.getCameras(function(cameras) {
          $log.debug('otnVideoComp.getCameras: ', cameras);
          deferred.resolve(cameras);
        });

        return deferred.promise;
      }

      function getCurrentCameraId() {
        $log.debug('CallService: getCurrentCameraId, function called');
        var deferred = $q.defer();

        otnVideoComp.getCurrentCamera(function(cameraId) {
          $log.debug('otnVideoComp.getCurrentCamera: ', cameraId);
          deferred.resolve(cameraId);
        });

        return deferred.promise;
      }

      function getMics() {
        $log.debug('CallService: getMics, function called');
        var deferred = $q.defer();

        otnVideoComp.getMics(function(mics) {
          $log.debug('otnVideoComp.getMics: ', mics);
          deferred.resolve(mics);
        });

        return deferred.promise;
      }

      function getCurrentMicId() {
        $log.debug('CallService: getCurrentMicId, function called');
        var deferred = $q.defer();

        otnVideoComp.getCurrentMicrophone(function(micId) {
          $log.debug('otnVideoComp.getCurrentMicrophone: ', micId);
          deferred.resolve(micId);
        });

        return deferred.promise;
      }

      function getBandwidthSetting() {
        return currentCall.bandwidth;
      }

      // FACTORY RETURN
      return {
        createCall,
        startCall,
        joinCall,
        acceptCall,
        rejectCall,
        missCall,
        endCall,
        endCallWithoutRefresh,
        endCallOnUnload,
        completeCall,
        stopCurrentCall,
        getVvr,
        refreshCallData,
        findVvr,
        lockVvr,
        unlockVvr,
        stopVvrVideoByVvrData,
        redialParticipant,
        isVvrInProgress,
        getCurrentCall: () => currentCall,
        getParticipants,
        getParticipant,
        fetchRTEventParticipantsStatus,
        fecc,
        getSelfViewMode: function() {
          return otnVideoComp.getSelfViewMode();
        },
        isMuteSpeakerSupported,
        isSpeakerSwitchInCallSupported,
        isDeviceSwitchInCallSupported,
        isContentShareSupported,
        isContentShareLimited,
        getSpeakers,
        getCurrentSpeakerId,
        setSpeaker: function(speaker) {
          if (speaker) {
            otnVideoComp.setSpeaker(speaker.id);
          }
        },
        getCameras,
        getCurrentCameraId,
        setCamera: function(camera, showWarning) {
          if (camera) {
            //Intentional that undefined/null showWarning will equate to true. TODO: Refactor this in the future
            if (VideoService.isInternetExplorer() && showWarning !== false) {
              Notifier.createMessage('warning', 'The change will take effect the next time you join a call.');
            }
            otnVideoComp.setCamera(camera.id);
          }
        },
        changeCamera: function() {
          otnVideoComp.setCameraToDefault();
        },
        getMics,
        getCurrentMicId,
        setMic: function(mic, showWarning) {
          if (mic) {
            //Intentional that undefined/null showWarning will equate to true. TODO: Refactor this in the future
            if (VideoService.isInternetExplorer() && showWarning !== false) {
              Notifier.createMessage('warning', 'The change will take effect the next time you join a call.');
            }
            return otnVideoComp.setMic(mic.id);
          }
        },
        changeMic: function() {
          otnVideoComp.setMicToDefault();
        },
        toggleVolume: function() {
          return otnVideoComp.toggleVolume();
        },
        toggleMic: function() {
          return otnVideoComp.toggleMic();
        },
        toggleCamera: function() {
          return otnVideoComp.toggleCamera();
        },
        changeSelfViewMode: function(selfViewMode) {
          otnVideoComp.changeSelfViewMode(selfViewMode);
        },
        toggleSelfView: function() {
          var isSelfViewOn = false;
          if (otnVideoComp.getSelfViewMode() === 'PIP') {
            otnVideoComp.changeSelfViewMode('None');
            isSelfViewOn = false;
          } else{
            otnVideoComp.changeSelfViewMode('PIP');
            isSelfViewOn = true;
          }
          return isSelfViewOn;
        },
        startMirrorTestCall,
        isSharingContent,
        isViewingSharedContent,
        toggleContentSharing,
        isGuestCall,
        isP2P,
        isMultipoint: () => isCurrentCallMultipoint(),
        isP2PJoin,
        isP2PJoinOrCall,
        getMediaStats: () => currentCallMediaStats,
        getCurrentCallQualityLevel: () => currentCallQualityLevel,
        testCallQuality,
        getBandwidthSetting
      };

      function pad(number) {
        if (number < 10) {
          return '0' + number;
        }
        return number;
      }

      function toISOString(date) {
        return date.getUTCFullYear() +
          '-' + pad(date.getUTCMonth() + 1) +
          '-' + pad(date.getUTCDate()) +
          'T' + pad(date.getUTCHours()) +
          ':' + pad(date.getUTCMinutes()) +
          ':' + pad(date.getUTCSeconds());
      }

      function setCallerFlag(call) {
        angular.forEach(call.vvrData.participants, function(participant, key) {
          if (participant.userGuid) {
            participant.caller = participant.userGuid === User.details.guid;
          } else{
            participant.caller = false;
          }
        });
      }

      function getCaller(call) {
        return $filter('filter')(call.vvrData.participants, {
          caller: true
        })[0];
      }

      function getCallees(call) {
        return (call.vvrData)
          ? call.vvrData.participants.filter((participant) => (participant.caller === false))
          : [];
      }

      function getPcvcCallees(call) {
        const callees = getCallees(call);
        return callees.filter((callee) => (callee.userGuid));
      }

      function getCallee(call) {
        return getCallees(call)[0];
      }

      function isMultipoint(call) {
        // TODO: CS1-2673: Consider using getEventParticipants(call)
        const vvrParticipants = call.vvrData
          ? call.vvrData.participants
          : undefined;
        const eventParticipants = call.createEventRequest
          ? call.createEventRequest.participants
          : undefined;

        if (vvrParticipants) {
          return vvrParticipants.length > 2;
        } else if (eventParticipants) {
          return eventParticipants.length > 2;
        }
        return undefined;
      }

      function isCurrentCallMultipoint() {
        return isMultipoint(currentCall);
      }

      function isMirrorTest(call) {
        return call.context === 'MIRROR_TEST';
      }
    });
;'use strict';
angular.module('otn.directives.vidyo').service('ConfirmationModalService',
  function ($injector, $log, $timeout, $rootScope, $location, $window, $filter, $q,
    EventsService, OtnConfirmationModalService, VideoService, Notifier, VidyoMessages,
    ngAudio, Events, User, Users, UserSettings, InviteModalService) {
    //this service is created to manage the confirm/cancel modal pop-up and to use the generic Otn Confirmation Modal
    $log.info('ConfirmationModalService stared.');

    /**
     * Create Otn Invite Event and start call.
     *
     * @param event
     * @returns {*} Start call promise.
     */
    var createOtnInviteAndCall = function (event) {
      $log.info('createOtnInviteAndCall start');
      var CallService = $injector.get('CallService'); // Circular dependency

      var call = {
        createEventRequest: event
      };
      return CallService.createCall(call);
    };

    const parseJson = (str) => {
      try {
        return JSON.parse(str);
      } catch (e) {
        return {};
      }
    };

    /**
     * Display details of rejection due to conflict.
     *
     * Details Object:
     * {"systems":[
     *    {"id":24360182,"eventId":250019571,"name":"Alex_Rechetov_OTN"},
     *    {"id":1974303,"eventId":250019571,"name":"LON_OTN_0271_LAB_13"}
     * ],"contact":
     *    {"id":24360179,"eventId":250019571,"name":"Dr. Alex Rechetov"}
     * }
     * End goal: "The systems A, B, C and the consultant D have a conflicting event at the selected time"
     *
     * @param event
     * @param errorDetails
     */
    const buildConflictMessage = (event, errorDetails) => {
      const isClinical = EventsService.isClinical(event);
      const detailsJSON = parseJson(errorDetails);
      const { systems, contact } = detailsJSON;
      if ((!systems || systems.length === 0) && !contact) {
        return VidyoMessages.otnInvite.msg04.message;
      }

      let message = 'The';
      let numConflicts = 0;
      if (systems && systems.length > 0) {
        const systemNames = systems.map((system) => ` ${system.name}`);
        message += ` system${systems.length > 1 ? 's' : ''}${systemNames}`;
        numConflicts += systems.length;
      }
      if (contact && contact.name) {
        if (message.length > 3) {
          message += ' and';
        }
        message += ` ${isClinical ? 'consultant' : 'presenter'} ${contact.name}`;
        numConflicts += 1;
      }
      message += ` ${(numConflicts > 1) ? 'have' : 'has'} a conflicting event at the selected time. Please pick another time or modify your participant system list.`;
      return message;
    };

    /**
     * Schedule Otn Invite Event
     *
     * @param event
     * @param viewHandout
     * @returns {*} Event creation promise
     */
    var scheduleOtnInvite = function (event, viewHandout) {
      $log.info('scheduleOtnInvite start');
      var deferred = $q.defer();

      Events.create(event,
        function (response) {
          $log.info('scheduleOtnInvite: Event created. ID: ' + response.eventId);

          //If event is created from the hub home page, just show the confirmation
          //If it's created on the eventList page, select the event date
          if ($location.path() === '/hub') {
            $location.search('eventCreated', true);
          } else {
            $location.search('selDate', $filter('date')(event.startTime));
          }

          /*
          This used to automatically switch the event list to the delegator. As of 4.8 we don't have a clear delegator
          in events. Therefore, we return all PCVC/Legacy participants and let the caller cross-reference that list with
          the delegator list.
           */
          $rootScope.$broadcast('closeModal', 'preCall');
          $rootScope.$broadcast('event_created', {
            eventId: response.eventId,
            startTime: event.startTime,
            viewHandout: viewHandout,
            memberParticipants: EventsService.getMemberParticipants(event)
          });

          deferred.resolve();
        },
        function (rejection) {
          $log.debug('scheduleOtnInvite: Failed to create event. Error: ', rejection);
          const { error, details } = rejection.data;
          if (error === 'E_TIME_CONFLICT') {
            const message = buildConflictMessage(event, details);
            Notifier.createMessage('error', message, null, details);
          } else {
            Notifier.createMessage('error', VidyoMessages.otnInvite.msg07.message, VidyoMessages.otnInvite.msg07.solution, JSON.stringify(rejection));
          }
          deferred.reject(rejection);
        });

      return deferred.promise;
    };

    const isPHIConsent = (participant, event) => {
      if (event.patients && event.patients.length > 0) {
        var patient = event.patients.find((item) => {
          if(item.email === participant.email) {
            return item;
          }
        });
        if(patient) {
          return patient.isPHIConsent;
        }
      }
      return false;
    };

    const participantToName = (participant) => {
      let name = participant.name || '';
      let email = participant.email ? `(${participant.email})` : '';
      return `${name} ${email}`.trim();
    }

    const participantsToNames = (participants) => (
      participants.map(participantToName)
    );

    const eventParticipantsWithConsent = (participants, event) => {
      var eventParticipants = [];
      participants.forEach((participant) => {
        let participantName = participantToName(participant);
        let isParticipantConsented = isPHIConsent(participant, event);
        eventParticipants.push({
          displayName: participantName,
          name: participant.name,
          isPHIConsent: isParticipantConsented,
          email: participant.email,
          type: participant.type
        });
      });

      return eventParticipants;
    };

    // Converts list of participants to a string in the form: "Name at Email, Name2 at Email2, ..."
    const guestParticipantsToString = (participants) => {
      const names = [];
      participants.forEach((participant) => {
        if (participant.name && participant.email) {
          names.push(`${participant.name} at ${participant.email}`);
        }
      });
      return names.join(', ');
    };

    const showPatientConsentModal = function (onConfirm, onSuccess, onFailure) {
      $log.debug('showOtnInviteCallModal start');
      var modalOptions = {
        closeButtonText: 'Cancel',
        actionButtonText: 'Confirm',
        actionHeaderText: 'Consent',
        questionText: 'Patient has agreed to receive OTNInvite email with PHI exposed.',
        explanationText: 'By clicking "continue", you agree to the term presented above.'
      };

      OtnConfirmationModalService.showModal({}, modalOptions).then(function (result) {
        onConfirm();
      });
    };

    var showOtnInviteCallModal = function (event, modalData, onConfirm, onSuccess, onFailure) {
      $log.debug('showOtnInviteCallModal start');
      var modalOptions = {
        closeButtonText: 'Cancel',
        actionButtonText: 'Create',
        actionHeaderText: 'Create Event',
        questionText: 'Are you sure you want to create this event?',
        explanationText: modalData.explanationText,
        noteText: modalData.noteText
      };

      OtnConfirmationModalService.showModal({}, modalOptions).then(function (result) {
        onConfirm();
        createOtnInviteAndCall(event)
          .then(onSuccess)
          .catch(onFailure);
      });
    };

    const mapPhiConsentToPatient = function(event, participantsWithConsent) {
      let mappedPatients = [];
      participantsWithConsent.forEach((participant) => {
        if(participant.isPHIConsent) {
          mappedPatients.push({
            lastName: participant.name,
            email: participant.email,
            gender: 'O',
            isPHIConsent: participant.isPHIConsent
          });
        }
      });
      event.patients = mappedPatients;
    };

    const showOtnInviteScheduleModal = function (event, modalData, onConfirm, onSuccess, onFailure) {
      $log.debug('showOtnInviteScheduleModal start');
      const modalOptions = {
        closeButtonText: 'Cancel',
        actionButtonText: 'Schedule',
        actionHeaderText: 'Schedule Event',
        questionText: 'Are you sure you want to schedule this event?',
        explanationText: modalData.explanationText,
        handoutText: modalData.handoutText,
        showHandoutCheckbox: modalData.isHandoutCheckboxShown,
        noteText: modalData.noteText,
        adminForUserId: modalData.adminForUserId,
        adminContact: modalData.adminContact,
        isAdminInfoAccessAllowed: modalData.isAdminInfoAccessAllowed,
        eventParticipants: eventParticipantsWithConsent(
          EventsService.getNonHostSystems(event),
          event
        ),
        eventConsultantLabel: EventsService.eventTypeToConsultantRole(event.category), // 'Consultant / Speaker / Chair',
        eventConsultant: event.eventContact.name,
        eventConsultantSystemLabel: EventsService.eventTypeToHostRole(event.category), // 'Consultant system / Host system',
        eventConsultantSystem: participantToName(
          EventsService.getHostSystem(event)
        ),
        eventTime: $filter('date')(event.startTime, 'MMM dd, yyyy, HH:mm') + ' - ' + $filter('date')(event.endTime, 'HH:mm'),
        eventCategory: event.category
      };

      InviteModalService.showModal({}, modalOptions).then(function (result) {
        onConfirm();
        mapPhiConsentToPatient(event, modalOptions.eventParticipants);
        scheduleOtnInvite(event, result.viewHandout)
          .then(onSuccess)
          .catch(onFailure);
      });
    };

    /**
     * @returns {Promise<{ adminContact, adminForUserId }>}
     */
    const getDelegatorAdminContact = function (delegatorUserId) {
      return new Promise((resolve, reject) => {
        Users.getUserSettings({
          userId: delegatorUserId,
          serviceType: 'pcvc'
        }, function (settings) {
          $log.debug('Got delegator settings: ', settings);
          let adminContact = {};
          for (var i = 0; i < settings.length; i++) {
            var setting = settings[i];
            $log.debug('Name: ' + setting.name + ', value: ' + setting.value);
            if (setting.name === 'contactName') {
              adminContact.name = setting.value;
            } else if (setting.name === 'contactPhone') {
              adminContact.phone = setting.value;
            } else if (setting.name === 'contactEmail') {
              adminContact.email = setting.value;
            }
          }

          if (Object.keys(adminContact).length === 0) {
            adminContact = null;
          }
          resolve({
            adminContact,
            adminForUserId: delegatorUserId,
            isAdminInfoAccessAllowed: true
          });
        }, function (rejection) {
          $log.error('Error getting delegator settings: ', rejection);
          reject(rejection);
        });
      });
    };

    /**
     * @returns {Promise<{ adminContact, adminForUserId }>}
     */
    const getMyAdminContact = () => {
      return new Promise((resolve, reject) => {
        UserSettings.getPcvcSettings().then((settings) => {
          let adminContact;
          if (!settings.adminContactName) {
            adminContact = null
          } else {
            adminContact = {
              name: settings.adminContactName,
              phone: settings.adminContactPhone,
              email: settings.adminContactEmail
            };
          }
          resolve({
            adminContact,
            adminForUserId: User.details.userid,
            isAdminInfoAccessAllowed: true
          })
        }).catch((err) => reject(err));
      });
    };

    /**
     * Gets the Admin Contact data for the given event. The admin data is based on the selected
     * Consultant/Speaker/Chair (event.eventContact).
     *
     * Rules (CS1-3227)
     * ================
     * 1. If contact is a PCVC user and is Myself or My Delegator - return their Admin Contact
     * 2. If contact is a PCVC user and is NOT Myself or My Delegator - don't show (isAdminInfoAccessAllowed=false)
     * 3. If contact is NOT a PCVC user - return the Creator's (my) Admin Contact
     *
     * A contact is a PCVC user if they have a userId.
     *
     * @param event
     * @returns {Promise<{ adminContact, adminForUserId }>}
     */
    const getAdminContact = (event) => {
      const contactUserId = event.eventContact.userId;
      const myUserId = User.details.userid;

      if (!contactUserId || contactUserId === myUserId) {
        // Contact is not a PCVC user or is myself
        return getMyAdminContact();
      }

      return new Promise((resolve, reject) => {
        Users.isDelegator({
          userId: contactUserId
        }, ({ isPCVCDelegator }) => {
          if (isPCVCDelegator) {
            // Contact is a PCVC user and my delegate. Return their admin data.
            getDelegatorAdminContact(contactUserId)
              .then((contact) => resolve(contact))
              .catch((err) => reject(err));
          } else {
            // Contact is a PCVC user but not my delegate. Not allowed to access.
            resolve({
              adminContact: null,
              adminForUserId: contactUserId,
              isAdminInfoAccessAllowed: false
            });
          }
        }, (err) => {
          $log.error('Could not get isDelegator for ' + contactUserId, err);
          resolve(null);
        });
      });
    };

    /**
     * @param event
     * @param adminContact -  Admin contact data
     * @param adminForUserId - User for which the admin contact was retrieved
     * @returns {{noteText: string, adminForUserId: *, isHandoutCheckboxShown: boolean, adminContact: *}}
     */
    const buildModalData = function (event, adminContact, adminForUserId, isAdminInfoAccessAllowed) {
      const modalData = {
        noteText: '',
        isHandoutCheckboxShown: false,
        adminContact,
        adminForUserId,
        isAdminInfoAccessAllowed
      };

      const isAdhoc = EventsService.isAdHoc(event);
      const isClinical = EventsService.isClinical(event);
      const hasGuests = EventsService.isGuestLink(event);
      const hasNonMemberParticipants = EventsService.hasNonMemberParticipants(event);

      if (isClinical && isAdhoc && hasNonMemberParticipants) {
        modalData.explanationText = 'When you click "Create" the videoconference invite will be emailed to ' +
          guestParticipantsToString(event.participants) + ' and the event will start.';
      }
      if (isClinical && !isAdhoc && hasNonMemberParticipants) {
        modalData.explanationText = 'The event will be sent to any participants invited by email.';
      }
      if (isClinical && !isAdhoc && hasGuests) {
        modalData.handoutText = 'If you want to give the attendees additional notice about the event, a patient ' +
          'handout which includes the host\'s administrative contact is available in the event details. (The handout ' +
          'will not be sent to the patients automatically).';
        modalData.isHandoutCheckboxShown = true;
      }
      if (!isClinical && isAdhoc && hasNonMemberParticipants) {
        modalData.explanationText = 'When you click "Create" the videoconference invite will be emailed to ' +
          guestParticipantsToString(event.participants) + ' and the event will start.';
        modalData.noteText = 'The email invitation will also be sent to your administrative contact if you have ' +
          'identified one in your videoconference settings.';
      }
      if (!isClinical && !isAdhoc && hasNonMemberParticipants) {
        modalData.explanationText = 'The event will be sent to any participants invited by email and the ' +
          'administrative contact email if provided. The contact information will also be included in the ' +
          'invite email if provided. If you\'ve invited participants by email, the event cannot be modified ' +
          'once it has been created.';
      }

      return modalData;
    };

    return {
      /**
       * we show this confirmation for events that includes guests and offnets since we send email notification
       * @param data
       */
      showPreCallConfirmModal: function (data) {
        $log.debug('ConfirmationModalService.showPreCallConfirmModal() data=', data);
        const { event } = data;

        const onConfirm = data.onConfirm || function () {};
        const onSuccess = data.onSuccess || function () {};
        const onFailure = data.onFailure || function () {};

        getAdminContact(event).then(({ adminContact, adminForUserId, isAdminInfoAccessAllowed }) => {
          const modalData = buildModalData(event, adminContact, adminForUserId, isAdminInfoAccessAllowed);
          if (event.eventSource === 'ADHOC') {
            showOtnInviteCallModal(event, modalData, onConfirm, onSuccess, onFailure);
          } else {
            showOtnInviteScheduleModal(event, modalData, onConfirm, onSuccess, onFailure);
          }
        }).catch((rejection) => {
          Notifier.createMessage('error', VidyoMessages.otnInvite.cantGetAdminContact.message, VidyoMessages.otnInvite.cantGetAdminContact.solution, JSON.stringify(rejection));
          onFailure(rejection);
        });
      },
      showPatientConsentModal: showPatientConsentModal
    };
  });
;'use strict';
angular.module('otn.directives.vidyo').service('FullScreen', function ($log, $window) {
  $log.info('FullScreen initializing');

  var getBodyElement = function () {
    return angular.element($window.document.body);
  };

  // Returns element that's currently in full-screen mode
  var getFullScreenElement = function () {
    var elem = $window.document.fullscreenElement ||
      $window.document.msFullscreenElement ||
      $window.document.mozFullScreenElement ||
      $window.document.webkitFullscreenElement ||
      $window.document.webkitCurrentFullScreenElement;
    return elem;
  };

  // Listens to full-screen changes, in case it was toggled without using our control (i.e. using Esc)
  var addFullScreenEventListener = function () {
    var onFullScreenChange = function (event) {
      if (getFullScreenElement()) {
        //Full screen is on
        getBodyElement().addClass('full-screen-video');
      } else {
        //Full screen is off
        getBodyElement().removeClass('full-screen-video');
      }
    };

    $window.document.addEventListener('fullscreenchange', onFullScreenChange);
    $window.document.addEventListener('MSFullscreenChange', onFullScreenChange);
    $window.document.addEventListener('mozfullscreenchange', onFullScreenChange);
    $window.document.addEventListener('webkitfullscreenchange', onFullScreenChange);
  };

  addFullScreenEventListener();

  var service = {};

  service.enter = function (element) {
    var method = element.requestFullscreen ||
      element.msRequestFullscreen ||
      element.mozRequestFullScreen ||
      element.webkitRequestFullscreen ||
      element.webkitRequestFullScreen;
    if (method) {
      return method.apply(element);
    } else {
      return false;
    }
  };

  service.exit = function () {
    var doc = $window.document;
    var isFullScreen = getFullScreenElement() ? true : false;
    if (isFullScreen) {
      var method = doc.exitFullscreen ||
        doc.msExitFullscreen ||
        doc.mozCancelFullScreen ||
        doc.webkitExitFullscreen ||
        doc.webkitCancelFullScreen;
      if (method) {
        return method.apply(doc);
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  service.toggle = function (element) {
    if (!getFullScreenElement()) {
      service.enter(element);
    } else {
      service.exit();
    }
  };

  return service;
});
;'use strict';

angular.module('otn.directives.vidyo').service('InviteModalService', ['$modal',
  function($modal) {

    var modalDefaults = {
      backdrop: true,
      keyboard: true,
      modalFade: true,
      templateUrl: 'html/inviteModal.html',
      windowClass: 'schedule-confirmation-modal'
    };

    var modalOptions = {
      closeButtonText: 'Close',
      actionButtonText: 'OK',
      actionHeaderText: 'Proceed?',
      questionText: 'Perform this action?',
      explanationText: '',
      handoutWarning: '',
      showHandoutCheckbox: false,
      noteText: ''
    };

    this.showModal = function(customModalDefaults, customModalOptions) {
      if (!customModalDefaults) {
        customModalDefaults = {};
      }
      customModalDefaults.backdrop = 'static';
      return this.show(customModalDefaults, customModalOptions);
    };

    this.show = function(customModalDefaults, customModalOptions) {
      //Create temp objects to work with since we're in a singleton service
      var tempModalDefaults = {};
      var tempModalOptions = {};

      //Map angular-ui modal custom defaults to modal defaults defined in service
      angular.extend(tempModalDefaults, modalDefaults, customModalDefaults);

      //Map modal.html $scope custom properties to defaults defined in service
      angular.extend(tempModalOptions, modalOptions, customModalOptions);

      if (!tempModalDefaults.controller) {
        tempModalDefaults.controller = ('modalController', ['$scope', '$modalInstance', 'UserPcvcPrefSettingsService', '$log', function($scope, $modalInstance, UserPcvcPrefSettingsService, $log) {
          $scope.modalOptions = tempModalOptions;
          $scope.model = {
            viewHandout: JSON.parse(localStorage.getItem('viewHandout')),
            adminText: UserPcvcPrefSettingsService.renderContact(tempModalOptions.adminContact),
            isAdminInfoAccessAllowed: tempModalOptions.isAdminInfoAccessAllowed,
            eventParticipants: tempModalOptions.eventParticipants,
            eventTime: tempModalOptions.eventTime,
            eventConsultantLabel: tempModalOptions.eventConsultantLabel,
            eventConsultant: tempModalOptions.eventConsultant,
            eventConsultantSystemLabel: tempModalOptions.eventConsultantSystemLabel,
            eventConsultantSystem: tempModalOptions.eventConsultantSystem,
            eventCategory: tempModalOptions.eventCategory
          };

          $scope.modalOptions.ok = function(result) {
            localStorage.setItem('viewHandout', $scope.model.viewHandout);
            $modalInstance.close({
              status: 'OK',
              viewHandout: $scope.model.viewHandout
            });
          };

          $scope.modalOptions.close = function(result) {
            $modalInstance.dismiss({
              status: 'CANCEL'
            });
          };

          $scope.modalOptions.editUserSettings = function() {
            UserPcvcPrefSettingsService.open(tempModalOptions.adminForUserId);
          };

          $scope.$on('user-pcvc-pref-settings-changed', function(angEvent, changedData) {
            $log.debug('inviteModal: received user-pcvc-pref-settings-changed %s', JSON.stringify(changedData));
            var newSettings = changedData.settings;
            $scope.model.adminText = UserPcvcPrefSettingsService.renderContact(
              {
                name: newSettings.contactName,
                phone: newSettings.contactPhone,
                email: newSettings.contactEmail
              }
            );
          });
        }]);
      }
      return $modal.open(tempModalDefaults).result;
    };
  }]);;'use strict';
angular.module('otn.directives.vidyo').service('ModalManager', function ($log, $rootScope, $modal, EventsService, ConfirmationModalService) {
  $log.info('ModalManager initializing');

  var manager = {
    instances: {
      preCall: null,
      incomingCall: null,
      inCall: null,
      preCallConfirm: null,
      deviceSelect: null,
      joinCall: null
    }
  };

  manager.open = function (modal, data) {
    $log.info('manager open start');
    if (manager.instances[modal]) {
      $log.warn('Tried to open modal "' + modal + '", but it was already open');
    } else {
      if (modal === 'incomingCall') {
        // Rationalize undefined to null to make everything from here deeper easier to work with
        if (typeof data === 'undefined') {
          data = {};
        }
        if (typeof data.eventId === 'undefined') {
          data.eventId = null;
        }
        if (typeof data.caller === 'undefined') {
          data.caller = {};
        }
        if (typeof data.caller.type === 'undefined') {
          data.caller.type = null;
        }
        if (typeof data.caller.mainName === 'undefined') {
          data.caller.mainName = null;
        }
        if (typeof data.caller.subName === 'undefined') {
          data.caller.subName = null;
        }

        manager.instances.incomingCall = $modal.open({
          templateUrl: 'html/incomingCall.html',
          backdrop: 'static',
          keyboard: false,
          windowClass: 'incomingcall-modal-size',
          resolve: {
            data: function () {
              return data;
            }
          },
          controller: 'IncomingCallModalController'
        });

        // If this modal somehow manages to be closed/dismissed without calling ModalManager.close, ensure the instance variable is cleared to allow re-opening again afterwards
        manager.instances.incomingCall.result.then(function () {
          manager.instances.incomingCall = null;
        }, function () {
          manager.instances.incomingCall = null;
        });
      } else if (modal === 'inCall') {
        if (typeof data === 'undefined') {
          data = {};
        }
        if (typeof data.caller === 'undefined') {
          data.caller = {};
        }
        if (typeof data.call === 'undefined') {
          data.call = {};
        }

        manager.instances.inCall = $modal.open({
          templateUrl: 'html/callScreen.html',
          backdrop: 'static',
          keyboard: false,
          windowClass: 'modal fade in call-screen-modal',
          resolve: {
            data: function () {
              return data;
            }
          },
          controller: 'InCallModalController'
        });

        // If this modal somehow manages to be closed/dismissed without calling ModalManager.close, ensure the instance variable is cleared to allow re-opening again afterwards
        manager.instances.inCall.result.then(function () {
          manager.instances.inCall = null;
        }, function () {
          manager.instances.inCall = null;
        });
      } else if (modal === 'preCall') {
        const patientPresentList = ['present', 'not present'];
        let eventType = EventsService.codeToTypeString(data.category);
        const systems = [];
        const guests = [];
        const offnet = [];

        // let pcvcParticipantContactId = null;
        if (data.participants) {
          data.participants.forEach((participant) => {
            const {
              id,
              contactId,
              name,
              email,
              type,
              isHost,
              isMySystem
            } = participant;

            if (type === 'PCVC' || type === 'LEGACY') {
              systems.push({
                id,
                contactId,
                name,
                isHost,
                isMySystem
              });
            } else if (type === 'GUEST') {
              guests.push({
                name,
                email,
                isHost
              });
            } else if (participant.type === 'OFFNET') {
              offnet.push({
                id,
                contactId,
                name,
                email,
                isHost
              });
            }
          });
        }

        if (data.patientName && data.patientEmail) {
          guests.push({
            name: data.patientName,
            email: data.patientEmail //DIR-1504
          });
        }

        // TODO: Move out to a util
        const toInt = (str) => {
          const int = parseInt(str);
          return isNaN(int) ? 0 : int;
        };

        // TODO: CS1-2673: Looks like the only thing that's really needed here is the systemId.
        // All other fields will be ignored as they're fetched from the Profiles API.
        const systemId = toInt(data.systemId);
        if (systemId) {
          const callee = {
            id: systemId,
            contactId: toInt(data.contactId) || undefined,
            name: data.systemDescription,
            // organization: data.organizationName,
            // type: data.systemType, // "system" or "contact"
          };
          systems.push(callee);
        }

        manager.instances.preCall = $modal.open({
          templateUrl: 'pcvcModalContent.html',
          controller: 'PreCallModalController',
          backdrop: 'static',
          windowClass: 'custom-modal-size pre-call-modal',
          keyboard: 'true',
          resolve: {
            options: function () {
              return {
                calledFrom: data.systemName, // i.e. 'fav-list-icon' or 'event-copy'
                type: EventsService.getTypes(),
                patientPresent: patientPresentList,
                viewMode: data.viewMode
              };
            },
            event: function () {
              return {
                type: eventType,
                followupRequestId: data.followupRequestId,
                numPatients: data.numPatients || 1,
                patients: data.patients,
                patientPresent: (data.isPatientPresent === false) ? patientPresentList[1] : patientPresentList[0], // present
                title: data.eventTitle || 'Clinical Event',
                startTime: data.startTime || null,
                endTime: data.endTime || null,
                hostPin: data.hostPin || null,
                guestPin: data.pin || null,
                eventContact: data.eventContact || null,
                participants: {
                  systems,
                  guests,
                  offnet
                }
              };
            }
          }
        });

        // If this modal somehow manages to be closed/dismissed without calling ModalManager.close, ensure the instance variable is cleared to allow re-opening again afterwards
        manager.instances.preCall.result.then(function () {
          manager.instances.preCall = null;
        }, function () {
          manager.instances.preCall = null;
        });
      } else if (modal === 'preCallConfirm') {
        $log.info('Calling ConfirmationModalService to open preCallConfirm modal...');
        ConfirmationModalService.showPreCallConfirmModal(data);
      } else if (modal === 'joinCall') {
        $log.info('Calling open joinCall modal...');
        manager.instances.joinCall = $modal.open({
          templateUrl: 'html/joinCallModal.html',
          backdrop: 'static',
          keyboard: false,
          windowClass: 'custom-modal-size'
        });
      } else {
        $log.error('Attempted to open modal "' + modal + '" which has not been set up in ModalManager');
      }
    }
    $log.info('manager open end');
  };

  manager.close = function (modal) {
    $log.info('close start');
    if (!manager.instances[modal]) {
      $log.warn('Tried to close modal "' + modal + '", but it was not open');
    } else {
      manager.instances[modal].close();
      manager.instances[modal] = null;
    }
    $log.info('close end');
  };

  $rootScope.$on('closeModal', function (event, modalName) {
    $log.debug('closing modal - ', modalName);
    manager.close(modalName);
  });

  $rootScope.$on('openInCallModal', function (event, data) {
    $log.debug('open modal inCall with data - ', data);
    manager.open('inCall', data);
  });

  return manager;
});
;'use strict';
angular.module('otn.directives.vidyo')
  .factory('PresenceService',
    function ($http, $q, $log, $timeout, AppConfig, User, Systems, otnHubEvents, Notifier, VidyoMessages) {
      function videoReLogin() {
        $log.info('PresenceService: videoReLogin start');

        var endpoint;
        if (User.isIamProtected) {
          endpoint = '/auth/firebase';
        } else {
          endpoint = '/' + AppConfig.services.authentication.internalAddress + '/authentication/oauth2/token/firebase';
        }

        $http({
          method: 'GET',
          url: endpoint,
          headers: {
            Authorization: 'Bearer ' + User.token.accessToken
          }
        }).success(function (data, status, headers, config) {
          $log.debug('Retrieved the firebase token');
          otnHubEvents.register(data.firebaseToken).then(
            function () {
              // successfully registered...do nothing, events will be broadcast 
            },
            function (error) {
              $log.debug('Unable to register with firebase ' + error);
              Notifier.createMessage('error', VidyoMessages.authentication.failedFirebaseToken.message, VidyoMessages.authentication.failedFirebaseToken.solution, error);
            }
          );

        }).error(function (data, status, headers, config) {
          $log.debug('Could not retrieve the firebase token');
          Notifier.createMessage('error', VidyoMessages.authentication.failedFirebaseToken.message, VidyoMessages.authentication.failedFirebaseToken.solution, JSON.stringify(data));
        });
        $log.info('PresenceService: videoReLogin end');
      }

      function fetchRemotePresence(system) {
        $log.debug('PresenceService: fetchRemotePresence, function called with parameter: ', system);
        var deferred = $q.defer();

        if (system.type === 'hcp' || system.type === 'contact' || system.type === 'PCVC') { // User
          getUserGuid(system)
            .then(function (systemGuids) {
              if (systemGuids[0]) {
                otnHubEvents.getPresence(systemGuids[0].otnGuid).then(function (remotePresence) {
                  $log.info('** OtnHubEvents.getPresence successful: ', remotePresence);
                  system.presence = remotePresence.toLowerCase();
                  system.status = 'current-status-' + remotePresence.toLowerCase();
                  deferred.resolve(system);
                });
              } else {
                deferred.resolve(system);
              }
            })
            .catch(function (error) {
              deferred.reject(error);
            });
        } else {
          if (!system.id || system.id === 0) {
            deferred.reject(system);
          }

          Systems.presence({
            id: system.id
          }, function (response) {
            $log.debug('Systems.presence resolved: ', response);
            system.presence = response.status.toLowerCase();
            system.status = 'current-status-' + response.status.toLowerCase();
            system.details = response;
            deferred.resolve(system);
          }, function (rejection) {
            $log.debug('Systems.presence rejected: ', rejection);
            deferred.reject(rejection);
          });
        }

        return deferred.promise;
      }

      var setPresence = function (user, status) {
        $log.debug('PresenceService: setPresence, function called with parameter: ' + status);
        var deferred = $q.defer();

        // FIXME: Call the notification service
        user.presence.status = status;
        deferred.resolve(user);
        return deferred.promise;
      };

      var getUserGuid = function (system) {
        $log.debug('PresenceService: getUserGuids, function called with parameter: ', system);
        if (system === null) {
          return null;
        }
        var deferred = $q.defer();
        $http({
          method: 'GET',
          url: AppConfig.services.users.base + '/?contactids=' + system.contactId,
          headers: {
            Authorization: 'Bearer ' + User.token.accessToken
          }
        }).success(function (response) {
          $log.debug('Users.get guids resolved: ', response);
          deferred.resolve(response);
        }).error(function (rejection) {
          $log.debug('Users.get guids rejected: ', rejection);
          deferred.reject(rejection);
        });
        return deferred.promise;
      };
      var getMyPresence = function () {
        return otnHubEvents.getMyPresence();
      };
      var setMyPresence = function (status) {
        return otnHubEvents.setPresence(status);
      };
      return {
        videoReLogin: function () {
          return videoReLogin();
        },
        fetchRemotePresence: function (system) {
          return fetchRemotePresence(system);
        },
        setPresence: function (user, status) {
          return setPresence(user, status);
        },
        notifyPresenceChange: function () {
          return 'TO BE IMPLEMENTED';
        },
        getMyPresence: function () {
          return getMyPresence();
        },
        setMyPresence: function (status) {
          return setMyPresence(status);
        }
      };
    });
;'use strict';
angular.module('otn.directives.vidyo')
  .factory('RetryService',
    function ($q, $log) {

      var options = {
        maxRetry: 3
      };

      var getOptions = function () {
        return options;
      };

      var setOptions = function (newOptions) {
        $log.debug('RetryService: setMaxRetry, function called: ', newOptions);
        options = newOptions;
      };

      /**
        action: Action is the actual business logic to be executed. This method should return a promise.
        condition: On Action fail retry when the condition is true. This method should return true/false.
        refresh: Method to be executed before retrying the action again. This method should return a promise. 
      */
      function retryWhen(action, condition, refresh) {
        $log.debug('RetryService: retryWhen, function called: ', action, condition, refresh);

        var deferred = $q.defer();

        var resolver = function (remainingTry) {
          $log.debug('RetryService: retryWhen, resolver remainingTry', remainingTry);

          action().then(function (response) {
              $log.debug('RetryService: retryWhen, resolved', remainingTry, response);
              deferred.resolve(response);
            })
            .catch(function (rejection) {
              $log.debug('RetryService: retryWhen, rejection', remainingTry, rejection);
              if (!condition(rejection) || remainingTry <= 1) {
                deferred.reject(rejection);
              } else {
                refresh().then(function (response) {
                    return resolver(remainingTry - 1);
                  })
                  .catch(function (rejection) {
                    deferred.reject(rejection);
                  });
              }
            });
        };

        resolver(options.maxRetry);

        return deferred.promise;
      }

      return {
        getOptions: function () {
          return getOptions();
        },
        setOptions: function (options) {
          return setOptions(options);
        },
        retryWhen: function (action, condition, refresh) {
          return retryWhen(action, condition, refresh);
        }
      };
    });
;/**
 * Created by ymilner on 10/19/2017.
 */
/*jslint latedef:false*/
/**
 * service should include video specific configurations.
 */
(function() {
  'use strict';
  angular.module('otn.directives.vidyo')
    .service('VideoConfigService', videoConfigService);

  videoConfigService.$inject = ['$log', 'AppConfig','HubServices'];

  function videoConfigService($log, AppConfig, HubServices) {
    $log.debug('invoked VideoConfigService');

    var service = {
      pcvcSettingsUrl: AppConfig.links.selfServe.base + HubServices.settingsURL.videoconference
    };
    return service;
  }
})();
;'use strict';
angular.module('otn.directives.vidyo')
  .factory('VideoOverlayService',
    function($http, $q, $log, $timeout, $filter, $rootScope) {

      var isMediaStatsOn = false;
      var isVideoWarnOn = false;
      var messageTimer;
      var messageShownOnce = false;

      function destroy() {
        $log.info('VideoOverlayService: destroy');
        isMediaStatsOn = false;
        isVideoWarnOn = false;
        messageShownOnce = false;
        cancelMessageTimer();
        $rootScope.includeToVideoOverlay = 'views/partials/empty.html';
        $rootScope.includeMessageToVideoOverlay = 'views/partials/empty.html';
      }

      function toggleMediaStatsView() {
        $log.info('VideoOverlayService: toggleMediaStatsView start');
        isMediaStatsOn = !isMediaStatsOn;
        if (isMediaStatsOn) {
          $rootScope.includeToVideoOverlay = 'html/mediaStatsPanel.html';
        }
        else{
          $rootScope.includeToVideoOverlay = 'views/partials/empty.html';
        }
        $rootScope.$broadcast('RealignVideoWindowEvent');
        $log.debug('VideoOverlayService:toggleMediaStatsView: ', isMediaStatsOn);
      }

      function toggleVideoWarningMessage() {
        $log.info('VideoOverlayService: toggleVideoWarningMessage start');
        isVideoWarnOn = !isVideoWarnOn;
        updateVideoWarningMessage(isVideoWarnOn);
        $log.debug('VideoOverlayService:toggleVideoWarningMessage: ', isVideoWarnOn);
      }

      function closeVideoWarningMessage() {
        $log.info('VideoOverlayService: closeVideoWarningMessage start');
        isVideoWarnOn = false;
        updateVideoWarningMessage(isVideoWarnOn);
        $log.debug('VideoOverlayService:closeVideoWarningMessage: ', isVideoWarnOn);
      }

      function showVideoWarningMessage() {
        $log.info('VideoOverlayService: showVideoWarningMessage start');
        if (messageShownOnce) {
          return;
        }
        isVideoWarnOn = true;
        messageShownOnce = true;
        updateVideoWarningMessage(isVideoWarnOn);
        $log.debug('VideoOverlayService:showVideoWarningMessage: ', isVideoWarnOn);
      }

      function updateVideoWarningMessage(state) {
        if (state) {
          $rootScope.includeMessageToVideoOverlay = 'html/videoQualityWarning.html';
          messageTimer = $timeout(function() {
            isVideoWarnOn = false;
            updateVideoWarningMessage(isVideoWarnOn);
          }, 5000);
        }
        else{
          $rootScope.includeMessageToVideoOverlay = 'views/partials/empty.html';
          cancelMessageTimer();
        }
        $rootScope.$broadcast('RealignVideoWindowEvent');
      }

      function cancelMessageTimer() {
        if (angular.isDefined(messageTimer)) {
          $timeout.cancel(messageTimer);
          messageTimer = undefined;
        }
      }

      $rootScope.$on("InCallModalControllerDestroyed", function() {
        $log.debug('VideoOverlayService: on InCallModalControllerDestroyed');
        destroy();
      });

      return {
        toggleMediaStatsView: function() {
          return toggleMediaStatsView();
        },
        toggleVideoWarningMessage: function() {
          return toggleVideoWarningMessage();
        },
        closeVideoWarningMessage: function() {
          return closeVideoWarningMessage();
        },
        showVideoWarningMessage: function() {
          return showVideoWarningMessage();
        }
      };
    });
;'use strict';
angular
  .module('otn.directives.vidyo')
  .service(
    'VideoService',
    function ($rootScope, $log, $sce, $cookies, $location, $timeout, $window, otnVideoComp, VidyoMessages, Notifier, AppConfig, Logging) {
      $log.info('VideoService init');

      var videoService = this;
      videoService.plugin = null;
      videoService.pluginInitialized = false;
      videoService.pluginReady = false;
      var SEEN_MESSAGE_IE_EDGE_LIMITED_SUPPORT = 'otn-msg-ie-edge-limited-support';
      var SEEN_MESSAGE_IE_EDGE_LIMITED_SUPPORT_VALUE = 'true';
      var SEEN_MESSAGE_IOS_LIMITED_SUPPORT = 'otn-msg-ios-limited-support';
      var SEEN_MESSAGE_IOS_LIMITED_SUPPORT_VALUE = 'true';
      var SEEN_MESSAGE_ANDROID_LIMITED_SUPPORT = 'otn-msg-android-limited-support';
      var SEEN_MESSAGE_ANDROID_LIMITED_SUPPORT_VALUE = 'true';

      var getFirefoxVersion = function () {
        //Firefox 51 useragent: 'Mozilla/5.0 (Windows NT 10.0; WOW64; rv:51.0) Gecko/20100101 Firefox/51.0'
        var firefoxVersion = $window.navigator.userAgent.match(/Firefox\/(\d+)\./);
        if (firefoxVersion && firefoxVersion[1]) {
          return parseInt(firefoxVersion[1], 10);
        }
        return null;
      };

      videoService.config = {
        videoContainerId: 'pluginContainer',
        domContainerClass: 'pluginContainer',
        pexipVideoFlash: 'bower_components/otn.components.video/dist/PexVideo.swf',
        flashInstallUrl: 'https://get.adobe.com/flashplayer/',
        chromeInstallUrl: 'https://www.google.com/intl/en_ca/chrome',
        firefoxInstallUrl: 'https://www.mozilla.org/en-CA/firefox?',
        iosAppInstallUrl: 'https://itunes.apple.com/ca/app/otnconnect/id965222852',
        offScreenPosition: {
          top: '1px',
          left: '1px',
          width: '1px',
          height: '1px'
        },
        containerIds: [],
        logLevels: {
          default: 'fatal error warning info@App info@AppEvents info@AppEmcpClient info@LmiApp info@LmiH264SvcPace',
          debug: 'fatal error warning info@App info@AppEvents info@AppEmcpClient info@LmiApp info@LmiH264SvcPace debug@AppVcsoapClient debug@App',
          extendedDebug: 'fatal error warning info@App info@AppEvents info@AppEmcpClient info@LmiApp info@LmiH264SvcPace debug@AppVcsoapClient debug@App all@AppVcsoapClient all@AppVcsoapClient',
          renderingAndCapturingIssues: 'ALL@App ALL@AppVcsoapClient ALL@LmiApp ALL@LmiUi',
          networkErrorsOnWindows: 'ALL@App ALL@AppVcsoapClient ALL@LmiWinHttp ALL@AppEvents ALL@AppEmcpClient ALL@LmiCsEpClient ALL@LmiTransport ALL@LmiTransportManager',
          networkErrorsOnMacLinuxiOSAndroid: 'ALL@App ALL@AppVcsoapClient ALL@LmiCurlHttp ALL@AppEvents ALL@AppEmcpClient ALL@LmiCsEpClient ALL@LmiTransport ALL@LmiTransportManager',
          performanceIssues: 'ALL@App ALL@LmiApp ALL@AppEvents ALL@AppStats',
          customVidyo: 'ALL@App ALL@AppEvents ALL@AppVcsoapClient ALL@AppGui ALL@LmiCmcpSignaling ALL@LmiXmlParser ALL@LmiXsdParser',
          customVidyo2: 'ALL@AppEvents ALL@App ALL@AppVcsoapClient ALL@AppGui  ALL@LmiCsConfClient',
          current: null
        }
      };

      //CS1-1289 - Get sub domain to save that user seen the banner message so it works cross domain
      var getDomain = function () {
        var host = $location.host();
        if (host.indexOf('.') > 0) {
          return host.substring(host.indexOf('.'));
        }
        return host;
      };

      videoService.initializePlugin = function () {
        $log.info('initializePlugin start: ', videoService.pluginInitialized);
        if (!videoService.pluginInitialized) {
          var solution;
          if (!videoService.isPlatformSupported()) {
            solution = VidyoMessages.plugin.installation.unsupportedPlatform.message;
            solution = solution.replace('@@PRIVACY_CASL@@',
              manager.config.privacyCasl);
            Notifier
              .createMessage(
                'warning',
                $sce
                .trustAsHtml(VidyoMessages.plugin.installation.required.message +
                  ' ' + solution), $sce.trustAsHtml(solution));
            return;
          }

          if (!videoService.isBrowserSupported()) {
            solution = VidyoMessages.plugin.installation.unsupportedBrowser.solution;
            solution = solution.replace('@@PRIVACY_CASL@@',
              manager.config.privacyCasl);
            Notifier
              .createMessage(
                'warning',
                $sce
                .trustAsHtml(VidyoMessages.plugin.installation.unsupportedBrowser.message +
                  ' ' + solution), $sce.trustAsHtml(solution));
            return;
          }

          if ($cookies.get(SEEN_MESSAGE_IE_EDGE_LIMITED_SUPPORT) !== SEEN_MESSAGE_IE_EDGE_LIMITED_SUPPORT_VALUE && (videoService.isEdge() || videoService.isUsingFlash())) {
            //CS1-1289 - Show a banner message every time an IE & Edge user logs into the hub
            var message = videoService.isEdge() ?
              VidyoMessages.browserSupport.msEdgeAndIELimitedSupport.message :
              VidyoMessages.browserSupport.msIENoSupport.message;
            Notifier
              .createMessage(
                'warning', $sce.trustAsHtml(
                  message.replace('@@CHROME_INSTALL_URL@@', videoService.config.chromeInstallUrl).replace('@@FIREFOX_INSTALL_URL@@', videoService.config.firefoxInstallUrl)));
            $cookies.put(SEEN_MESSAGE_IE_EDGE_LIMITED_SUPPORT, SEEN_MESSAGE_IE_EDGE_LIMITED_SUPPORT_VALUE, {
              domain: getDomain()
            });
          } else if ($cookies.get(SEEN_MESSAGE_IOS_LIMITED_SUPPORT) !== SEEN_MESSAGE_IOS_LIMITED_SUPPORT_VALUE && videoService.isIos()) {
            //CS1-1590: iOS
            Notifier
              .createMessage(
                'warning',
                $sce.trustAsHtml(VidyoMessages.browserSupport.iosNoVideoSupport.message.replace('@@IOS_INSTALL_URL@@', videoService.config.iosAppInstallUrl)));
            $cookies.put(SEEN_MESSAGE_IOS_LIMITED_SUPPORT, SEEN_MESSAGE_IOS_LIMITED_SUPPORT_VALUE, {
              domain: getDomain()
            });
          } else if ($cookies.get(SEEN_MESSAGE_ANDROID_LIMITED_SUPPORT) !== SEEN_MESSAGE_ANDROID_LIMITED_SUPPORT_VALUE && videoService.isAndroid()) {
            //CS1-1590: Android
            Notifier.createMessage('warning', $sce.trustAsHtml(VidyoMessages.browserSupport.androidNoVideoSupport.message));
            $cookies.put(SEEN_MESSAGE_ANDROID_LIMITED_SUPPORT, SEEN_MESSAGE_ANDROID_LIMITED_SUPPORT_VALUE, {
              domain: getDomain()
            });
          }

          //CS1-1289 - Remove from the session that  a banner message has been shown to IE & Edge user
          $rootScope.$on('logout', function (event) {
            $cookies.remove(SEEN_MESSAGE_IE_EDGE_LIMITED_SUPPORT, {
              domain: getDomain()
            });
            $cookies.remove(SEEN_MESSAGE_IOS_LIMITED_SUPPORT, {
              domain: getDomain()
            });
            $cookies.remove(SEEN_MESSAGE_ANDROID_LIMITED_SUPPORT, {
              domain: getDomain()
            });
          });

          videoService.pluginInitialized = true;
          $timeout(function () {
            $rootScope.$broadcast('pluginInitialized');
          });
        }
        $log.info('initializePlugin end: ', videoService.pluginInitialized);
      };

      // Note: This doesn't return anything or trigger any callbacks. It only broadcasts a success on $rootScope
      videoService.loadPlugin = function (attempts = 1) {
        $log.info('loadPlugin start: ', videoService.pluginInitialized, videoService.pluginReady);

        if (videoService.pluginInitialized && !videoService.pluginReady) {
          otnVideoComp.setPexVideoFlashUrl(videoService.config.pexipVideoFlash);
          otnVideoComp.setApiRelativePath(false);
          otnVideoComp
            .init({
                videoContainerId: videoService.config.videoContainerId,
                apiUrl: AppConfig.services.virtualvisit.base
              },
              function () {
                if (attempts > 1) {
                  // Video restored message if previous attempt failed
                  Notifier.createMessage(
                    'information',
                    VidyoMessages.plugin.initialization.pexrtcNotLoadingRetrySuccess.message
                  );
                }

                $log.info('Plugin installed, loaded, and started successfully');
                videoService.plugin = otnVideoComp;
                videoService.pluginReady = true;
                $rootScope.$broadcast('pluginReady');
              },
              function (err) {
                $log.error('videoService.loadPlugin: Error:', err);
                Logging.sendLog({
                  level: 'error',
                  app: 'vidyo',
                  module: 'VideoService',
                  message: 'loadPlugin: Attempt ' + attempts + ', Error: ' + JSON.stringify(err)
                });

                if (videoService.isInternetExplorer()) {
                  // Inline warning already shown
                } else if (otnVideoComp.isUsingFlash() && !otnVideoComp.isFlashInstalled()) {
                  Notifier.createMessage(
                    'warning',
                    VidyoMessages.plugin.initialization.noFlashFound.message +
                    ' ' +
                    VidyoMessages.plugin.initialization.noFlashFound.solution.replace('@@ADOBE_FLASH_INSTALL_URL@@', videoService.config.flashInstallUrl)
                  );
                } else if (err && typeof err === 'string' && err.indexOf('pexrtc.js') >= 0) {
                  // err is: "Failed to load script at URL: https://awspexdevcn.otnvideo.ca/static/webrtc/js/pexrtc.js"
                  if (attempts === 1) {
                    // Please wait message (during retry loop)
                    Notifier.createMessage(
                      'warning',
                      VidyoMessages.plugin.initialization.pexrtcNotLoadingRetryLoop.message +
                      ' ' +
                      VidyoMessages.plugin.initialization.pexrtcNotLoadingRetryLoop.solution
                    );
                  } else if (attempts === 3) {
                    // Final error message (retry loop failed)
                    var message = VidyoMessages.plugin.initialization.pexrtcNotLoading.message +
                      ' ' +
                      VidyoMessages.plugin.initialization.pexrtcNotLoading.solution;
                    message = message.replace('<CUSTOMER_SUPPORT_HELP_LINE>', AppConfig.links.help.customerSupportHelpLine);

                    Notifier.createMessage(
                      'warning',
                      message,
                      '',
                      JSON.stringify(err)
                    );
                  }

                  // hotfix/CS1-2881 - retry logic in case of DNS failure
                  if (attempts < 3) {
                    setTimeout(() => videoService.loadPlugin(attempts + 1), 1000);
                  }
                } else if (err && typeof err === 'string' && err.indexOf('camera/microphone') >= 0) {
                  $log.warn('Cannot use video functionality. ' + VidyoMessages.plugin.initialization.noCamerasOrMicsFound.message);
                  Notifier.createMessage(
                    'warning',
                    VidyoMessages.plugin.initialization.noCamerasOrMicsFound.message +
                    ' ' +
                    VidyoMessages.plugin.initialization.noCamerasOrMicsFound.solution
                  );
                } else {
                  Notifier.createMessage(
                    'warning',
                    VidyoMessages.plugin.initialization.failed.message +
                    ' ' +
                    VidyoMessages.plugin.initialization.failed.solution,
                    '',
                    JSON.stringify(err)
                  );
                }
                // TODO: This is a bandaid fix to the messages not appearing right away. Investigate the root cause.
                $rootScope.$apply();
              });
        }
        $log.info('loadPlugin end: ', videoService.pluginInitialized, videoService.pluginReady);
      };

      videoService.isAudioEnabled = function () {
        $log.debug('isAudioEnabled');
        var audio = new Audio();
        return audio && audio.muted === false;
      };

      videoService.isPlatformSupported = function () {
        $log.debug('isPlatformSupported(); platform=' + $window.navigator.platform);
        var res = true;
        // FIXME: For now all are supported. Discussed with Yakov a new task will be created 
        //        and BA support will be needed for various error messages.
        return res;
      };

      videoService.isBrowserSupported = function () {
        $log.debug('isBrowserSupported(); userAgent=' + $window.navigator.userAgent);
        var res = true;
        // FIXME: For now all are supported. Discussed with Yakov a new task will be created 
        //        and BA support will be needed for various error messages.
        return res;
      };

      //TODO: All browser detection code below is copied from "video-api". Ideally it should be reused.

      videoService.isChrome = function () {
        if (this.isEdge()) {
          //MS Edge useragent contains the words "Chrome", "Mozilla" and "Safari"
          return false;
        }
        if ((navigator.appVersion.indexOf('Win') !== -1 && navigator.userAgent.toLowerCase().indexOf('chrome') !== -1) ||
          (navigator.appVersion.indexOf('Mac') !== -1 && navigator.userAgent.toLowerCase().indexOf('chrome') !== -1 &&
            navigator.userAgent.substr(navigator.userAgent.lastIndexOf('Chrome/') + 7, 10).split('.')[0] >= 42)) {
          return true;
        } else {
          return false;
        }
      };

      videoService.isFirefox = function () {
        if (this.isEdge()) {
          //MS Edge useragent contains the words "Chrome", "Mozilla" and "Safari"
          return false;
        }
        return getFirefoxVersion() !== null;
      };

      videoService.isInternetExplorer = function () {
        return $window.navigator.userAgent.indexOf('MSIE') > -1 || $window.navigator.userAgent.indexOf('Trident') > -1;
      };

      videoService.isEdge = function () {
        var res = $window.navigator.userAgent.match(/Edge\/\d+/);
        return res !== null;
      };

      videoService.isSafari = function () {
        if (this.isEdge()) {
          //MS Edge useragent contains the words "Chrome", "Mozilla" and "Safari"
          return false;
        }
        return (!videoService.isChrome() && !this.isAndroid() && $window.navigator.userAgent.indexOf('Safari') > -1);
      };

      videoService.isSafari11OrGreater = function () {
        if (this.isEdge()) {
          //MS Edge useragent contains the words "Chrome", "Mozilla" and "Safari"
          return false;
        }
        if (!this.isChrome() && !this.isIos() && !this.isAndroid() && $window.navigator.userAgent.indexOf('Safari') > -1) {
          try {
            var SafVersion = parseInt($window.navigator.userAgent.match(/version\/(\d+)/i)[1]);
            return SafVersion >= 11;
          } catch (e) {
            $log.error('Safari on Desktop incorrectly identified. UserAgent String: ' + $window.navigator.userAgent);
            return false;
          }
        } else {
          return false;
        }
      };

      videoService.isIos = function () {
        return $window.navigator.userAgent.match(/iPhone|iPad|iPod/i) ? true : false;
      };

      videoService.isAndroid = function () {
        return $window.navigator.userAgent.match(/Android/i) ? true : false;
      };

      videoService.isUsingFlash = function () {
        return videoService.isInternetExplorer() || (videoService.isSafari() && !videoService.isSafari11OrGreater() && !videoService.isIos());
      };

      videoService.isFlashInstalled = function () {
        return otnVideoComp.isFlashInstalled();
      };

    });
;'use strict';
angular.module('otn.directives.vidyo').service('VidyoSupportService', function ($log, $window, proxyWrapper) {

  // Firefox 52+ does not support NPAPI plugins and is therefore not supported. Unfortunately there's no way to feature detect NPAPI support, only by browser version.
  var config = {
    firefox: {
      maxSupportedVersion: 53
    }
  };

  var getFirefoxVersion = function () {
    //Firefox 51 useragent: 'Mozilla/5.0 (Windows NT 10.0; WOW64; rv:51.0) Gecko/20100101 Firefox/51.0'
    var firefoxVersion = $window.navigator.userAgent.match(/Firefox\/(\d+)\./);
    if (firefoxVersion && firefoxVersion[1]) {
      return parseInt(firefoxVersion[1], 10);
    }
    return null;
  };

  var isEdge = function () {
    var res = $window.navigator.userAgent.match(/Edge\/\d+/);
    return res !== null;
  };

  var isChrome = function () {
    return proxyWrapper.isChrome;
  };

  var isSafari12OrLater = function () {
    //Safari 12 Useragent: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_6) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/12.0 Safari/605.1.15"
    var userAgent = $window.navigator.userAgent;
    if (isEdge() || userAgent.indexOf('Safari') < 0) {
      return false;
    }

    var version = $window.navigator.userAgent.match(/Version\/(\d+)\./);
    if (version && version[1]) {
      var versionAsInt = parseInt(version[1], 10);
      return versionAsInt >= 12;
    }

    return false;
  };

  return {
    isBrowserSupported: function () {
      $log.debug('isBrowserSupported(); userAgent=' + $window.navigator.userAgent);
      //PCVC-1261: Removed Chrome support
      if (isEdge()) {
        return false;
      }
      var firefoxVersion = getFirefoxVersion();
      if (firefoxVersion && firefoxVersion > config.firefox.maxSupportedVersion) {
        $log.debug('isBrowserSupported(); Firefox version greater than max supported version.');
        return false;
      }
      if (isSafari12OrLater()) {
        return false;
      }
      return true;
    },

    isChrome: function () {
      return isChrome();
    },

    isFirefox: function () {
      return getFirefoxVersion() !== null;
    },

    isSafari12OrLater: function () {
      return isSafari12OrLater();
    },

    isPlatformSupported: function () {
      var res = true;
      if ($window.navigator.platform !== 'MacIntel' && $window.navigator.platform !== 'Win32' && $window.navigator.platform !== 'Win64') {
        res = false;
      }
      return res;
    }
  };

});
;/**
 * Created by ymilner on 11/08/2019.
 */
/*jslint latedef:false*/
/**
 * The service will include all common functions and common events related to events domain (events list/details etc)
 */
(function() {
  'use strict';
  angular.module('otn.directives.vidyo')
    .service('EventsService', EventsService);

  EventsService.$inject = ['$log', '$q', '$rootScope', '$window', 'AppConfig', 'User', 'VirtualVisits'];

  const participantTypes = {
    LEGACY: 'LEGACY',
    PCVC: 'PCVC',
    GUEST: 'GUEST',
    OFFNET: 'OFFNET'
  };

  function EventsService($log, $q, $rootScope, $window, AppConfig, User, VirtualVisits) {
    $log.debug('invoked EventsService');
    var pinDigits = 6;

    $rootScope.$on('event_created', function(angEvent, newEventData) {
      $log.debug('invoked EventsService:event_created %s', JSON.stringify(newEventData));
      if (newEventData.viewHandout === true) {
        navigateToPatientLetter(newEventData.eventId);
      }
    });

    function getHostCount (event) {
      var count = 0;
      angular.forEach(event.videoProviderParticipants, function (participant) {
        if (participant.role === 'chair') {
          count++;
        }
      });
      return count;
    }

    const getMemberParticipants = (event) => (
      event.participants.filter((participant) => (participant.type === 'PCVC' || participant.type === 'LEGACY'))
    );

    const getGuestParticipants = (event) => (
      event.participants.filter((participant) => (participant.type === 'GUEST'))
    );

    const getGuestCount = (event) => (
      getGuestParticipants(event).length
    );

    const getOffnetCount = (event) => (
      event.participants.filter((participant) => (participant.type === 'OFFNET')).length
    );

    const hasNonMemberParticipants = (event) => (
      (getGuestCount(event) > 0) || (getOffnetCount(event) > 0)
    );

    const isClinical = (event) => (event.category === 'CLINICAL' || event.category === 'CLINIC');

    /**
     * Returns true if the given event has one or more guest participants.
     *
     * @param event
     * @returns {boolean}
     */
    const isGuestLink = (event) => {
      if (!event || !event.participants) {
        return false;
      }
      return getGuestCount(event) > 0;
    }

    const isDelegatorEvent = (event) => (
      event.participants.filter((participant) => (participant.isMySystem === true)).length === 0
    );

    const getMySystem = (event) => {
      const mySystems = event.participants.filter((participant) => (participant.isMySystem));
      return (mySystems.length > 0) ? mySystems[0] : null;
    };

    const getHostSystem = (event) => {
      const hostSystems = event.participants.filter((participant) => (participant.isHost));
      return (hostSystems.length > 0) ? hostSystems[0] : null;
    };

    const getNonHostSystems = (event) => (
      event.participants.filter((participant) => (!participant.isHost))
    );

    const getConsultantSystem = (event) => {
      const consultantSystems = event.participants.filter((participant) => (participant.isConsultantSystem));
      return (consultantSystems.length > 0) ? consultantSystems[0] : null;
    };

    function isAdHoc (event) {
      return event && event.eventSource === 'ADHOC';
    }

    /**
     * Returns true if the given event should be considered as "multipoint", false otherwise.
     *
     * This logic was moved from Directory: EventList Controller.
     *
     * @param event
     * @returns {boolean}
     */
    function isMultipoint (event) {
      if (!event) {
        return false;
      }
      var isMultipoint = event.eventSource !== 'GUEST_LINK' && (event.sessionCode === 'MULTIPOINT' || ((event.participants.length || 0) > 2));
      if (isMultipoint && event.category === 'CLINIC' && !event.isGroup && ((event.participants.length || 0) === 2)) {
        //CS1-2563: Ncompass serial clinic event has session code MULTIPOINT even though its P2P call
        isMultipoint = false;
      }
      return isMultipoint;
    }

    function navigateToPatientLetter(eventId) {
      $log.debug('EventsService: navigateToPatientLetter id:%s', eventId);
      $window.open(AppConfig.links.directory.base + '/#/events/' + eventId + '/handout', '_blank');
    }

    const isVvrInProgress = (event) => (
      event.vvr && event.vvr.vvrData && event.vvr.vvrData.state === 'InProgress'
    );

    /**
     * Fetches event participant counts for a list of events, and broadcasts an event on $rootScope with the result.
     * Broadcasts an array of objects such as: List of objects such as {requestId: 29761209, isWaiting: false, hosts: 0,
     * guests: 0}
     *
     * @param eventIds A list of event.requestId values to fetch the participants status for.
     */
    function fetchEventListParticipants(eventIds) {
      if (!eventIds || eventIds.length === 0) {
        return;
      }
      VirtualVisits.getProviderParticipantsStatus({
        tsmIds: eventIds
      }, function(results) {
        $rootScope.$broadcast('event_list_update_participants', results);
      });
    }

    /**
     * Generate a random pin (to be used for guest/host)
     * @param differentFrom A pin that the new pin must be different from.
     * @returns {string}
     */
    function generateRandomPin(differentFrom) {
      var pinStr = Math.floor(Math.random() * Math.pow(10, pinDigits)).toString();
      while (pinStr.length < pinDigits) {
        pinStr = '0' + pinStr;
      }
      if (differentFrom && pinStr === differentFrom) {
        return generateRandomPin(differentFrom);
      }
      return pinStr;
    }

    function normalizeEventTitle(eventTitle) {
      const trimmedTitle = eventTitle ? eventTitle.trim() : '';
      return (trimmedTitle.length > 0) ? trimmedTitle : null;
    }

    const eventTypes = {
      'CLINICAL': 'Clinical event',
      'LEARNING': 'Learning event',
      'MEETING': 'Meeting'
    };

    const eventTypesToConsultantRole = {
      'CLINICAL': 'Consultant',
      'LEARNING': 'Speaker',
      'MEETING': 'Chair'
    };

    /**
     * Converts event type string (i.e. 'Clinical Event') to code (i.e. 'CLINICAL')
     * @param typeStr
     * @returns {string}
     */
    function typeStringToCode(typeStr) {
      for (const [code, string] of Object.entries(eventTypes)) {
        if (string === typeStr) {
          return code;
        }
      }
      return '';
    }

    const getTypes = () => (Object.values(eventTypes));

    /**
     * Opposite of typeStringToCode - converts code (i.e. LEARNING) to type string (i.e. 'learning event')
     * @param code
     */
    function codeToTypeString(code) {
      return eventTypes[code];
    }

    const eventTypeToConsultantRole = (eventTypeCode) => (
      eventTypesToConsultantRole[eventTypeCode]
    );

    const eventTypeToHostRole = (eventTypeCode) => (
      (eventTypeCode === 'CLINIC' || eventTypeCode === 'CLINICAL') ? 'Consultant system' : 'Host system'
    );

    const getEventCategoryLabel = function (event) {
      var output = '';
      if (event.category === 'CLINIC' || event.category === 'CLINICAL') {
        output = 'Clinical event';
      } else if (event.category === 'LEARNING') {
        output = 'Learning event';
      } else if (event.category === 'MEETING') {
        output = 'Meeting';
      }
      return output;
    };

    // TODO: Copied from PreCallModalController. Take them both out to a common util.
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/parseInt
    function filterInt(value) {
      if (/^(\-|\+)?([0-9]+|Infinity)$/.test(value)) {
        return Number(value);
      }
      return 0;
    }

    /**
     * When a new event is created, this is called to build an "event details" object to be sent to the MT. Essentially
     * this converts the event properties as they are entered into the form, to the object expected by the MT.
     *
     * Participant array entries will have a "type" field set to a value from participantTypes
     *
     * @param props Object containing all properties required for the event to be built
     * @returns {{title: any, category: string, eventSource: *, participants: Array, isCheckSystemsConflict: boolean}}
     */
    function packageEventDetails({
      title,
      type,
      followupRequestId,
      patientPresent,
      numPatients,
      eventContact,
      participants,
      hostPin,
      guestPin,
      isGuestPinRequired,
      schedule,
      patients,
      pcvcConflictOptOut
    }) {
      const { startTime, endTime } = schedule || {};

      const eventDetails = {
        title: normalizeEventTitle(title),
        category: typeStringToCode(type),
        participants: [],
        patients: [],
        isCheckSystemsConflict: pcvcConflictOptOut ? 'PCVC_OPTOUT' : 'TRUE', // toggles whether the MT checks system schedule conflicts
      };

      if (followupRequestId) {
        eventDetails.followupRequestId = followupRequestId;
      }

      /*
      The field "eventSource" is a legacy field that some of the logic still expects (i.e. event list, event details).
      Initialize it based on the event details.
      - Old Values: GUEST_LINK_SCHEDULED / GUEST_LINK / ADHOC
      - New Values: GUEST_LINK_SCHEDULED (all scheduled events) / ADHOC (all adhoc events)

      TODO: CS1-2673: Look for and refactor out all occurrences of the old GUEST_LINK type. See:
      - ConfirmationModalService.showPreCallConfirmModal
       */
      const isScheduledEvent = !!(startTime && endTime);
      if (isScheduledEvent) {
        eventDetails.eventSource = 'GUEST_LINK_SCHEDULED';
      } else {
        eventDetails.eventSource = 'ADHOC';
      }

      const hasGuestParticipants = (participants.guests && participants.guests.length > 0);
      const hasOffnetParticipants = (participants.offnet && participants.offnet.length > 0);
      const hasNonMemberParticipants = hasGuestParticipants || hasOffnetParticipants;

      if (hasGuestParticipants) {
        participants.guests.forEach((guest) => {
          const { name, email, isHost, isPHIConsent } = guest;
          eventDetails.participants.push({
            name,
            email,
            isHost,
            type: participantTypes.GUEST,
            isPHIConsent
          });
        });
      }

      if (hasOffnetParticipants) {
        participants.offnet.forEach((offnetParticipant) => {
          const { name, email, isHost, contactId } = offnetParticipant;
          eventDetails.participants.push({
            name,
            email,
            contactId,
            isHost,
            type: participantTypes.OFFNET
          });
        });
      }

      if (hasNonMemberParticipants) {
        eventDetails.hostPin = hostPin;
        if (isGuestPinRequired === true) {
          eventDetails.pin = guestPin;
        }
      }

      if (participants.systems) {
        participants.systems.forEach((systemParticipant) => {
          const { id, contactId, name, isMySystem, isHost, type } = systemParticipant;
          const { LEGACY, PCVC } = participantTypes;

          // We don't have the system id for my system. Hence we send "isMySystem". The MT has logic to look it up.
          let participant = isMySystem ? {
            isMySystem
          } : {
            id
          };

          participant = {
            ...participant,
            name,
            isHost,
            type: (type === 'site') ? LEGACY : PCVC,
            contactId: contactId || undefined
          };

          eventDetails.participants.push(participant);
        });
      }

      if (isScheduledEvent) {
        eventDetails.startTime = startTime;
        eventDetails.endTime = endTime;
      }

      if (isClinical(eventDetails)) {
        // Convention: Only one participant holds the numPatients value. This can be any non-host participant.
        let participantWithNumPatients;
        eventDetails.participants.forEach((participant) => {
          if (!participant.isHost) {
            participantWithNumPatients = participant;
          }
        });
        if (participantWithNumPatients) {
          participantWithNumPatients.numOfPatients = filterInt(numPatients);
        }
        eventDetails.isPatientPresent = patientPresent === 'present';
      }

      // Always set eventContact. For clinical it's the consultant, for non-clinical it's the presenter
      if (eventContact && eventContact.id) {
        eventDetails.eventContact = {
          id: eventContact.id, // this is the contact id
          userId: eventContact.userId,
          name: eventContact.name
        };
      }

      if (patients) {
        eventDetails.patients = patients;
      }

      return eventDetails;
    }

    function init() {
      $log.debug('invoked EventsService:init');
    }

    return {
      getHostCount,
      getGuestCount,
      getOffnetCount,
      hasNonMemberParticipants,
      isClinical,
      isGuestLink,
      isDelegatorEvent,
      getMySystem,
      getHostSystem,
      getNonHostSystems,
      getConsultantSystem,
      getMemberParticipants,
      getGuestParticipants,
      isAdHoc,
      isMultipoint,
      isVvrInProgress,
      navigateToPatientLetter,
      fetchEventListParticipants,
      generateRandomPin,
      typeStringToCode,
      getTypes,
      codeToTypeString,
      eventTypeToConsultantRole,
      eventTypeToHostRole,
      getEventCategoryLabel,
      packageEventDetails,
      init
    };
  }
})();
;/*jslint latedef:false*/
/**
 * This will include all common code related to the Favourites domain.
 *
 * TODO: CS1-2673: This will likely be a temporary service. Once all favourites-related code is refactored out of
 * controllers, it should be moved to the FavouritesServiceV2 in otn.components.favourites so it can be used by other
 * apps as well.
 *
 * TODO: CS1-2673: Note also that there is a FavouritesService in directory (Search for
 * "app.factory('FavouritesService'") that needs to be refactored out to the above common service.
 */
(function() {
  'use strict';
  angular.module('otn.directives.vidyo')
    .service('FavouritesServiceV3', FavouritesServiceV3);

  FavouritesServiceV3.$inject = ['$log', '$q', 'User', 'Favourites', 'FavouritesServiceV2'];

  function FavouritesServiceV3($log, $q, User, Favourites, FavouritesServiceV2) {
    var service = {
      isFavouriteSystem: isFavouriteSystem,
      setFavouriteSystem: setFavouriteSystem,
      toggleSystemFavourite: toggleSystemFavourite,
      init: init
    };

    return service;

    /**
     * TODO: CS1-2673: A similar function already exists in ActionUtils ('otn.services.user'). Think about
     * combining/reusing.
     *
     * @param system
     * @returns {*}
     */
    function getFavouriteId(system) {
      var id = null;
      var systemId = system.id ? Number(system.id) : null;
      var systemContactId = system.contactId ? Number(system.contactId) : null;
      angular.forEach(User.favourites, function (favourite) {
        if ((favourite.category === 'SYSTEM' && favourite.categoryId === systemId) ||
          (favourite.category === 'CONTACT' && favourite.categoryId === systemContactId)){
          id = favourite.favouriteId;
        }
      });
      return id;
    }

    function isFavouriteSystem(system) {
      return (getFavouriteId(system) !== null);
    }

    function setFavouriteSystem(system) {
      var newFavourite = {};
      if ((system.type === 'hcp') || (system.type === 'contact')) {
        newFavourite.category = 'CONTACT';
        newFavourite.categoryId = String(system.contactId);
      } else {
        newFavourite.category = 'SYSTEM';
        newFavourite.categoryId = String(system.id);
      }

      FavouritesServiceV2.create(newFavourite).$promise.then(function success(data) {
        Favourites.list(function (favourites) {
          User.actions.resolveFavourites(favourites);
        });
      });
    }

    function deleteFavourite(favouriteId) {
      FavouritesServiceV2.delete(favouriteId).$promise.then(function success(data) {
        Favourites.list(function (favourites) {
          User.actions.resolveFavourites(favourites);
        });
      });
    }

    function toggleSystemFavourite(system) {
      var favId = getFavouriteId(system);
      if (favId) {
        deleteFavourite(favId);
      } else {
        setFavouriteSystem(system);
      }
    }

    function init() {
      $log.debug('invoked FavouritesServiceV3:init');
    }
  }
})();
;/*jshint latedef: nofunc */
(function() {
  'use strict';

  angular
    .module('otn.directives.vidyo')
    .service('GuestParticipantService', GuestParticipantService);

  GuestParticipantService.$inject = ['$log', '$modal', '$q', '$rootScope', '$timeout', 'Events'];
  function GuestParticipantService($log, $modal, $q, $rootScope, $timeout, Events) {
    var sName = 'GuestParticipantService';
    $log.debug('%s: init', sName);
    var currEventId = null;
    var currParticipantId = null;
    var currParticipantName = null;
    var currParticipantOldEmail = null;
    var service = {
      open: open,
      dismiss: dismiss
    };
    return service;

    ////////////IMPLEMENTATION//////////////////
    function open(eventId, participantId, 
      participantName, participantOldEmail, isPHIConsent, eventCategory) {
      $log.debug('%s: open, eventId: %s, participantId: %s, participantName: %s, participantOldEmail: %s, isPHIConsent: %s', 
        sName, eventId, participantId, participantName, participantOldEmail, isPHIConsent);
        currEventId = eventId;
        currParticipantId = participantId;
        currParticipantName = participantName;
        currParticipantOldEmail = participantOldEmail;
      var modalInstance = $modal.open({
        templateUrl: 'html/guest-participant.view.html',
        controller: 'GuestParticipantController',
        resolve: {
          guestSettings: function() {
            return {
              id: eventId,
              name: participantName, 
              email: participantOldEmail,
              oldEmail: participantOldEmail,
              isPHIConsent: isPHIConsent,
              eventCategory: eventCategory
            };
          }
        },
        backdrop: 'static',
        keyboard: 'true'
      });
      modalInstance.result.then(formSubmitted, formDismissed);
    }

    function dismiss() {
      $log.debug('%s: dismiss', sName);
    }

    function formSubmitted(data) {
      $log.debug('%s: formSubmitted, data: %s', sName, data);
      
      Events.updateGuestParticipant({
        id: data.guestSettings.id,
        oldEmail: data.guestSettings.oldEmail
      }, data.guestSettings, function(response) {
        $log.debug('%s: updateGuestParticipant completed, response: %s', sName, response);
        var notification = data;
        $timeout(function() {
          $rootScope.$broadcast('guest-participant-changed', notification);
        });
      }, function(rejection) {
        $log.debug('%s: updateGuestParticipant failed, rejection: %s', sName, rejection);
      });
    }

    function formDismissed() {
      $log.debug('%s: formDismissed', sName);
    }
  }
})();;'use strict';
angular.module('otn.directives.vidyo').factory('modalService', [
  '$modal',
  function ($modal) {
    var self = this;
    var modalInstance = null;
    self.open = function (scope, path) {
      modalInstance = $modal.open({
        templateUrl: path,
        scope: scope
      });
    };

    self.close = function () {
      modalInstance.dismiss('close');
    };
    return self;
  }
]);
;'use strict';
angular.module('otn.directives.vidyo').service('otnVideoComp', function ($log, $window) {
  $log.info('otnVideoComp initializing');
  if (!$window.otnVideoComp) {
    // If otnVideoComp is not available you can now provide a
    // mock service, try to load it from somewhere else,
    // redirect the user to a dedicated error page, ...
    alert('otnVideoComp is not found!!!');
    return {
      "fixme": "TODO"
    };
  } else {
    return $window.otnVideoComp;
  }
});
;/*jshint latedef: nofunc */
(function() {
  'use strict';

  angular
    .module('otn.directives.vidyo')
    .service('UserPcvcPrefSettingsService', UserPcvcPrefSettingsService);

  UserPcvcPrefSettingsService.$inject = ['$log', '$modal', '$q', '$rootScope', '$timeout', 'Users'];
  function UserPcvcPrefSettingsService($log, $modal, $q, $rootScope, $timeout, Users) {
    var sName = 'UserPcvcPrefSettingsService';
    $log.debug('%s: init', sName);
    var currUserId = null;
    var service = {
      open: open,
      dismiss: dismiss,
      renderContact: renderContact
    };
    return service;

    ////////////IMPLEMENTATION//////////////////

    function open(userId) {
      $log.debug('%s: open, userId: %s', sName, userId);
      currUserId = userId;
      var modalInstance = $modal.open({
        templateUrl: 'html/user-pcvc-pref-settings.view.html',
        controller: 'UserPcvcPrefSettingsController',
        resolve: {
          pcvcSettings: function() {
            return getCurrentPrefs();
          }
        },
        backdrop: 'static',
        keyboard: 'true'
      });
      modalInstance.result.then(formSubmitted, formDismissed);
    }

    function dismiss() {
      $log.debug('%s: dismiss', sName);
    }

    // TODO: All places that call this and get null, should show "Not provided" in italics
    function renderContact(contact) {
      var res = '';
      if (contact) {
        angular.forEach(['name', 'phone', 'email'], function (fieldName) {
          if (contact[fieldName]) {
            res += contact[fieldName] + ', ';
          }
        });
      }
      return (res && res.length > 2) ? res.substring(0, res.length - 2) : null;
    }
    
    function getCurrentPrefs() {
      $log.debug('%s: getCurrentPrefs', sName);
      var deferred = $q.defer();

      Users.getUserSettings({
        userId: currUserId,
        serviceType: 'pcvc'
      }, function(settings) {
        $log.debug('Got pcvc settings: ', settings);
        deferred.resolve(fromArray(settings));
      }, function(rejection) {
        $log.error('Error getting pcvc settings: ', rejection);
        //TODO NOTIFIER message
        deferred.reject(rejection);
      });
      return deferred.promise;
    }

    function fromArray(settings) {
      var vModel = {contactName: '', contactPhone: '', contactEmail: ''};
      var handlePair = function(pair) {
        if (pair.name === 'contactName') {
          vModel.contactName = pair.value;
        }
        else if (pair.name === 'contactPhone') {
          vModel.contactPhone = pair.value;
        }
        else if (pair.name === 'contactEmail') {
          vModel.contactEmail = pair.value;
        }
      };
      settings.forEach(handlePair);
      return vModel;
    }

    function toArray(settings) {
      var sModel = [
        {name: 'contactName', value: settings.contactName},
        {name: 'contactPhone', value: settings.contactPhone},
        {name: 'contactEmail', value: settings.contactEmail}
      ];
      return sModel;
    }

    function formSubmitted(data) {
      $log.debug('%s: formSubmitted, data: %s', sName, data);
      Users.saveUserSettings({
        userId: currUserId,
        serviceType: 'pcvc'
      }, toArray(data), function(response) {
        $log.debug('%s: saveUserSettings completed, response: %s', sName, response);
        var notification = {userId: currUserId, settings: data};
        $timeout(function() {
          $rootScope.$broadcast('user-pcvc-pref-settings-changed', notification);
        });
        currUserId = null;
      }, function(rejection) {
        $log.debug('%s: saveUserSettings failed, rejection: %s', sName, rejection);
        currUserId = null;
      });
    }

    function formDismissed() {
      $log.debug('%s: formDismissed', sName);
    }
  }
})();;/**
 * Created by ymilner on 10/19/2017.
 */
/*jslint latedef:false*/
(function () {
  'use strict';
  angular.module('otn.directives.vidyo')
    .service('videoHelper', videoHelper);

  videoHelper.$inject = ['$log'];

  function videoHelper($log) {
    $log.debug('invoked videoHelper');

    var service = {
      resolveEventIdFromBridgeCall: resolveEventIdFromBridgeCall
    };
    return service;
    /**
     * Attempt to retrieve event id from pexip bridged calling string
     * @param input ('1530 C 70141568 Hebert')
     * @returns {*} event id or null
     */
    function resolveEventIdFromBridgeCall(input) {
      if (typeof input === 'undefined') {
        return null;
      }
      var arr = input.split(' ');
      var arrLength = arr.length;
      if (arrLength !== 3 && arrLength !== 4) {
        return null;
      }
      //check event type A-admin, E-educ, C-clinical
      var pattern = /[AEC]/;
      var res = pattern.test(arr[1]);
      if (!res) {
        return null;
      }
      //check 3rd parameter if this event id
      var eventId = arr[2];
      if (eventId.length !== 8 && eventId.length !== 9) {
        return null;
      }
      pattern = /^\d+$/;
      res = pattern.test(eventId);
      if (!res) {
        return null;
      }
      return eventId;
    }
  }
})();
